import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
import 'firebase/auth';
import history from '@history';
import _ from '@lodash';
import { setInitialSettings, setDefaultSettings } from 'app/store/fuse/settingsSlice';
import { showMessage } from 'app/store/fuse/messageSlice';
import { changeLanguage } from 'app/store/i18nSlice';
import auth0Service from 'app/services/auth0Service';
import firebaseService from 'app/services/firebaseService';
import jwtService from 'app/services/jwtService';
import apiJwtService from 'app/services/apiJwtService';
import i18n from 'i18n';
/* *** PLS - Import and Set Navigation on user *** */
import { setNavigation } from 'app/store/fuse/navigationSlice';
import navigationConfig from 'app/fuse-configs/navigationConfig';
import navigationConfigSupplyChain from 'app/fuse-configs/navigationConfigSupplyChain';
import navigationConfigTransport from 'app/fuse-configs/navigationConfigTransport';

export const setUserDataAuth0 = tokenData => async dispatch => {
	const user = {
		role: ['admin'],
		from: 'auth0',
		data: {
			displayName: tokenData.username || tokenData.name,
			photoURL: tokenData.picture,
			email: tokenData.email,
			settings:
				tokenData.user_metadata && tokenData.user_metadata.settings ? tokenData.user_metadata.settings : {},
			shortcuts:
				tokenData.user_metadata && tokenData.user_metadata.shortcuts ? tokenData.user_metadata.shortcuts : []
		}
	};

	return dispatch(setUserData(user));
};

export const setUserDataFirebase = (user, authUser) => async dispatch => {
	if (
		user &&
		user.data &&
		user.data.settings &&
		user.data.settings.theme &&
		user.data.settings.layout &&
		user.data.settings.layout.style
	) {
		// Set user data but do not update
		return dispatch(setUserData(user));
	}

	// Create missing user settings
	return dispatch(createUserSettingsFirebase(authUser));
};

export const createUserSettingsFirebase = authUser => async (dispatch, getState) => {
	const guestUser = getState().auth.user;
	const fuseDefaultSettings = getState().fuse.settings.defaults;
	const { currentUser } = firebase.auth();

	/**
	 * Merge with current Settings
	 */
	const user = _.merge({}, guestUser, {
		uid: authUser.uid,
		from: 'firebase',
		role: ['admin'],
		data: {
			displayName: authUser.displayName,
			email: authUser.email,
			settings: { ...fuseDefaultSettings }
		}
	});
	currentUser.updateProfile(user.data);

	dispatch(updateUserData(user));

	return dispatch(setUserData(user));
};

export const setUserData = user => async (dispatch, getState) => {
	/* You can redirect the logged-in user to a specific route depending on his role */
	history.location.state = {
		redirectUrl: user.redirectUrl // for example 'apps/academy'
	};

	/* PLS - 18/02/2021 - show sidebar menu by user type */
	const { typeClient } = user.data;
	if (typeClient) {
		switch (typeClient) {
			case 'SC':
				dispatch(setNavigation(navigationConfigSupplyChain));
				break;
			case 'TR':
				dispatch(setNavigation(navigationConfigTransport));
				break;
			default:
				dispatch(setNavigation(navigationConfig));
				break;
		}
	}

	// PLS - 16/03/2021 - Get Select Language on refresh page
	let langGet = window.localStorage.getItem('setLanguage');
	let initLng = getState().i18n.language;
	const langSet = !_.isNull(langGet) && langGet !== initLng ? langGet : initLng;
	dispatch(changeLanguage(langSet));

	/* PLS - 1/04/2021 -  Set User Settings on load and reload
	 **** get variable global user settings theme
	 **** convert variable global to json pass
	 **** Verification user setting theme is change
	 **** set User Default Setting */
	const userSetting = localStorage.getItem('userSetting');
	const userConnectSetting = JSON.parse(userSetting);
	const userConnectDataSetting =
		!_.isNull(userConnectSetting) && !_.isEqual(userConnectSetting, getState().fuse.settings.current)
			? userConnectSetting
			: user.data.settings;

	dispatch(setDefaultSettings(userConnectDataSetting));
	// dispatch(setDefaultSettings(user.data.settings));

	dispatch(setUser(user));
};

export const updateUserSettings = settings => async (dispatch, getState) => {
	const oldUser = getState().auth.user;
	const user = _.merge({}, oldUser, { data: { settings } });

	dispatch(updateUserData(user));

	return dispatch(setUserData(user));
};

export const updateUserShortcuts = shortcuts => async (dispatch, getState) => {
	const { user } = getState().auth;
	const newUser = {
		...user,
		data: {
			...user.data,
			shortcuts
		}
	};

	dispatch(updateUserData(user));

	return dispatch(setUserData(newUser));
};

export const logoutUser = () => async (dispatch, getState) => {
	const { user } = getState().auth;

	if (!user.role || user.role.length === 0) {
		// is guest
		return null;
	}

	history.push({
		pathname: '/'
	});

	switch (user.from) {
		case 'firebase': {
			firebaseService.signOut();
			break;
		}
		case 'auth0': {
			auth0Service.logout();
			break;
		}
		case 'jwt': {
			jwtService.logout();
			break;
		}
		default: {
			apiJwtService.logout();
		}
	}

	dispatch(changeLanguage(i18n.options.lng));
	dispatch(setInitialSettings());

	return dispatch(userLoggedOut());
};

export const updateUserData = user => async (dispatch, getState) => {
	if (!user.role || user.role.length === 0) {
		// is guest
		return;
	}
	switch (user.from) {
		case 'firebase': {
			firebaseService
				.updateUserData(user)
				.then(() => {
					dispatch(showMessage({ message: 'User data saved to firebase' }));
				})
				.catch(error => {
					dispatch(showMessage({ message: error.message }));
				});
			break;
		}
		case 'auth0': {
			auth0Service
				.updateUserData({
					settings: user.data.settings,
					shortcuts: user.data.shortcuts
				})
				.then(() => {
					dispatch(showMessage({ message: 'User data saved to auth0' }));
				})
				.catch(error => {
					dispatch(showMessage({ message: error.message }));
				});
			break;
		}
		case 'jwt': {
			jwtService
				.updateUserData(user)
				.then(() => {
					dispatch(showMessage({ message: 'User data saved with api' }));
				})
				.catch(error => {
					dispatch(showMessage({ message: error.message }));
				});
			break;
		}
		default: {
			apiJwtService
				.updateUserData(user)
				.then(() => {
					//! PLS - 1/04/2021 - add user setting theme in varaible global
					localStorage.setItem('userSetting', JSON.stringify(user.data.settings));
					//! PLS - 1/04/2021 - add user setting theme into user data
					dispatch(setUserData(user));
					// dispatch(showMessage({ message: 'apijwt User data saved with api' }));
				})
				.catch(error => {
					dispatch(showMessage({ message: error.message }));
				});
			break;
		}
	}
};

const initialState = {
	role: [], // guest
	userId: '',
	customerName: 'Fuse',
	data: {
		displayName: 'John Doe',
		photoURL: 'assets/images/avatars/Velazquez.jpg',
		email: 'johndoe@withinpixels.com'
		// shortcuts: ['calendar', 'mail', 'contacts', 'todo']
	}
};

const userSlice = createSlice({
	name: 'auth/user',
	initialState,
	reducers: {
		setUser: (state, action) => action.payload,
		userLoggedOut: (state, action) => initialState
	},
	extraReducers: {}
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
