import _ from '@lodash';
import * as Actions from '../contractors/store/actions';

const initialState = [];

const contractor = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_CONTRACTORS_SUCCES: {
			return {
				state: _.keyBy(action.payload.data, 'idContractor')
			};
		}
		case Actions.UPDATE_CONTRACTOR_SUCCES: {
			return {
				...state
			};
		}
		case Actions.DELETE_CONTRACTOR_SUCCESS: {
			return {
				...initialState
			};
		}
		case Actions.DELETE_CONTRACTOR_ERROR: {
			return {
				success: false,
				error: action.payload
			};
		}
		case Actions.CREATE_CONTRACTORS_SUCCES: {
			return {
				...state
			};
		}
		case Actions.CREATE_CONTRACTORS_ERROR: {
			return {
				success: false
			};
		}
		// case Actions.SET_SEARCH_TEXT: {
		// 	return {
		// 		...state,
		// 		searchText: action.searchText
		// 	};
		// }
		default:
			return state;
	}
};

export default contractor;
