const locale = {
	TITLE: 'Page Example',
	ALERT_NAME: "Nom de l'alerte",
	TYPEGEO: 'Type de Geofencing',
	DEFZONE: 'Définition du périmètre géographique de surveillance',
	DISTANCEA: "Quel est le périmètre de contrôle de l'alerte? ",
	INOUT: 'Entrée / Sortie',
	OUTIN: 'Sortie / Entrée',
	CREATE: 'Valider',
	ADDALERT: 'Ajouter une alerte',
	CANCEL: 'Annuler',
	UPDATE: 'Actualiser',
	GEOFENCING: 'Geofencing',
	TEMPERATURE: 'Température',
	HUMIDITY: 'Humidité',
	BATTERIE: 'Batterie',
	ADDALERTSENT: "Ajout d'une alerte",
	CONFIRM_DELETE_PRESET: "Etes-vous sûr de vouloir supprimer l'alerte ?",
	BOUTON_CANCEL: 'Annuler',
	BOUTON_VALIDER: 'Valider',
	NBCARMIN: '1 caractère minimum',
	NBCARMAX: '3 caractères maximum',
	NUMERICVALID: 'Caractères numériques',
	NO_ALERTS: 'Aucun favori',
	GEOFENCE: 'Rayon',
	ALERTING: 'Déclencheur',
	ROUTE: 'Parcours',
	DEPARTURE: 'Départ',
	ARRIVAL: 'Arrivée',
	STEP: 'Étape',
	ADDSTEP: 'Ajouter une étape',
	FAV_ALERT_LIST: 'Liste des alertes favorites',
	MY_FAV_ALERT: 'Mes alertes favorites',
	CREATE_NEW_FAV_ALERT: 'Créez votre nouvelle alerte favorite'
};

export default locale;
