import React from 'react';
import withReducer from 'app/store/withReducer';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import CancelIcon from '@material-ui/icons/Cancel';
import reducer from './store/reducers';
import ProfileContent from './ProfileContent';
import UserIcon from '../../../custom-icons/UserIcon';
import { TitleTabSettings } from '../../../../../@stracker/components/title/TitleTabSettings';

function Profile({ handleCloseDialogSettings, burgerIsOpen, setBurgerIsOpen, displayMenuBurger }) {
	const { t } = useTranslation('profile');
	const theme = useTheme();

	return (
		<>
			<TitleTabSettings>
				{displayMenuBurger && (
					<MenuIcon className="cursor-pointer mr-10" onClick={() => setBurgerIsOpen(!burgerIsOpen)} />
				)}
				<UserIcon color={theme.palette.stracker.contrastText} />
				<h4>{t('TITILEPROFILE')}</h4>
				{displayMenuBurger && (
					<CancelIcon className="cursor-pointer ml-auto" onClick={handleCloseDialogSettings} />
				)}
			</TitleTabSettings>
			<ProfileContent handleCloseDialogSettings={handleCloseDialogSettings} />
		</>
	);
}

export default withReducer('profile', reducer)(Profile);
