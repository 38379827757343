import ProfileConfig from 'app/main/supplychain/parametres/profile/ProfileConfig';
import MembresConfig from 'app/main/supplychain/parametres/membres/MembresConfig';
import AlertsConfig from 'app/main/supplychain/parametres/alerts/AlertsConfig';
import SearchConfig from 'app/main/supplychain/parametres/search/SearchConfig';
import NotificationsConfig from 'app/main/supplychain/parametres/notifications/NotificationsConfig';
import NotificationConfig from 'app/main/supplychain/notifications/NotificationsConfig';
import LanguesConfig from 'app/main/supplychain/parametres/langues/LanguesConfig';
import DashboardConfig from 'app/main/supplychain/dashboard/DashboardConfig';
import AssetsConfig from './assets/AssetsConfig';
import ContenusConfig from './contenu/ContenusConfig';
import MastersConfig from './master/MasterConfig';

const supplychainConfig = [
	ProfileConfig,
	MembresConfig,
	AlertsConfig,
	SearchConfig,
	LanguesConfig,
	AssetsConfig,
	DashboardConfig,
	NotificationsConfig,
	NotificationConfig,
	ContenusConfig,
	MastersConfig
];

export default supplychainConfig;
