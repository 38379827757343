import _ from '@lodash';
import * as Actions from '../membres/store/actions';

const initialState = {
	state : {},
	searchText: ''
};

const member = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_SC_MEMBERS: {
			return {
				state: _.keyBy(action.payload.users, 'idUser'),
				searchText: ''
			};
		}
		case Actions.GET_SC_MEMBER: { 
			return {
				...state
			};
		}
		case Actions.UPDATE_MEMBER_SUCCESS: {
			return {
				...state,
			};
		}
		case Actions.UPDATE_ROLE_MEMBER_SUCCESS: {
			return {
				...state,
			};
		}
		case Actions.UPDATE_ROLE_MEMBER_ERROR: {
			return {
				success: false,
				error: action.payload
			};
		}
		case Actions.DELETE_MEMBER_SUCCESS: {
			return {
				...state,
			};
		}
		case Actions.DELETE_MEMBER_ERROR: {
			return {
				success: false,
				error: action.payload
			};
		}
		case Actions.CREATE_MULTI_MEMBER_SUCCESS: {
			return {
				...state,
			};
		}
		case Actions.CREATE_MULTI_MEMBER_ERROR: {
			return {
				success: false,
				error: action.payload
			};
		}
		case Actions.SET_SEARCH_TEXT: {
			return {
				...state,
				searchText: action.searchText
			};
		}
		default:
			return state;
	}
};

export default member;
