import Typography from '@material-ui/core/Typography';
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
	typography: {
		fontStyle: 'normal',
		alignItems: 'center'
	},
	iconSvg: {
		width: '26px',
		height: '26px'
	}
}));

function CustomTypography(props) {
	const classes = useStyles();
	const { t } = useTranslation(props.appPage);

	return (
		<div className="flex items-center ml-8">
			{props.customIconSvg}
			{(props.titleplus || props.title) && (
				<Typography
					variant={props.variant}
					className={clsx(
						props.className,
						props.noArrowToTheLeft ? 'sm:mr-12' : '',
						'text-20 sm:text-24',
						classes.typography
					)}
					style={props.color && { color: props.color }}
				>
					<Box fontFamily="Open Sans" fontWeight={600} m={1} className={props.noArrowToTheLeft && 'ml-0'}>
						{props.titleplus} {t(props.title)}
					</Box>
				</Typography>
			)}
		</div>
	);
}

export default CustomTypography;
