import React, { useState } from 'react';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import _ from '@lodash';
import i18next from 'i18next';
import { /*useHistory,*/ useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { List, ListItem, ListItemIcon, ListItemText /*, Icon*/ } from '@material-ui/core';
import * as notificationsActions from 'app/main/supplychain/notifications/store/actions';

import RectangleIcon from 'app/main/custom-icons/RectangleIcon';
import ParametersDialogMenu from 'app/main/supplychain/parametres/ParametersDialogMenu';
import en from './i18n/en';
import fr from './i18n/fr';
import UserLink from './UserLink';
import NotifIcon from './NotifIcon';

i18next.addResourceBundle('en', 'usernav', en);
i18next.addResourceBundle('fr', 'usernav', fr);

const useStyles = makeStyles(theme => ({
	root: {
		alignItems: 'center',
		borderRadius: 2,
		padding: '5px 10px',
		fontSize: 14,
		backgroundColor: `${theme.palette.stracker.variantFocus}!important`,
		width: '100%'
	},
	userLink: {
		width: '100%'
	},
	nested: {
		paddingLeft: theme.spacing(2)
	},
	listItem: {
		color: 'inherit!important',
		textDecoration: 'none!important',
		height: 40,
		width: 'calc(100% - 16px)',
		borderRadius: '5px',
		paddingLeft: 24,
		paddingRight: 12,
		paddingTop: 22,
		paddingBottom: 12,
		'&.active': {
			paddingTop: 16,
			fontSize: ' 1.6rem',
			borderRadius: '8px',
			boxShadow: '0px 4px 4px rgb(7 78 232 / 15%)',
			backgroundColor: theme.palette.background.paper,
			color: `${theme.palette.primary.contrastText}`,
			pointerEvents: 'none',
			'& .list-item-icon': {
				color: 'inherit'
			},
			'&.active:after': {
				content: '',
				border: '1px solid #074EE8',
				height: '20px'
			}
		},
		'& .list-item-icon': {
			marginRight: 16
		}
	},
	'@global': {
		'.navigator .Mitem.active:after': {
			content: '',
			border: '1px solid #074EE8',
			height: '20px'
		},
		'.navigator .Mitem .RectangleIcon': {
			display: 'none'
		},
		'.navigator .Mitem.active .RectangleIcon': {
			display: 'block',
			height: '20px',
			color: theme.palette.stracker.contrastText
		}
	}
}));

function UserNavigation(props) {
	const dispatch = useDispatch();
	//const history = useHistory();
	const classes = useStyles(props);
	const { t } = useTranslation('usernav');
	const location = useLocation();
	const { pathname } = location;
	const selectedItem = _.split(pathname, '/', 2);
	const [openSettingsDialog, setOpenSettingsDialog] = useState(false);
	const userRole = useSelector(({ auth }) => auth.user.role);
	const theme = useTheme();

	function ListItemLink(props) {
		const activeClassName = props.selected ? 'active' : '';
		return (
			<ListItem
				button
				dense
				{...props}
				className={clsx(classes.nested, activeClassName, classes.listItem, props.className, 'Mitem list-item')}
			/>
		);
	}

	const handleOpenDialogSettings = e => {
		e.stopPropagation();
		if (openSettingsDialog) return;

		/* dispatch(Actions.openProfileScDialog(`/profile/${user.id}`)); */
		setOpenSettingsDialog(true);
	};

	const handleCloseDialogSettings = e => {
		e.stopPropagation();
		setOpenSettingsDialog(false);
	};

	return (
		<div
			className={clsx(
				classes.root,
				'user relative flex flex-col items-center justify-center pt-10 pb-5 mb-5 z-0'
			)}
		>
			<FuseAnimate animation="transition.slideLeftIn" delay={200}>
				<List dense component="nav" aria-label="user" className={clsx(classes.root, 'navigator')}>
					<ListItemLink
						className="w-full py-10 mb-10"
						/* onClick={ev => dispatch(Actions.openProfileScDialog(`/profile/${user.id}`))} */
						onClick={e => handleOpenDialogSettings(e)}
					>
						<UserLink className={classes.userLink} />
					</ListItemLink>
					<ParametersDialogMenu
						openDialogSettings={openSettingsDialog}
						handleCloseDialogSettings={handleCloseDialogSettings}
						tabWanted="profile"
						/* onClick={e => e.stopPropagation()} */
					/>

					{userRole === 'superAdmin' ? null : (
						<>
							<ListItemLink
								className="w-full py-10 mt-20"
								/* component={Link} */
								/* to="/notifications" */
								onClick={() => dispatch(notificationsActions.updateStatusDialog(true))}
								selected={selectedItem[1] === 'notifications'}
							>
								<ListItemIcon
									style={{ color: theme.palette.stracker.contrastTextLight }}
									className="min-w-40"
								>
									<NotifIcon />
								</ListItemIcon>
								<ListItemText
									style={{ color: theme.palette.stracker.contrastTextLight }}
									primary={t('NOTIFICATION')}
								/>
								<RectangleIcon />
							</ListItemLink>

							{/*<ListItemLink
								className="w-full py-10 cursor-pointer"
								style={{ paddingTop: '10px' }}
								onClick={() => history.push(`/search`)}
								selected={selectedItem[1] === 'search'}
							>
								<ListItemIcon
									style={{ color: theme.palette.stracker.contrastTextLight }}
									className="min-w-40"
								>
									<Icon className="list-item-icon">search</Icon>
								</ListItemIcon>
								<ListItemText
									style={{ color: theme.palette.stracker.contrastTextLight }}
									primary={t('SEARCH')}
								/>
								<RectangleIcon />
					</ListItemLink>*/}
						</>
					)}
				</List>
			</FuseAnimate>
		</div>
	);
}

export default UserNavigation;
