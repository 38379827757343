import { useEffect } from 'react';

const useLoadScripts = (url, async, defer) => {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = url;
		script.async = async;
		script.defer = defer;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
		// eslint-disable-next-line
	}, [url]);
};

export default useLoadScripts;
