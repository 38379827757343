import React, { useRef, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Formsy from 'formsy-react';
import _ from '@lodash';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '@fuse/hooks';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import styled from 'styled-components';
import PhoneInput from 'react-phone-input-2';
import * as Actions from './store/actions';
import UpdateIconProfile from '../../../custom-icons/UpdateIconProfile';
import { ButtonStyled } from '../../../../../@stracker/components/StyledComponents/ButtonStyled';
import { ListItemStyledWithSelect } from '../../../../../@stracker/components/StyledComponents/ListItemStyledWithSelect';
import 'react-phone-input-2/lib/high-res.css';
import { TextFieldFormsyStyled } from '../../../../../@stracker/components/StyledComponents/TextFieldFormsyStyled';
import { values } from 'lodash';

const defaultFormState = {
	firstname: '',
	name: '',
	email: '',
	phone: '',
	photoURL: '',
	password: '',
	showPassword: false,
	disabledOn: 'disabled'
};

function ProfileContent({ handleCloseDialogSettings }) {
	const theme = useTheme();

	const { contrastTextLight } = theme.palette.stracker;
	const dispatch = useDispatch();

	const { t } = useTranslation('profile');

	const user = useSelector(({ auth }) => auth.user);
	const idConnectedUser = user.data.idUser;

	const idUser = user.id;
	const { idCustomer } = user.data;

	const [isFormValid, setIsFormValid] = useState(false);

	const [state, setState] = useState(false);
	const [initiales, setInitiales] = useState('');
	const [disabledEmail, setDisabledEmail] = useState('disabled');
	const [disabledPassword, setDisabledPassword] = useState('disabled');
	const [phoneInput, setPhoneInput] = useState(null);

	const [isError, setIsError] = React.useState(false);
	const [isErrorMsg, setIsErrorMsg] = React.useState({
		code: '',
		message: ''
	});

	const [isSuccess, setIsSuccess] = React.useState(false);
	const [isSuccessMsg, setIsSuccessMsg] = React.useState({
		code: '',
		message: ''
	});

	const { form, setForm } = useForm({ ...defaultFormState });

	const formRef = useRef(null);

	const profileCustomer = useSelector(({ profile }) => profile.profile.profiles);
	const retourApi = useSelector(({ profile }) => profile.profile.success);
	const retourApiPass = useSelector(({ profile }) => profile.profile.successPass);

	const handleChange = event => {
		setState({ ...state, [event.target.name]: event.target.checked });
	};

	const handleSMSInputChange = phone => {
		setPhoneInput(phone);
	};

	React.useEffect(() => {
		dispatch(Actions.getCustomerName(idCustomer));
		// eslint-disable-next-line
	}, [dispatch]);

	React.useEffect(() => {
		if (retourApi.status && retourApi.success_coce) {
			switch (retourApi.status) {
				case 'ok':
					setIsSuccess(true);
					setIsSuccessMsg({ message: retourApi.success_coce });

					break;
				case 'nok':
					setIsError(true);
					setIsErrorMsg({ message: 'UPDACNTERR' });
					break;
				default:
					break;
			}
		}
	}, [retourApi]);

	React.useEffect(() => {
		if (retourApiPass.status && retourApiPass.success_coce) {
			switch (retourApiPass.status) {
				case 'ok':
					setIsSuccess(true);
					setIsSuccessMsg({ message: 'UPDACNTSUCPASS' });
					break;
				case 'nok':
					setIsError(true);
					setIsErrorMsg({ message: 'UPDACNTERR' });
					break;
				default:
					break;
			}
		}
	}, [retourApiPass]);

	React.useEffect(() => {
		if (!_.isEmpty(profileCustomer)) {
			setForm({
				...defaultFormState,
				customerName: profileCustomer.customerName
			});
		}

		if (!_.isEmpty(user.data)) {
			const initiales = user.data.displayName ? user.data.displayName.match(/\b\w/g).join('').toUpperCase() : '';
			setInitiales(initiales);
			let phoneFromStore = user.data.phone;
			if (phoneFromStore.substr(0, 1) === '0') {
				phoneFromStore = `33${phoneFromStore.substr(1)}`;
				setPhoneInput(phoneFromStore);
			} else {
				setPhoneInput(user.data.phone);
			}

			setForm({
				...defaultFormState,
				firstname: user.data.firstname,
				name: user.data.lastname,
				email: user.data.email,
				photoURL: user.data.photo,
				customerName: profileCustomer.customerName
			});
		}
	}, [user.data, profileCustomer, setForm]);

	function handlePasswordChange(event) {
		const { value } = event.currentTarget;
		setForm({ ...form, password: value });
	}

	function disableButton() {
		setIsFormValid(false);
	}

	function enableButton() {
		setIsFormValid(true);
	}

	function enableEmail() {
		switch (disabledEmail) {
			case '':
				setDisabledEmail('disabled');
				break;

			case 'disabled':
				setDisabledEmail('');
				break;
			default:
				break;
		}
	}

	function enablePassword() {
		switch (disabledPassword) {
			case '':
				setDisabledPassword('disabled');
				break;

			case 'disabled':
				setForm({ ...form, password: '' });
				setDisabledPassword('');
				break;
			default:
				break;
		}
	}

	function handleSubmit(model) {
		const newList = {};

		model.customerId = idCustomer;
		model.idUser = idUser;
		newList.idConnectedUser = idConnectedUser;
		model.enabled = 'Y';
		model.firstName = idUser;

		newList.idUser = idUser;
		newList.customerId = idCustomer;
		newList.firstName = model.firstname;
		newList.lastName = model.name;
		newList.phone = phoneInput;
		newList.language = '';
		newList.photo = '';
		newList.userId = '';
		newList.idRole = '';
		newList.userId = '';

		if (disabledEmail === '') {
			newList.email = model.email;
		} else {
			newList.email = '';
		}
		if (disabledPassword === '') {
			newList.password = model.password;
		} else {
			newList.password = '';
		}

		if (newList.email === '' && newList.password === '') dispatch(Actions.updateProfile(newList));
		else dispatch(Actions.updateProfilePass(newList));

		function localfName() {
			if (form.firstName !== newList.firstName) {
				localStorage.setItem('firstname', newList.firstName);
			}
		}
		function localName() {
			if (form.name !== newList.lastName) {
				localStorage.setItem('lastname', newList.lastName);
			} else if (form.name === newList.lastName) {
				localStorage.setItem('lastname', newList.lastName);
			}
		}
		localfName();
		localName();
		return newList;
	}
	return (
		<Formsy
			name="registerForm"
			onValidSubmit={handleSubmit}
			onValid={enableButton}
			onInvalid={disableButton}
			ref={formRef}
			className="flex flex-col"
			style={{ height: 'calc(100% - 54px)' }}
		>
			<div
				className="flex items-center mb-10 pb-10 border-b-1"
				style={{ borderColor: theme.palette.stracker.colorOutlined }}
			>
				<p className="mr-10" style={{ color: contrastTextLight }}>
					Photo:{' '}
				</p>
				<div>
					{form.photoURL && form.photoURL !== '' ? (
						<AvatarStyled className="" alt="user photo" src={form.photoURL} />
					) : (
						<AvatarStyled className="">{initiales}</AvatarStyled>
					)}
				</div>
			</div>

			{isError && (
				<span className="" id="message-err-form">
					{t(isErrorMsg.message)}
				</span>
			)}

			{isSuccess && (
				<span className="" id="message-form">
					{t(isSuccessMsg.message)}
				</span>
			)}

			<div className="flex-col mb-10">
				<p className="mb-5" style={{ color: contrastTextLight }}>
					{t('IDENTCON')}
				</p>
				<div className="flex items-center">
					<TextFieldFormsyStyled
						className="w-full"
						type="email"
						name="email"
						value={form.email}
						variant="outlined"
						placeholder={t('EMAIL')}
						required
						disabled={disabledEmail === 'disabled'}
						validations={{
							minLength: 4,
							isEmail: true
						}}
						validationErrors={{
							minLength: t('NBCARACTERES'),
							isEmail: t('EMAILNOVALID')
						}}
						fullWidth
						secondary
					/>
					<IconButton className="p-5" aria-label="toggle email modify" onClick={() => enableEmail()}>
						<Icon className="text-8" color="action">
							<UpdateIconProfile
								ColorInM={theme.palette.stracker.contrastTextDark}
								boderColorM={theme.palette.secondary.colorBorderSelect}
							/>
						</Icon>
					</IconButton>
				</div>

				<div className="flex items-center">
					<TextFieldFormsyStyled
						id="password"
						type={form.showPassword ? 'text' : 'password'}
						name="password"
						disabled={disabledPassword === 'disabled'}
						onChange={handlePasswordChange}
						placeholder={t('PASSWORD')}
						value={form.password}
						validations={{
							minLength: 9
						}}
						validationErrors={{
							minLength: t('NBCARACTERES')
						}}
						InputProps={{
							className: 'pr-2',
							type: 'password'
						}}
						variant="outlined"
						secondary
					/>
					<IconButton className="p-5" aria-label="toggle password modify" onClick={() => enablePassword()}>
						<Icon className="text-8" color="action">
							<UpdateIconProfile
								ColorInM={theme.palette.stracker.contrastTextDark}
								boderColorM={theme.palette.secondary.colorBorderSelect}
							/>
						</Icon>
					</IconButton>
				</div>
			</div>

			<div className="flex flex-col mb-20">
				<p className="mb-5" style={{ color: contrastTextLight }}>
					{t('INFOPROFILE')}
				</p>
				<TextFieldFormsyStyled
					className=""
					type="customerName"
					name="customerName"
					value={form?.customerName}
					onChange={handleChange}
					variant="outlined"
					placeholder={t('CUSTOMERNAME')}
					required
					fullWidth
					disabled
				/>

				<div className="flex">
					<TextFieldFormsyStyled
						className=""
						type="firstname"
						name="firstname"
						value={
							localStorage?.getItem('firstname', values) &&
							form?.firstname !== localStorage?.getItem('firstname', values)
								? localStorage?.getItem('firstname', values)
								: form?.firstname
						}
						onChange={handleChange}
						variant="outlined"
						placeholder={t('FIRSTNAME')}
						required
						fullWidth
					/>

					<TextFieldFormsyStyled
						className=""
						type="name"
						name="name"
						value={
							localStorage?.getItem('lastname', values) &&
							form?.name !== localStorage?.getItem('lastname', values)
								? localStorage?.getItem('lastname', values)
								: form?.name
						}
						onChange={handleChange}
						variant="outlined"
						placeholder={t('NAME')}
						required
						fullWidth
						noMr
					/>
				</div>

				<ListItemStyledWithSelect className="p-0 flex flex-row mb-0 -mb-8" dense>
					<PhoneInput
						country="fr"
						value={
							localStorage?.getItem('phone', values) &&
							phoneInput !== localStorage?.getItem('phone', values)
								? localStorage?.getItem('phone', values)
								: phoneInput
						}
						onChange={e => handleSMSInputChange(e)}
						countryCodeEditable={false}
						enableSearch
						disableSearchIcon
						preferredCountries={['fr', 'us', 'gb']}
						containerClass="containerPhoneInput"
						inputClass="PhoneInput"
						buttonClass="buttonPhoneInput"
						dropdownClass="dropdownPhoneInput"
						searchClass="searchPhoneInput"
						placeholder="Choose a Country"
					/>
				</ListItemStyledWithSelect>
			</div>

			<div className="flex w-full justify-end mt-auto">
				<ButtonStyled
					type="button"
					className="ml-5 py-7 px-10"
					onClick={e => handleCloseDialogSettings(e)}
					secondary
				>
					{t('CANCEL')}
				</ButtonStyled>

				<ButtonStyled className="ml-5 py-7 px-10" disabled={!isFormValid} type="submit">
					{t('UPDATE')}
				</ButtonStyled>

				{isError && (
					<span className="" id="message-form">
						{isErrorMsg.message}
					</span>
				)}
			</div>
		</Formsy>
	);
}

const AvatarStyled = styled(Avatar)`
	width: 38px !important;
	height: 38px !important;
	color: #eda124;
	border: 2px solid #fff;
	font-size: 1.5rem;
	box-shadow: 0px 4px 4px rgb(7 78 232 / 15%);
	font-weight: 600;
	background-color: #fae3bd;
	margin-right: 13px;
	margin-bottom: 0 !important;
`;

export default ProfileContent;
