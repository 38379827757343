import React from 'react';
import { authRoles } from 'app/auth';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import en from './i18n/en';
import fr from './i18n/fr';

i18next.addResourceBundle('fr', 'alerts', fr);
i18next.addResourceBundle('en', 'alerts', en);

const AlertsConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.superAdmin,
	routes: [
		{
			path: '/parametres/alerts',
			component: React.lazy(() => import('./Alerts'))
		},
		{
			path: '/alerts',
			component: () => <Redirect to="/parametres/alerts" />
		}
	]
};

export default AlertsConfig;
