import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Formsy from 'formsy-react';
import Button from '@material-ui/core/Button';
import { SelectFormsy } from '@fuse/core/formsy';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import { TextFieldFormsyStyled } from '@stracker/components/StyledComponents/TextFieldFormsyStyled';
import styled from 'styled-components';
import * as Actions from './store/actions';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	dividerS: {
		backgroundColor:
			theme.palette.type === 'light'
				? `${theme.palette.stracker.colorOutlined} !important`
				: `${theme.palette.background.paper} !important`
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	},
	MboutonBoxV: {
		borderRadius: '8px',
		paddingBottom: '4px !important',
		minWidth: '100px',
		height: '34px'
	},
	hoverColor: {
		backgroundColor: '#fff'
	},
	hoverValidButton: {
		backgroundColor: theme.palette.stracker.contrastText // '#074EE8'
	},
	strackerBlack: {
		color:
			theme.palette.type === 'light'
				? `${theme.palette.stracker.contrastTextDark} !important`
				: `${theme.palette.stracker.contrastTextLight} !important`,
		// '#023E65',
		fontWeight: '600'
	},
	MheightList: {
		height: '50px'
	},
	'@global': {
		'.MuiIconButton-root': {
			color:
				theme.palette.type === 'light'
					? `${theme.palette.stracker.variantContained} !important`
					: `${theme.palette.background.paper} !important` // '#074EE8',
		},
		'.MuiIcon-colorAction': {
			color:
				theme.palette.type === 'light'
					? `${theme.palette.stracker.variantContained} !important`
					: `${theme.palette.background.paper} !important` // '#074EE8',
		},
		'.mbottomButtom': {
			width: '220px',
			textAlign: 'right',
			marginLeft: 'auto'
		},
		'.hoverColor:hover': {
			backgroundColor: theme.palette.stracker.contrastText, // '#074EE8',
			color: '#fff !important'
		},
		'hoverValidButton:hover': {
			backgroundColor: theme.palette.stracker.contrastText // '#074EE8'
		},
		'.mbottomButtom .hoverValidButton:hover': {
			backgroundColor: theme.palette.stracker.contrastText // '#074EE8'
		},
		'.temperatureForm .bginputlog .MuiOutlinedInput-input': {
			padding: '10px 14px',
			borderRadius: '8px',
			fontWeight: '600'
		},
		'.temperatureForm .bginputlog .MuiOutlinedInput-notchedOutline': {
			border: 'none',
			borderRadius: '8px'
		},
		'.temperatureForm .bginputlog .MuiOutlinedInput-root': {
			border: 'none',
			borderRadius: '8px'
		},
		'.temperatureForm .bginputlog ': {
			border: 'none',
			borderRadius: '8px'
		},
		'.mbottomButtom .MuiButton-contained.Mui-disabled': {
			backgroundColor: '#83a7f3',
			color: '#fff'
		},
		'.mbottomButtom .bcancel': {
			paddingBottom: '4px',
			backgroundColor: 'transparent',
			color: theme.palette.secondary.contrastTextDialog, // '#023E65',
			border: `1px solid ${theme.palette.secondary.colorBorderSelect}` // #A8C1F7'
		},
		'.addMembersList': {},
		'.addMembersList MuiFormControl-root': {
			marginLeft: 0
		},
		'.addMembersList .MuiList-padding': {
			paddingLeft: 0,
			paddingRight: 0
		},
		'.addMembersList .MuiFilledInput-input': {
			padding: '12px 12px 10px',
			color: theme.palette.secondary.contrastTextDialog, // '#023E65',
			fontWeight: '600',
			backgroundColor: theme.palette.background.bgInput, // '#E0E8FC',
			borderRadius: '8px'
		},
		'.addMembersList .MuiInputBase-root': {
			borderRadius: '8px !important',
			border: 'none',
			paddingLeft: 0,
			paddingRight: 0
		},
		'.MuiFilledInput-underline:hover': {
			border: 'none !important'
		},
		'.addMembersList .MuiFilledInput-input:hover': {
			border: 'none !important'
		},
		'.addMembersList .MuiFilledInput-input:before:hover': {
			border: 'none !important'
		},
		'.addMembersList .MuiSelect-root': {
			height: '22px',
			border: 'none !important',
			fontWeight: 'normal',
			color: theme.palette.secondary.contrastTextDialog, // '#023E65',
			fontFamily: 'Open Sans'
		},
		'.MselectRole .MuiOutlinedInput-notchedOutline': {
			border: `1px solid ${theme.palette.secondary.colorBorderSelect}` // #A8C1F7'
		},
		'.MselectRole .MuiMenuItem-root': {
			fontFamily: 'Open Sans',
			color: theme.palette.secondary.contrastTextDialog, // '#023E65',
			textTransform: 'capitalize'
		},
		'.MuiMenu-paper.MuiPopover-paper': {
			Width: '100px',
			top: '219px',
			left: '1075px'
		},
		'.MuiMenuItem-root': {
			fontFamily: 'Open Sans',
			color: theme.palette.secondary.contrastTextDialog, // '#023E65',
			textTransform: 'capitalize'
		},
		'.MselectRole': {
			minWidth: '125px'
		},
		'.MboxLangue .MuiSelect-select': {
			border: 'none'
		},
		'.MuiFilledInput-underline:before': {
			border: 'none !important'
		},
		'.MuiFilledInput-underline:after': {
			border: 'none !important'
		},
		'.addMembersList .MuiFormHelperText-root.Mui-error': {
			position: 'absolute',
			bottom: '-15px'
		}
	}
}));

function AddMembers(props) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const { t } = useTranslation('membres');

	const formRef = useRef(null);

	const user = useSelector(({ auth }) => auth.user);
	const { idCustomer } = user.data;

	const [isFormValid, setIsFormValid] = useState(false);
	const [formList, setFormList] = useState([{ email: '', idRole: '' }]);
	const [isLoading, setIsLoading] = useState(false);

	function disableButton() {
		setIsFormValid(false);
	}

	function enableButton() {
		setIsFormValid(true);
	}

	function backList(BackToList) {
		BackToList();
	}

	const handleInputChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...formList];
		list[index][name] = value;
		setFormList(list);
	};

	const handleSubmit = async event => {
		event.preventDefault();
		await setIsLoading(true);
		await dispatch(Actions.createMultiUser(formList, user.id, idCustomer));
		await backList(props.cancel);
		if (user.uuid) {
			const membersList = {
				idCustomer: user.data.idCustomer,
				position: 1,
				limit: 300
			};
			dispatch(Actions.getMembres(membersList));
		}
		await setIsLoading(false);
	};

	if (isLoading) {
		return (
			<div className="flex h-full justify-center items-center">
				<CircularProgress size={50} />
			</div>
		);
	}

	return (
		<>
			<div className="addMembersList">
				<div className="mb-10">
					<div className="float-left font-semibold strackergrey">{t('ADDUSER')}</div>
					<div className="float-right font-semibold strackergrey">{t('ACCESS')}</div>
					<div className="clear-none" />
				</div>

				<div className="w-full pt-28">
					<Divider className={clsx(classes.dividerS, 'w-full')} />
				</div>

				<List dense>
					<Formsy
						ref={formRef}
						onValidSubmit={handleSubmit}
						onValid={enableButton}
						onInvalid={disableButton}
						className="flex flex-col justify-center w-full"
					>
						{/*<span className="flex flex-row items-center">
							<IconButton className="w-18 h-18 mx-4 p-0" aria-label="add" onClick={handleAddClick}>
								<AddIconBlue
									bgColorM={theme.palette.stracker.contrastText}
									boderColorM={'transparence'}
									ColorInM={theme.palette.stracker.body}
								/>
							</IconButton>

							<Typography className={clsx(classes.strackerBlack)}>{t('ADDUSERS')}</Typography>
	</span>*/}
						{formList.map((x, i) => {
							return (
								<ListItem
									className={clsx(classes.MheightList, 'p-0 mb-4 flex items-center')}
									dense
									key={i}
								>
									{/*<IconButton
										className="list-item-icon text-24 pl-3"
										color="action"
										onClick={() => handleRemoveClick(i)}
									>
										<DeleteIcon
											boderColorM={theme.palette.stracker.contrastOtherButton}
											ColorInM={theme.palette.stracker.contrastTextDark}
										/>
							</IconButton>*/}
									<TextFieldFormsyStyled
										className="flex-1 mr-24 ml-0"
										placeholder="Email*"
										id="email"
										name="email"
										type="text"
										value={x.email}
										onChange={e => handleInputChange(e, i)}
										validations={{
											isEmail: true
										}}
										/*validationErrors={{
											isEmail: t('EMAILNOVALID')
										}}*/
										variant="filled"
										required
										size="small"
									/>
									<SelectFormsyStyled
										className="MselectRole"
										label=""
										name="idRole"
										value={x.idRole}
										onChange={e => handleInputChange(e, i)}
										variant="outlined"
										size="small"
										required
										validations={{
											isNumeric: true
										}}
										inputProps={{
											name: 'idRole'
										}}
										//validationError={t('REQUIRED')}
									>
										<MenuItem value="3">User</MenuItem>
										<MenuItem value="2">Admin</MenuItem>
									</SelectFormsyStyled>
								</ListItem>
							);
						})}

						<div className="bottom-14 mbottomButtom">
							<Button
								variant="contained"
								color="primary"
								className={clsx(
									classes.mbuttonBack,
									classes.MboutonBoxV,
									'bcancel w-content mx-auto mt-20 mr-10 normal-case shadow-none text-white float-left'
								)}
								aria-label="CANCEL"
								type="cancel"
								onClick={() => backList(props.cancel)}
							>
								{t('CANCEL')}
							</Button>

							<Button
								variant="contained"
								color="primary"
								className={clsx(
									classes.MboutonBoxV,
									'w-content mx-auto mt-20 normal-case shadow-none text-white hoverValidButton'
								)}
								aria-label="UPDATE"
								disabled={!isFormValid}
								onClick={handleSubmit}
							>
								{t('CREATE')}
							</Button>
						</div>
					</Formsy>
				</List>
			</div>
		</>
	);
}

const SelectFormsyStyled = styled(SelectFormsy)`
	font-weight: 600;
	.MuiFormHelperText-root.Mui-error {
		color: blue;
	}
	[class*='MuiOutlinedInput-root'] {
		height: 35px;
	}
	label {
		color: ${props => props.theme.palette.stracker.contrastTextDark};
		font-weight: 600;
	}
	fieldset {
		border: 2px solid ${props => props.theme.palette.stracker.colorOutlined} !important;
	}
`;

export default AddMembers;
