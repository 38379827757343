/** Authorization Roles * */
const authRoles = {
	superAdmin: ['superAdmin'],
	userAdmin: ['superAdmin', 'userAdmin'],
	user: ['superAdmin', 'userAdmin', 'user'],
	admin: ['admin'],
	staff: ['admin', 'staff'],
	onlyGuest: []
};

export default authRoles;
