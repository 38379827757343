const locale = {
	TITILEPROFILE: 'Profil',
	IDENTCON: 'Identifiants de connexion',
	INFOPROFILE: 'Informations du profil',
	FIRSTNAME: 'Prénom',
	NAME: 'Nom',
	TEL: 'Téléphone',
	CANCEL: 'Annuler',
	UPDATE: 'Mettre à jour',
	CUSTOMERNAME: 'Nom de la société',
	NBCARACTERES: '10 caractères minimum avec indicateur du pays',
	EMAILNOVALID: 'Adresse email non valide',
	PASSWORD: 'Mot de passe',
	EMAIL: 'Email',
	UPDACNTSUC: 'Profil mis à jour avec succès',
	UPDACNTSUCPASS: 'Profil mis à jour avec succcès, vous allez être redirigé vers la page de connexion.',
	UPDACNTERR: 'Attention. Une erreur est survenue lors de la mise à jour',




};

export default locale;
