import * as React from 'react';

function RectangleIcon(props) {
	return (
		<svg
			className="RectangleIcon"
			width="3"
			height="24"
			viewBox="0 0 3 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="0.5" width="2" height="24" fill="currentColor" />
		</svg>
	);
}

export default RectangleIcon;
