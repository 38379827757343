const locale = {
	TITLE: 'Tableau de bord',
	OFFLINE: 'Trackers hors ligne',
	SEELIST: 'Voir plus',
	STOCKS: 'Trackers en stock',
	ONLINE: 'Trackers en ligne',
	SEARCHHEADER: 'Rechercher',
	INTERNALMAP: 'Plan intérieur',
	EXTERNALMAP: 'Carte',
	CONTAINER_LIST: 'Liste des contenants',
	UPDATED_AT: 'Mis à jour',
	DASHBOARD_DEVICES: 'trackers',
	ERROR_REQUEST: "Pas d'information disponible",
	NO_MASTER: 'Aucun Contenu',
	COTATION: 'Cotation',
	TO_COMPLETE: 'En préparation',
	READY_TO_GO: 'Au départ',
	TRANSIT: 'En transit',
	DESTINATION_END: 'Arrivée',
	TO_COMPLETE_RES: 'Préparation',
	READY_TO_GO_RES: 'Départ',
	TRANSPORT: 'Transports',
	QUOTE: 'Devis',
	CREATED: 'créé le',
	OFFER_TO_ACCEPT: 'offres à valider',
	ARRIVAL: 'Arrivée',
	EXPORT_DATA: 'Export Excel',
	REFRESH: 'Rafraichir'
};

export default locale;
