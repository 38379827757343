import React from 'react';
import i18next from 'i18next';
// import DeviceMain from './deviceMain';
import en from './i18n/en';
import fr from './i18n/fr';

i18next.addResourceBundle('en', 'devices', en);
i18next.addResourceBundle('fr', 'devices', fr);

const DeviceMainConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/devices/deviceDetails/:uniqueIdentifiant/:type',
			component: React.lazy(() => import('./deviceDetails/Details'))
		},
		{
			path: '/devices',
			component: React.lazy(() => import('./deviceMain'))
		}
	]
};

export default DeviceMainConfig;

/**
 * Lazy load Example
 */
/*
import React from 'react';

const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
};

export default ExampleConfig;

*/
