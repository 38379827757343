const locale = {
	ADD: 'Add',
	ADDASSET: 'Add an asset',
	ADDCONTENU: 'Add a content',
	ALERT: 'Alert',
	ALERTGEN: 'Alerts - custom',
	ASSET: 'Asset',
	ASSOCIE: 'Link',
	ASSOCIERCONTENANT1: 'Create your asset',
	ASSOCIERCONTENANT2: 'Link your asset to a tracker',
	ASSOCIERCONTENANT3: 'Add contents to your asset',
	ASSOCIERCONTENANT4: 'Customized the alerts for your asset',
	CANCEL: 'Cancel',
	CAPTEUR: 'Sensors',
	CLOSE_TRANSPORT: 'Close transport',
	COMMENT: 'Comments',
	COMMENT_BACK: 'Close',
	COMMENT_BUTTON: 'Add a comment',
	COMMENT_CONFIRM_DELETE: 'Some comments will be deleted. Are you sure ?',
	COMMENT_TITLE: 'Comments - ',
	CONTAINER_INFO: 'Information about the asset',
	CONTENU: 'Content(s)',
	CONNEXION: 'Connectivity',
	CONTENU_ADD_TITLE: 'Add content',
	CONTENU_BACK: 'Close',
	CONTENU_CONFIRM_DELETE: 'Some content will be deleted. Are you sure ?',
	CONTENU_UPBDATE_B: 'Done',
	CREATEAT: 'Created',
	CURRENT_ROUTE: 'Route',
	DANGER: 'Warning !',
	DATE_PICKER_BEGIN: 'Begin',
	DATE_PICKER_END: 'End',
	DELETEASSET: 'Delete',
	DELETEASSETDIALOG: 'Are you sure to delete the asset ? ',
	DELETEASSETTITLE: 'Delete',
	ARCHIVE: 'Archive',
	DELETE_TRANSPORT:
		'Are you sure to close this transport? You can choose between recycling the related trackers in case of a single use or to move them to your stock in case they will be used later for a new transport',
	DELETE_ASSET: 'Remove asset',
	DELETE_ASSET_TEXT:
		'Are you sure to delete this asset? You can choose between recycling the related trackers in case of a single use or to move them to your stock in case they will be used later for a new transport',
	DEPRISEENCHARGE: 'End control',
	DESASSOCIE: 'Dissociate',
	DIALOGEXPLICATION1: 'Define under which reference you want to identify this asset',
	DIALOGEXPLICATION1BIS: 'Asset reference',
	DIALOGEXPLICATION2:
		'Select through the list of the search bar the serial number of the tracker to be linked to your asset',
	DIALOGEXPLICATION3: 'Describe the content of your asset (optional)',
	DIALOGEXPLICATION4: 'Select the data that you want to monitor as well as the alerts for which you will be notified',
	DISSOCIATE: 'Dissociate',
	DOWNLOAD_CSV: 'Download in CSV',
	EDIT: 'Edit',
	EDITASSET: 'Edit',
	EDITASSETTITLE: 'Edit an asset',
	ENDSCROLL: 'Last reached',
	ENDTRACKEDDIALOG: 'Save',
	GEOFENCE: 'Geofence',
	HIDE_FILTERS: 'Hide filters',
	HUMIDITY: 'Humidity',
	HUMIDITY_HISTORY: 'Humidity history',
	IAM_IN_CHARGE: 'I am in charge',
	IS_IN_CHARGE: 'is in charge',
	LISTTRACKER: 'tracker(s) available',
	LOADING: 'Loading',
	MODIFY_TRANSPORT_ALERT: "Modify transport's alerts",
	NEW: 'Creating an asset',
	NOTIFICATIONS: 'Notifications',
	NOTIFICATION_CONFIRM_DELETE: ' will be deleted. Are you sure ?',
	NOTIFICATION_CONFIRM_UPDATE: 'Some content will be updated. Are you sure?',
	NOTIFICATION_EMAIL: 'Notification by email',
	NOTIFICATION_SMS: 'Notification by SMS',
	NOTIFICATION_SUBTITLE: 'Set-up the recipients of the notifications related to this asset',
	POSITION: 'Position',
	PRESETALERT: 'Alerts - favorite',
	PRISEENCHARGE: 'Under my control',
	RANGE_BY: 'Sort by',
	RANGE_BY_ASC: 'From latest to oldest',
	RANGE_BY_DSC: 'From oldest to latest',
	RECYCLE: 'Recycle',
	REFRESH: 'Refresh',
	SAVE: 'Validate',
	SAVEASSET: 'Save',
	SEARCHHEADER: 'Search for',
	SEEMORE_TITLE: 'Information',
	SHARE: 'share',
	SHOW_FILTERS: 'Filters',
	STOCK: 'Store',
	TEMPERATURE: 'Temperature',
	TEMPERATURE_HISTORY: 'Temperature history',
	TITLE: 'Page Example ',
	TITLE_INFO: 'Notes',
	TRACKEDASSET: 'Connected asset(s)',
	TRACKERS: 'Trackers',
	UNLINKASSETDIALOG: 'Are you sure to unlink the asset',
	UNLINKASSETDIALOGBIS: ' from the tracker',
	UNLINKASSETTITLE: 'End of tracking',
	UNTRACKEDASSET: 'Asset(s)',
	UPDATE2: 'Edit',
	UPDATE: 'Edit an asset',
	UP_TO_DATE: 'Asset information',
	NEXT: 'Next',
	PREVIOUS: 'Previous',
	NO_MORE_CHARGE: 'No longer take care of it',
	CHARGE_BY: 'Supported by',
	CHARGE_IT: 'Take it over',
	TEXT_UPLOAD_FILE: 'Uploaded file : ',
	UPLOAD_FILE: 'Upload file',
	CONVERSATION_OF: 'Conversation of',
	CONVERSATION: 'Conversation',
	SEARCH: 'Search',
	MANUAL_POSITION: 'Manual Position',
	MANUAL_POSITION_TEXT: 'Add a position on the map',
	ADD_POSITION: 'Add Position',
	DEPARTURE: 'Departure',
	STEP: 'Step',
	ARRIVAL: 'Arrival',
	MANUAL: 'Manual',
	DEPARTURE_POSITION: 'Departure Position',
	STEP_POSITION: 'Step Position',
	ARRIVAL_POSITION: 'Arrival Position',
	ERROR_DATE: 'Arrival date anterior from departure date',
	ERROR_TRIP: 'Missing informations in route'
};

export default locale;
