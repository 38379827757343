import * as PropTypes from 'prop-types';
import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { useTheme } from '@material-ui/core/styles';

const FusePageSimple = ({
	header,
	contentToolbar,
	content,
	pageScrollable,
	infiniteScroll,
	fullScreenWidth,
	overflowXHidden,
	fitContent
}) => {
	const theme = useTheme();
	return (
		<ThemeProvider theme={theme}>
			<MainStyled pageScrollable={pageScrollable} fitContent={fitContent}>
				{header && <HeaderStyled>{header}</HeaderStyled>}
				{contentToolbar && (
					<ContentToolbarStyled overflowXHidden={overflowXHidden}>{contentToolbar}</ContentToolbarStyled>
				)}
				{content && (
					<ContentStyled
						pageScrollable={pageScrollable}
						infiniteScroll={infiniteScroll}
						fullScreenWidth={fullScreenWidth}
						fitContent={fitContent}
					>
						{content}
					</ContentStyled>
				)}
			</MainStyled>
		</ThemeProvider>
	);
};

FusePageSimple.propTypes = {
	header: PropTypes.node,
	contentToolbar: PropTypes.node,
	content: PropTypes.node,
	innerScroll: PropTypes.bool
};

FusePageSimple.defaultProps = {};

const MainStyled = styled.main`
	height: 100vh;
	display: grid;
	overflow: ${props => (props.pageScrollable || props.fitContent ? 'auto' : 'hidden')};
	grid-template-rows: auto auto 1fr;
	grid-auto-columns: auto;
	grid-gap: 0;
	grid-template-areas:
		'headerStyled'
		'contentToolbarStyled'
		'contentStyled';
`;

const HeaderStyled = styled.header`
	grid-area: headerStyled;
	width: 100%;
	height: max-content;
	overflow: hidden;
	background-color: transparent;
	color: ${props => props.theme.palette.stracker.contrastTextDark};
	padding: 12px 10px;
	font-weight: 600;
	@media (min-width: 600px) {
		padding: 10px 21px 9px;
	}
	@media (min-width: 1280px) {
		padding: 10px 20px;
	}
`;
/* Test display table pour fixe le probleme d'overflow sur la page share */

const ContentToolbarStyled = styled.section`
	grid-area: contentToolbarStyled;
	display: flex;
	flex-direction: column;
	height: fit-content;
	display: table;
	padding: 0 14px;
	color: ${props => props.theme.palette.stracker.contrastTextDark};
	font-weight: 600;
	overflow-x: ${props => (props.overflowXHidden ? 'hidden' : 'initial')};
	@media (min-width: 600px) {
		padding: 0 21px;
	}
`;
const ContentStyled = styled.section`
	grid-area: contentStyled;
	height: ${props => (props.pageScrollable ? 'auto' : props.fitContent ? 'fit-content' : 'calc(100% - 14px)')};
	padding: 7px ${props => (props.infiniteScroll ? '0' : '7px')} 0 14px;
	margin: 0 7px 0 0;
	overflow: ${props => (props.infiniteScroll || props.fullScreenWidth) && 'auto'};

	@media (min-width: 600px) {
		height: ${props => props.fitContent && 'auto'};
		overflow: ${props => (props.infiniteScroll ? 'hidden' : '')};
		padding: 7px ${props => (props.infiniteScroll ? '0' : '14px')} 0 ${props => (!props.fitContent ? '21px' : '0')};
	}
	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border: 2px solid ${props => props.theme.palette.stracker.colorOutlined};
		box-shadow: none;
	}
`;
export default React.memo(FusePageSimple);
