import React from 'react';
import NotificationsDialog from 'app/main/supplychain/notifications/Notifications';

function LeftSideLayout4() {
	return (
		<>
			{/* <Profile />
			<Membres />
			<Alerts />
			<Search />
			<Langues />
			<Notifications /> */}
			<NotificationsDialog />
		</>
	);
}

export default React.memo(LeftSideLayout4);

// A DELETE (Probablement)
