import React, { useState } from 'react';
import withReducer from 'app/store/withReducer';
import reducer from '../store/index';
import MembresHeader from './MembresHeader';
import MembresContent from './MembresContent';

function Membres({ handleCloseDialogSettings, burgerIsOpen, setBurgerIsOpen, displayMenuBurger }) {
	const [pagesTest, setPagesTest] = useState('');

	function changePage(comp) {
		setPagesTest(comp);
	}

	return (
		<>
			<MembresHeader
				switchPageAdd={changePage}
				burgerIsOpen={burgerIsOpen}
				setBurgerIsOpen={setBurgerIsOpen}
				switchPageValue={pagesTest}
				displayMenuBurger={displayMenuBurger}
				handleCloseDialogSettings={handleCloseDialogSettings}
			/>
			<MembresContent
				handleCloseDialogSettings={handleCloseDialogSettings}
				AddMem={pagesTest}
				switchPageAdd={changePage}
			/>
		</>
	);
}

export default withReducer('members', reducer)(Membres);
