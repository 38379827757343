const locale = {
	ADDCONTENU: 'Ajouter du contenu',
	ASSET: 'Actif',
	CANCEL: 'Annuler',
	CHANGEAT: 'Modifié le',
	COMMENT_BACK: 'Fermer',
	COMMENT_BUTTON: 'Ajouter un commentaire',
	COMMENT_CONFIRM_DELETE: 'Le commentaire sera effacé. Poursuivre ?',
	COMMENT_TITLE: 'Commentaires',
	CONNEXION: 'Connectivité',
	CONTENT: 'Contenant',
	CONTENU: 'Contenu',
	CONTENU_ADD_TITLE: 'Ajouter contenu',
	CONTENU_BACK: 'Fermer',
	CONTENU_CONFIRM_DELETE: 'Du contenu sera effacé. Poursuivre ?',
	CREATEAT: 'Créé',
	DANGER: 'Attention !',
	DELETECONTENU: 'Supprimer ce contenu',
	DELETECONTENUDIALOG: 'Etes-vous sûr de vouloir supprimer le contenu',
	DELETECONTENUTITLE: 'Suppression contenu',
	ENDSCROLL: 'Dernier Atteint',
	LOADING: 'Chargement',
	POSITION: 'Position',
	RANGE_BY: 'Ranger par',
	RANGE_BY_ASC: 'Du plus récent au moins récent',
	RANGE_BY_DSC: 'Du moins récent au plus récent',
	SAVE: 'Valider',
	SEARCHHEADER: 'Rechercher',
	SEEMORE: 'Voir plus',
	SHOW_FILTERS: 'Afficher les filtres',
	TITLE: 'Contenu(s)',
	TRACKERS: 'Trackers'
};

export default locale;
