const locale = {
	DATE_PICKER_BEGIN: 'Begin',
	DATE_PICKER_END: 'End',
	HUMIDITY_HISTORY: 'Humidity history',
	TEMPERATURE_HISTORY: 'Temperature history',
	TITLE: 'Share',
	UP_TO_DATE: 'Asset information',
	LOGIN: 'Login',
	REFRESH: 'Refresh',
	DOWNLOAD_CSV: 'Download to CSV',
	CONTENU: 'Content(s)',
	POSITION: 'Position',
	CAPTEUR: 'Sensors',
	ASSOCIATED_TRANSPORT: 'Related transport',
	DESTINATION: 'Destination',
	ROUTE: 'Route',
	TEXT_UPLOAD_FILE: 'This file has been uploaded :',
	ETA_DATE_PICKER_BEGIN: 'Departure',
	ETA_DATE_PICKER_END: 'Arrival',
	EVENT: 'Events',
	INFORMATION: 'Notes',
	ETA: 'Estimated Time',
	CUSTOM_EVENT: 'Informations',
	ALERT: 'Alert',
	MODIFICATION: 'Modification',
	IN_CHARGE: 'In charge',
	HISTORY_OF: 'History of',
	HISTORY_NONE: 'No history',
	RANGE_BY: 'Ranger by',
	RANGE_BY_ASC: 'From latest to oldest',
	RANGE_BY_DSC: 'From oldest to latest',
	CONTAINER_INFO: 'Information about the asset',
	SEARCH: 'Search for',
	COTATION: 'Cotation',
	BUDGET: 'Budget',
	SHIPMENT_DETAILS: 'Shipment Details',
	CARBON_FOOTPRINT: 'Carbon footprint',
	FLIGHT: 'Flight',
	AWB: 'AWB',
	UNITS: 'Units',
	UNIT: 'Unit',
	DRIVER: 'Driver',
	PHONE_ABV: 'Phone',
	PLATE: 'Plate'
};

export default locale;
