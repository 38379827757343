import React from 'react';

function ClientIcon(props) {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={props.className}
		>
			<path
				d="M9 11.25C11.8995 11.25 14.25 8.8995 14.25 6C14.25 3.10051 11.8995 0.75 9 0.75C6.10051 0.75 3.75 3.10051 3.75 6C3.75 8.8995 6.10051 11.25 9 11.25Z"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.1575 10.4175L5.25 17.25L9 15L12.75 17.25L11.8425 10.41"
				stroke="currentColor"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<g clipPath="url(#clip0)" />
			<defs>
				<clipPath id="clip0">
					<rect width="18" height="18" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default ClientIcon;
