import FuseUtils from '@fuse/utils';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { navbarCloseMobile } from 'app/store/fuse/navbarSlice';
import TrackNavBadge from '../TrackNavBadge';
import StatusIcon from '../StatusIcon';

const useStyles = makeStyles(theme => ({
	item: props => ({
		height: 40,
		width: 'calc(100% - 66px)',
		borderRadius: '0 20px 20px 0',
		paddingRight: 12,
		paddingTop: 12,
		paddingBottom: 12,
		cursor: 'pointer',
		textDecoration: 'none!important',
		fontWeight: 600,
		font: 'Open Sans',
		color: theme.palette.stracker.contrastTextDark,
		paddingLeft: props.itemPadding > 80 ? 80 : props.itemPadding,
		'&.active': {
			fontSize: ' 1.6rem',
			borderRadius: '8px',
			border: '2px solid #fff',
			boxShadow: `0px 4px 12px ${theme.palette.secondary.colorShadow2}`, // '0px 4px 4px rgb(7 78 232 / 15%)',
			backgroundColor: theme.palette.background.default2, // to #373C3F
			color: `${theme.palette.stracker.contrastBgButton}`, // `${theme.palette.primary.contrastText}`, //
			// pointerEvents: 'none',
			transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
			'& .list-item-text-primary': {
				color: 'inherit'
			},
			'& .list-item-icon': {
				color: 'inherit'
			},
			'& .active:after': {
				content: 'a',
				border: '1px solid #074EE8',
				height: '20px'
			}
		},
		'&.active svg': {
			display: 'block',
			color: `${theme.palette.stracker.contrastText} !important`
		},
		'& .active:after': {
			content: 'a',
			border: `1px solid ${theme.palette.primary.contrastText}`,
			height: '20px'
		},
		'& .list-item-icon': {
			marginRight: 16
		},
		'& .list-item-text': {}
	}),
	'@global': {
		'.navigation .list-item.active:after': {
			content: '',
			border: '1px solid red', // + theme.palette.stracker.contrastBgButton, //#074EE8,
			height: '20px'
		},
		'.navigation .Mitem .RectangleIcon': {
			display: 'none'
		},
		'.navigation .Mitem.active .RectangleIcon': {
			display: 'block',
			height: '20px',
			color: theme.palette.stracker.contrastText
		}
	}
}));

function TrackNavVerticalLink(props) {
	const dispatch = useDispatch();
	const userRole = useSelector(({ auth }) => auth.user.role);

	const theme = useTheme();
	const mdDown = useMediaQuery(theme.breakpoints.down('md'));
	const { item, nestedLevel } = props;
	const classes = useStyles({
		itemPadding: nestedLevel > 0 ? 40 + nestedLevel * 16 : 24
	});

	const hasPermission = useMemo(() => FuseUtils.hasPermission(item.auth, userRole), [item.auth, userRole]);

	if (!hasPermission) {
		return null;
	}

	return (
		<ListItem
			button
			component="a"
			href={item.url}
			target={item.target ? item.target : '_blank'}
			className={clsx(classes.item, 'Mitem list-item py-20 my-4')}
			onClick={ev => mdDown && dispatch(navbarCloseMobile())}
			role="button"
		>
			{item.icon && <StatusIcon status={item.icon} className={classes.itemIcon} />}

			<ListItemText
				className="font-semibold list-item-text"
				primary={item.title}
				classes={{ primary: 'text-16 list-item-text-primary' }}
			/>

			{item.badge && <TrackNavBadge badge={item.badge} />}
		</ListItem>
	);
}

TrackNavVerticalLink.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.string.isRequired,
		title: PropTypes.string,
		icon: PropTypes.string,
		url: PropTypes.string,
		target: PropTypes.string
	})
};
TrackNavVerticalLink.defaultProps = {};

const NavVerticalLink = withRouter(React.memo(TrackNavVerticalLink));

export default NavVerticalLink;
