import API from '@pi/api';

export const CREATE_CONTRACTORS_SUCCES = 'CREATE_CONTRACTORS_SUCCES';
export const CREATE_CONTRACTORS_ERROR = 'CREATE_CONTRACTORS_ERROR';

export const GET_CONTRACTORS_SUCCES = 'GET_CONTRACTORS_SUCCES';
export const GET_CONTRACTORS_ERROR = 'GET_CONTRACTORS_ERROR';

export const UPDATE_CONTRACTOR_SUCCES = 'UPDATE_CONTRACTOR_SUCCES';
export const UPDATE_CONTRACTOR_ERROR = 'UPDATE_CONTRACTOR_ERROR';

export const DELETE_CONTRACTOR_SUCCESS = 'DELETE_CONTRACTOR_SUCCESS';
export const DELETE_CONTRACTOR_ERROR = 'DELETE_CONTRACTOR_ERROR';

// {
//     “Identity”: "Maxime DURAND"
//     “email”:“kalidoug@iofer.fr”, //facultatif
//     “idCustomer”:40,
//     “phone”:“32312323”, //facultatif
//     “occupation”:“fefezfezf”, //facultatif
//     “action”:“create”,
//     “idContractor”:1 // Seulement pour read, update et delete
// }

export function getContractors(idCustomer) {
	const request = API.post('/contractor', {
		action: 'read',
		idCustomer: idCustomer,
		position: 1,
		limit: 300
	});

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_CONTRACTORS_SUCCES,
				payload: response.data
			})
		);
}

export function createContractor(identity, email, phone, occupation, idCustomer, idContractor) {
	const request = API.post('/contractor', {
		action: 'create',
		identity,
		idContractor,
		idCustomer,
		email,
		phone,
		occupation
	});

	return dispatch =>
		request.then(response => {
			Promise.all([
				dispatch({
					type: CREATE_CONTRACTORS_SUCCES,
					payload: response.data
				})
			]);
		});
}

export function updateContractor(identity, email, phone, occupation, idCustomer, idContractor) {
	const request = API.post('/contractor', {
		idContractor,
		action: 'update',
		identity,
		idCustomer,
		email,
		phone,
		occupation
	});

	return dispatch =>
		request.then(response => {
			Promise.all([
				dispatch({
					type: CREATE_CONTRACTORS_SUCCES,
					payload: response.data
				})
			]);
		});
}

export function deleteContractor(contractorInfo) {
	// eslint-disable-next-line
	const { idContractor, idCustomer } = contractorInfo;

	const request = API.post('/contractor', {
		action: 'delete',
		idContractor
	});

	return dispatch =>
		request.then(response => {
			Promise.all([
				dispatch({
					type: DELETE_CONTRACTOR_SUCCESS,
					payload: response.data
				})
			]);
		});
}
