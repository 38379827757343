const locale = {
	TITLE: 'Example Page',
	ALERT_NAME: 'Alert name',
	TYPEGEO: 'Type of Geofencing',
	DEFZONE: 'Definition of the geographical area of ​​guarding',
	DISTANCEA: 'How far from the center should the alert be?',
	INOUT: 'In / Out',
	OUTIN: 'Out / In',
	CREATE: 'Validate',
	ADDALERT: 'Add an alert',
	CANCEL: 'Cancel',
	UPDATE: 'Update',
	GEOFENCING: 'Geofencing',
	TEMPERATURE: 'Temperature',
	HUMIDITY: 'Humidity',
	BATTERIE: 'Battery',
	ADDALERTSENT: 'Add new alert',
	CONFIRM_DELETE_PRESET: 'Delete confirmation',
	BOUTON_CANCEL: 'Cancel',
	BOUTON_VALIDER: 'Validate',
	ADDEMAILS: 'Ajout',
	ADDSMS: 'Ajout',
	NBCARMIN: '1 character minimum',
	NBCARMAX: '3 characters maximum',
	NUMERICVALID: 'numeric characters',
	GEOFENCE: 'Geofence',
	ALERTING: 'Trigger',
	ROUTE: 'Route',
	DEPARTURE: 'Departure',
	ARRIVAL: 'Arrival',
	STEP: 'Step',
	ADDSTEP: 'Add a step',
	FAV_ALERT_LIST: 'List of favorite alerts',
	MY_FAV_ALERT: 'My favorite alerts',
	CREATE_NEW_FAV_ALERT: 'Create your new favorite alert'
};

export default locale;
