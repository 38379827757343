import React from 'react';

import i18next from 'i18next';
import en from './i18n/en.js';
import fr from './i18n/fr.js';
// import { authRoles } from 'app/auth';

i18next.addResourceBundle('fr', 'cotation', fr);
i18next.addResourceBundle('en', 'cotation', en);

const NotificationsConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/cotations',
			component: React.lazy(() => import('./Cotation'))
		}
	]
};

export default NotificationsConfig;
