import React, { useState } from 'react';
import FuseAnimate from '@fuse/core/FuseAnimate';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// import { Link } from 'react-router-dom';
// import _ from '@lodash';
import clsx from 'clsx';
import { List, ListItem, ListItemIcon, ListItemText, Icon } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { logoutUser } from 'app/auth/store/userSlice';

import i18next from 'i18next';
import en from './i18n/en';
import fr from './i18n/fr';
import ParametersDialogMenu from '../../../../main/supplychain/parametres/ParametersDialogMenu';

i18next.addResourceBundle('en', 'usernav', en);
i18next.addResourceBundle('fr', 'usernav', fr);

const useStyles = makeStyles(theme => ({
	root: {
		alignItems: 'center',
		borderRadius: 2,
		padding: '0 6px',
		fontSize: 11,
		position: 'relative',
		left: 0,
		bottom: 0,
		verticalAlign: 'bottom'
		// backgroundColor: `${theme.palette.primary.light}`, // '#D4E5FF', 'rgba(0,0,0,.08);'
	},
	list: {
		bottom: 0,
		position: 'relative',
		width: '100%'
	},
	bottomPosition: {
		position: 'absolute',
		bottom: 0
	},
	nested: {
		paddingLeft: theme.spacing(4)
	},
	listItem: {
		color: 'inherit!important',
		textDecoration: 'none!important',
		height: 40,
		width: 'calc(100% - 16px)',
		borderRadius: '5px',
		paddingRight: 12,
		// borderRadius: '0 20px 20px 0',
		// paddingLeft: 24,
		'&.active': {
			backgroundColor: theme.palette.secondary.main,
			color: `${theme.palette.secondary.contrastText}!important`,
			pointerEvents: 'none',
			'& .list-item-icon': {
				color: 'inherit'
			}
		},
		'& .list-item-icon': {
			marginRight: 16
		},
		'&:hover': {
			borderRadius: '8px',
			boxShadow:
				theme.palette.type === 'dark' ? '0px 4px 4px rgb(7 78 232 / 15%)' : '0px 0px 0px rgb(7 78 232 / 15%)',
			color: `${theme.palette.primary.contrastText}`,
			backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.paper : 'rgba(0,0,0,.04)',
			'&:hover::after': {
				content: ''
			}
		}
	}
}));

function UserNavigation() {
	const classes = useStyles();
	const dispatch = useDispatch();
	const theme = useTheme();
	const { t } = useTranslation('usernav');
	const [openSettingsDialog, setOpenSettingsDialog] = useState(false);

	const handleOpenDialogSettings = e => {
		e.stopPropagation();
		if (openSettingsDialog) return;
		setOpenSettingsDialog(true);
	};

	const handleCloseDialogSettings = e => {
		e.stopPropagation();
		setOpenSettingsDialog(false);
	};

	function ListItemLink(props) {
		const activeClassName = props.selected ? 'active' : '';
		return (
			<ListItem
				button
				dense
				{...props}
				className={clsx(classes.nested, activeClassName, classes.listItem, 'py-20 my-4')}
			/>
		);
	}

	return (
		<div className={clsx(classes.root, 'user flex items-center  mb-32 w-full')}>
			<FuseAnimate animation="transition.slideLeftIn" delay={200}>
				<List dense component="nav" aria-label="settings" className={classes.list}>
					<ListItemLink onClick={e => handleOpenDialogSettings(e)}>
						<ListItemIcon style={{ minWidth: '0px' }} className="flex">
							<Icon
								style={{ color: theme.palette.stracker.contrastTextLight }}
								className="list-item-icon"
								color="action"
							>
								settings
							</Icon>
						</ListItemIcon>
						<ListItemText
							style={{ color: theme.palette.stracker.contrastTextLight }}
							primary={t('SETTING')}
						/>
						<ParametersDialogMenu
							openDialogSettings={openSettingsDialog}
							handleCloseDialogSettings={handleCloseDialogSettings}
							/* onClick={e => e.stopPropagation()} */
						/>
					</ListItemLink>

					<ListItemLink
						onClick={() => {
							dispatch(logoutUser());
						}}
					>
						<ListItemIcon style={{ minWidth: '0px' }} className="flex">
							<Icon
								style={{ color: theme.palette.stracker.contrastTextLight }}
								className="list-item-icon"
								color="action"
							>
								exit_to_app
							</Icon>
						</ListItemIcon>
						<ListItemText
							style={{ color: theme.palette.stracker.contrastTextLight }}
							primary={t('LOGOUT')}
						/>
					</ListItemLink>
				</List>
			</FuseAnimate>
		</div>
	);
}

export default UserNavigation;
