const locale = {
	ASSET: 'Colis',
	CLOSING: 'Clôturée',
	CONNEXION: 'Connectivité',
	CONTENU: 'Contenu(s)',
	CREATEAT: 'Créé',
	ERROR_MIN_CHAR: 'Veuillez rentrer au moins 3 caractères',
	FENCING: 'Cloturé',
	HIDE_FILTERS: 'Masquer filtres',
	HUMIDITY: 'Humidité',
	NO_RESULT: 'pas de résultats',
	PLACEHOLDER_SEARCH: "Entrez la référence d’un transport ou d'un tag",
	POSITION: 'Localisation',
	RANGE_BY: 'Ranger par',
	RANGE_BY_ASC: 'Du plus récent au moins récent',
	RANGE_BY_DSC: 'Du moins récent au plus récent',
	RESULT: 'Résultat',
	RESULT_TEXT: 'Résultat de la recherche',
	SEARCH: 'Rechercher',
	SHOW_FILTERS: 'Filtrer',
	TEMPERATURE: 'Température',
	TITLE: 'Historique',
	TITLE_INFO: 'Informations libres',
	TRANSPORT: 'Transport',
	DESTINATION: 'Destination',
	ARRIVED_AT: 'Arrivée le ',
	START_COUNTRY: 'Départ',
	END_COUNTRY: 'Arrivée',
	CAR: 'Routier',
	PLANE: 'Aérien',
	BOAT: 'Maritime',
	TRANSPORT_TYPE: 'Mode',
	DATE: 'Dates',
	FROM: 'Du',
	TO: 'Au',
	FROM_FILTER: 'Depuis le ',
	TO_FILTER: "Jusqu'au ",
	EXPORT_DATA: 'Export Excel',
	QUOTE: 'Cotation',
	CLIENT: 'Client',
	ORDER_BY: 'Trier par',
	FILTER_BY: 'Filtrer par'
};

export default locale;
