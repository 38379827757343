import React from 'react';

function HistoryIcon(props) {
	return (
		<svg
			width={props.size || '18'}
			height={props.size || '18'}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={props.className}
		>
			<path
				d="M0.75 3V7.5H5.25"
				stroke={props.color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.6325 11.2501C3.1188 12.6304 4.0405 13.8152 5.25874 14.6261C6.47698 15.437 7.92576 15.83 9.38679 15.7459C10.8478 15.6618 12.2419 15.1052 13.3591 14.1599C14.4763 13.2146 15.2559 11.9318 15.5807 10.5049C15.9054 9.07789 15.7576 7.58405 15.1595 6.24841C14.5614 4.91277 13.5454 3.80768 12.2646 3.09966C10.9839 2.39163 9.50768 2.11903 8.05851 2.32292C6.60934 2.52681 5.26568 3.19615 4.23 4.23008L0.75 7.50008"
				stroke={props.color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<g clipPath="url(#clip0)" />
			<defs>
				<clipPath id="clip0">
					<rect width="18" height="18" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default HistoryIcon;
