import { createSlice } from '@reduxjs/toolkit';
import { showMessage } from 'app/store/fuse/messageSlice';
import firebaseService from 'app/services/firebaseService';
//import jwtService from 'app/services/jwtService';
import apiJwtService from 'app/services/apiJwtService';
import { createUserSettingsFirebase } from './userSlice';

export const submitResetPassword = ({ email, password }) => async dispatch => {
	return apiJwtService
		.resetpwdUser({
			email,
			password
		})
		.then(user => {
			//dispatch(setUserData(user));
			return dispatch(resetpasswordSuccess());
		})
		.catch(error => {
			return dispatch(resetpasswordError(error));
		});
};

export const resetpasswordWithFirebase = model => async dispatch => {
	if (!firebaseService.auth) {
		console.warn("Firebase Service didn't initialize, check your configuration");

		return () => false;
	}
	const { email } = model;

	return firebaseService.auth
		.createUserWithEmailAndPassword(email)
		.then(response => {
			dispatch(
				createUserSettingsFirebase({
					...response.user,
					email
				})
			);

			return dispatch(resetpasswordSuccess());
		})
		.catch(error => {

			const emailErrorCodes = ['auth/email-already-in-use', 'auth/invalid-email'];

			const response = {
				email: emailErrorCodes.includes(error.code) ? error.message : null,
			};

			if (error.code === 'auth/invalid-api-key') {
				dispatch(showMessage({ message: error.message }));
			}

			return dispatch(resetpasswordError(response));
		});
};

const initialState = {
	success: false,
	error: {
		email: null,
		password: null
	}
};

const resetpasswordSlice = createSlice({
	name: 'auth/resetpassword',
	initialState,
	reducers: {
		resetpasswordSuccess: (state, action) => {
			state.success = true;
		},
		resetpasswordError: (state, action) => {
			state.success = false;
			state.error = action.payload;
		}
	},
	extraReducers: {}
});

export const { resetpasswordSuccess, resetpasswordError } = resetpasswordSlice.actions;

export default resetpasswordSlice.reducer;
