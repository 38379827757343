import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CustomTypography from '../../../../@stracker/components/CustomTypography';
import Bell2Icon from '../../custom-icons/Bell2Icon';
import * as Actions from './store/actions';
import { ButtonStyled } from '../../../../@stracker/components/StyledComponents/ButtonStyled';
import SearchStyled from '../../../../@stracker/components/StyledComponents/SearchStyled';

const NotificationsHeader = ({
	unreadNotificationsCount,
	searchTextValue,
	setSearchTextValue,
	hasNotificationUnread,
	userLog
}) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const { t } = useTranslation('notifications');

	const handleNotificationsRead = () => {
		dispatch(Actions.updateAllNotificationsRead(userLog.id, userLog.data.idCustomer));
	};

	return (
		<div className="flex flex-col justify-between items-center w-full md:flex-row">
			<CustomTypography
				variant="h2"
				title={`${unreadNotificationsCount} ${t('TITLE')}${unreadNotificationsCount > 1 ? 's' : ''}`}
				style={{ paddingLeft: '0' }}
				color={
					theme.palette.type === 'light'
						? theme.palette.stracker.contrastText
						: theme.palette.secondary.contrastText
				}
				customIconSvg={
					<Bell2Icon
						size={28}
						color={
							theme.palette.type === 'light'
								? theme.palette.stracker.contrastText
								: theme.palette.secondary.contrastText
						}
						className="flex"
					/>
				}
			/>
			<div className="flex flex-col items-center w-full sm:flex-row md:w-auto sm:justify-evenly">
				<SearchStyled
					searchValue={searchTextValue}
					setSearchValue={e => setSearchTextValue(e.target.value)}
					placeholder={t('PLACEHOLDER_SEARCH')}
				/>

				<ButtonStyled
					type="button"
					className="px-10 py-7 my-10 w-full tracking-wide sm:w-auto md:ml-16"
					disabled={!hasNotificationUnread}
					onClick={handleNotificationsRead}
				>
					{t('BTN_READ_ALL_NOTIFICATIONS_TEXT')}
				</ButtonStyled>
			</div>
		</div>
	);
};

export default React.memo(NotificationsHeader);
