import React from 'react';

function ChevronLeftIcon(props) {
	return (
		<svg
			width={props.size ? props.size: "18"}
			height={props.size ? props.size : "18"}
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			transform={props.rotate && `rotate(${props.rotate})`}
		>
			<path
				d="M4.5 6.75L9 11.25L13.5 6.75"
				stroke={props.color ? props.color : "#023E65"}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default ChevronLeftIcon;
