import axios from 'axios';
import API from '@pi/api';

export const GET_SC_PROFILE = '[SUPPLY CHAIN PROFILE] GET PROFILE';

export const GET_SC_CUSTOMERNAME = '[SUPPLY CHAIN PROFILE] GET PROFILE CUSTOMERNAME';

export const UPDATE_PROFILE_SUCCESS = '[SUPPLY CHAIN PROFILE] UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_WITHEMAIL_SUCCESS = '[SUPPLY CHAIN PROFILE] UPDATE_PROFILE_WITHEMAIL_SUCCESS';



export function getProfile() {
	const request = axios.get('/api/supply-chain-profile/profile');

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_SC_PROFILE,
				payload: response.data
			})
		);
}

export function getCustomerName(idCustomer) {
	const request = API.post('/customer', {
		action: 'read',
		idCustomer
	});

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_SC_CUSTOMERNAME,
				payload: response.data
			})
		);
}

export function updateProfile(model) {
	const { idUser, customerId, email, password, firstName, lastName, phone, enabled, language, photo, userId, idRole, idConnectedUser } = model;
	const request = API.post('/user', {
		action: 'update',
		idUser,
		idConnectedUser,
		customerId,
		email,
		password,
		firstName,
		lastName,
		phone,
		enabled,
		language,
		photo,
		userId,
		idRole,
	});
	
	return dispatch =>
		request.then(response =>
			dispatch({
				type: UPDATE_PROFILE_SUCCESS,
				payload: response.data
			})
		);
}

export function updateProfilePass(model) {
	const { idUser, customerId, email, password, firstName, lastName, phone, enabled, language, photo, userId, idRole, idConnectedUser } = model;
	const request = API.post('/user', {
		action: 'update',
		idUser,
		idConnectedUser,
		customerId,
		email,
		password,
		firstName,
		lastName,
		phone,
		enabled,
		language,
		photo,
		userId,
		idRole,
	});

	return dispatch =>
		request.then(response =>
			dispatch({
				type: UPDATE_PROFILE_WITHEMAIL_SUCCESS,
				payload: response.data
			})
		);
}

	