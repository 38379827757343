const locale = {
	TITILEPROFILE: 'Profile',
	IDENTCON: 'Connection identifier',
	INFOPROFILE: 'Profile information',
	FIRSTNAME: 'Firstname',
	NAME: 'Name',
	TEL: 'Phone',
	CANCEL: 'Cancel',
	UPDATE: 'Update',
	CUSTOMERNAME: 'Customer Name',
	NBCARACTERES: 'At least 9 characters required',
	EMAILNOVALID: 'Email no valid',
	PASSWORD: 'Password',
	EMAIL: 'Email',
	UPDACNTSUC: 'Profile updated successfully',
	UPDACNTSUCPASS: 'Profile updated successfully, you will be redirected to the login page.',
	UPDACNTERR: 'An error occurred during the update'
};

export default locale;
