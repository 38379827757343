import React from 'react';

import i18next from 'i18next';
import en from './i18n/en';
import fr from './i18n/fr';
// import { authRoles } from 'app/auth';

i18next.addResourceBundle('fr', 'fashionhub', fr);
i18next.addResourceBundle('en', 'fashionhub', en);

const FashionhubConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/fashionhub',
			component: React.lazy(() => import('./Fashionhub'))
		}
	]
};

export default FashionhubConfig;
