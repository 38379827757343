import React from 'react';

function ArrowUpRight(props) {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.8335 14.1663L14.1668 5.83301"
				stroke="#023E65"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.8335 5.83301H14.1668V14.1663"
				stroke="#023E65"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default ArrowUpRight;
