import React from 'react';

function ArrowDownRight(props) {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M5.8335 5.83301L14.1668 14.1663"
				stroke="#023E65"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.1668 5.83301V14.1663H5.8335"
				stroke="#023E65"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default ArrowDownRight;
