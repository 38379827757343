import React from 'react';
import { authRoles } from 'app/auth';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import en from './i18n/en'; 
import fr from './i18n/fr';

i18next.addResourceBundle('fr', 'profile', fr);
i18next.addResourceBundle('en', 'profile', en); 

const ProfileConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.superAdmin,
	routes: [
		{
			path: '/parametres/profile',
			component: React.lazy(() => import('./Profile'))
		},
		{
			path: '/profile',
			component: () => <Redirect to="/parametres/profile" />
		}
	]
};

export default ProfileConfig;
