import React from 'react';

const PlaneIcon = props => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={props.className}
		>
			<rect width="20" height="20" rx="10" fill="#023E65" />
			<path
				d="M16.685 9.375L12 7V5C12 4.46957 11.7893 3.96086 11.4142 3.58579C11.0391 3.21071 10.5304 3 10 3C9.46957 3 8.96086 3.21071 8.58579 3.58579C8.21071 3.96086 8 4.46957 8 5V7L3.315 9.375C3.22197 9.41205 3.1422 9.47618 3.08604 9.55909C3.02988 9.642 2.9999 9.73986 3 9.84V12.34C3 12.4726 3.05268 12.5998 3.14645 12.6936C3.24022 12.7873 3.36739 12.84 3.5 12.84C3.5493 12.8389 3.59816 12.8304 3.645 12.815L8 11V13.5L6.275 14.36C6.19178 14.4019 6.12194 14.4663 6.07335 14.5458C6.02476 14.6253 5.99935 14.7168 6 14.81V16.5C6 16.6326 6.05268 16.7598 6.14645 16.8536C6.24022 16.9473 6.36739 17 6.5 17C6.54638 17.0073 6.59362 17.0073 6.64 17L10 16L13.36 17C13.4064 17.0073 13.4536 17.0073 13.5 17C13.6326 17 13.7598 16.9473 13.8536 16.8536C13.9473 16.7598 14 16.6326 14 16.5V14.81C14.0006 14.7168 13.9752 14.6253 13.9267 14.5458C13.8781 14.4663 13.8082 14.4019 13.725 14.36L12 13.5V11L16.355 12.805C16.4018 12.8204 16.4507 12.8289 16.5 12.83C16.6326 12.83 16.7598 12.7773 16.8536 12.6836C16.9473 12.5898 17 12.4626 17 12.33V9.83C16.9981 9.73157 16.9672 9.63589 16.9112 9.55495C16.8552 9.47401 16.7765 9.41141 16.685 9.375ZM16 11.575L11 9.5V14.12L13 15.12V15.835L10 14.96L7 15.835V15.12L9 14.12V9.5L4 11.575V10.15L9 7.615V5C9 4.73478 9.10536 4.48043 9.29289 4.29289C9.48043 4.10536 9.73478 4 10 4C10.2652 4 10.5196 4.10536 10.7071 4.29289C10.8946 4.48043 11 4.73478 11 5V7.615L13.5 8.8825L16 10.15V11.575Z"
				fill="white"
			/>
		</svg>
	);
};

export default PlaneIcon;
