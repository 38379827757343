import React from 'react';
import i18next from 'i18next';
import en from './i18n/en';
import es from './i18n/es';
import it from './i18n/it';
import fr from './i18n/fr';

i18next.addResourceBundle('en', 'transport', en);
i18next.addResourceBundle('es', 'transport', es);
i18next.addResourceBundle('it', 'transport', it);
i18next.addResourceBundle('fr', 'transport', fr);

const TransportConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/transport/transportdetails/asset/:idContainer/:idTransport',
			component: React.lazy(() => import('./transportDetailsAsset/TransportAssetDetails'))
		},
		{
			path: '/transport/transportdetails/:idTransport',
			component: React.lazy(() => import('./transportDetails/TransportDetails'))
		},
		{
			path: '/transport',
			component: React.lazy(() => import('./Transport'))
		}
	]
};

export default TransportConfig;
