import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import { authRoles } from 'app/auth';
import en from './i18n/en';
import fr from './i18n/fr';

i18next.addResourceBundle('en', 'masters', en);
i18next.addResourceBundle('fr', 'masters', fr);

const MastersConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/masters/list',
			component: React.lazy(() => import('./Masters'))
		},
		{
			path: '/masters/details/:uniqueIdentifiant',
			component: React.lazy(() => import('./deviceDetails/Details'))
		},
		{
			path: '/masters',
			component: () => <Redirect to="/masters/list" />
		}
	]
};

export default MastersConfig;

