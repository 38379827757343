import _ from '@lodash';
import * as Actions from '../actions';

const initialState = {
	routeParams: {},
	scDialog: {
		type: '',
		open: false,
		data: null
	}
};

const scDialogReducer = (state = initialState, action) => {
	const pathDialog = _.split(action.options, '/', 3);

	switch (action.type) {
		case Actions.OPEN_SC_DIALOG: {
			return {
				...state,
				routeParams: {
					path: `${pathDialog[1]}/${pathDialog[2]}`
				},
				scDialog: {
					type: pathDialog[1],
					open: true,
					data: {
						userId: pathDialog[2],
						path: action.options
					}
				}
			};
		}
		case Actions.CLOSE_SC_DIALOG: {
			return {
				...state,
				routeParams: {},
				scDialog: {
					type: pathDialog[1],
					open: false,
					data: null
				}
			};
		}
		case Actions.OPEN_PROFILE_SC_DIALOG: {
			return {
				...state,
				routeParams: {
					path: `${pathDialog[1]}/${pathDialog[2]}`
				},
				scDialog: {
					type: 'profile',
					open: true,
					data: {
						userId: pathDialog[2],
						path: action.options
					}
				}
			};
		}
		case Actions.CLOSE_PROFILE_SC_DIALOG: {
			return {
				...state,
				routeParams: {},
				scDialog: {
					type: 'profile',
					open: false,
					data: null
				}
			};
		}
		case Actions.OPEN_ALERTS_SC_DIALOG: {
			return {
				...state,
				routeParams: {
					path: `${pathDialog[1]}/${pathDialog[2]}`
				},
				scDialog: {
					type: 'alerts',
					open: true,
					data: {
						userId: pathDialog[2],
						path: action.options
					}
				}
			};
		}
		case Actions.CLOSE_ALERTS_SC_DIALOG: {
			return {
				...state,
				routeParams: {},
				scDialog: {
					type: 'alerts',
					open: false,
					data: null
				}
			};
		}
		case Actions.OPEN_NOTIFICATIONS_SC_DIALOG: {
			return {
				...state,
				routeParams: {
					path: `${pathDialog[1]}/${pathDialog[2]}`
				},
				scDialog: {
					type: 'notifications',
					open: true,
					data: {
						userId: pathDialog[2],
						path: action.options
					}
				}
			};
		}
		case Actions.CLOSE_NOTIFICATIONS_SC_DIALOG: {
			return {
				...state,
				routeParams: {},
				scDialog: {
					type: 'notifications',
					open: false,
					data: null
				}
			};
		}
		case Actions.OPEN_MEMBERS_SC_DIALOG: {
			return {
				...state,
				routeParams: {
					path: `${pathDialog[1]}/${pathDialog[2]}`
				},
				scDialog: {
					type: 'members',
					open: true,
					data: {
						userId: pathDialog[2],
						path: action.options
					}
				}
			};
		}
		case Actions.CLOSE_MEMBERS_SC_DIALOG: {
			return {
				...state,
				routeParams: {},
				scDialog: {
					type: 'members',
					open: false,
					data: null
				}
			};
		}
		case Actions.OPEN_LANGUES_SC_DIALOG: {
			return {
				...state,
				routeParams: {
					path: `${pathDialog[1]}/${pathDialog[2]}`
				},
				scDialog: {
					type: 'langues',
					open: true,
					data: {
						userId: pathDialog[2],
						path: action.options
					}
				}
			};
		}
		case Actions.CLOSE_LANGUES_SC_DIALOG: {
			return {
				...state,
				routeParams: {},
				scDialog: {
					type: 'langues',
					open: false,
					data: null
				}
			};
		}
		case Actions.OPEN_SEARCH_SC_DIALOG: {
			return {
				...state,
				routeParams: {
					path: `${pathDialog[1]}/${pathDialog[2]}`
				},
				scDialog: {
					type: 'search',
					open: true,
					data: {
						userId: pathDialog[2],
						path: action.options
					}
				}
			};
		}
		case Actions.CLOSE_SEARCH_SC_DIALOG: {
			return {
				...state,
				routeParams: {},
				scDialog: {
					type: 'search',
					open: false,
					data: null
				}
			};
		}
		default:
			return state;
	}
};

export default scDialogReducer;
