import FuseAnimate from '@fuse/core/FuseAnimate';
import { makeStyles, useTheme } from '@material-ui/core/styles';
// import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import React from 'react';
import clsx from 'clsx';
// import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		borderRadius: 2,
		padding: '0 2px',
		fontSize: 11
		// backgroundColor: 'rgba(0,0,0,.08);'
	},
	color: {
		width: 8,
		height: 8,
		marginRight: 4,
		borderRadius: '50%'
	},
	avatarLetter: {
		border: '2px solid #fff',
		fontSize: '1.5rem',
		fontWeight: 'bold',
		boxSizing: 'content-box',
		transform: 'translateX(-50%)',
		boxShadow: '0px 4px 4px rgba(7, 78, 232, 0.15)'
	},
	avatar: {
		boxSizing: 'content-box',
		transform: 'translateX(-50%)',
		transition: theme.transitions.create('all', {
			duration: theme.transitions.duration.shortest,
			easing: theme.transitions.easing.easeInOut
		}),
		'& > img': {
			borderRadius: '50%'
		}
	},
	'@global': {
		'.UserLinkAvatar .avatar': {
			backgroundColor: '#FAE3BD',
			color: '#EDA124'
		},
		'.AdminLinkAvatar .avatar': {
			backgroundColor: '#A8C1F7',
			color: '#074EE8'
		}
	},
	layoutRoot: {
		color: 'black',
		backgroundColor: '#0094DA'
		// background: theme.palette.background.default,
		// marginRight: 16,
	}
}));

function UserLink(props) {
	const classes = useStyles(props);

	const user = useSelector(({ auth }) => auth.user);
	const theme = useTheme();
	let colorAvatar;
	const initiales = user.data.displayName ? user.data.displayName.match(/\b\w/g).join('').toUpperCase() : '';

	if (user.role === 'user') colorAvatar = 'UserLinkAvatar';
	else colorAvatar = 'AdminLinkAvatar';

	const theAvatar =
		user.data.displayName !== '' ? (
			user.data.photoURL && user.data.photoURL !== '' ? (
				<Avatar className={clsx(classes.avatar, 'avatar')} alt="user photo" src={user.data.photoURL} />
			) : (
				<Avatar
					alt={`${user.data.firstname} ${user.data.lastname}`}
					className={clsx(classes.avatarLetter, 'avatar')}
				>
					{initiales}
				</Avatar>
			)
		) : null;

	return (
		<div
			className={clsx(
				classes.root,
				props.className,
				'user w-full relative flex flex-col items-center justify-center z-0'
			)}
		>
			<div classes={{ root: classes.color }} style={{ backgroundColor: props.color }} />
			<FuseAnimate animation="transition.slideRightIn" delay={300}>
				<div className={clsx(classes.root, colorAvatar)}>
					{theAvatar}
					<span
						style={{ fontSize: 16, fontWeight: 'bold', color: theme.palette.stracker.contrastTextDark }}
						className=""
					>
						{user.role === 'userAdmin' ? user.data.customerName : user.data.displayName}
					</span>
				</div>
			</FuseAnimate>
		</div>
	);
}

export default UserLink;
