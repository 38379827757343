const locale = {
	FASHIONHUB: 'Fashion HUB',
	BOOKING: 'Booking',
	CANCEL: 'Cancel',
	ADD: 'Add',
	MODIFY: 'Modify',
	VALIDATE: 'Validate',
	DEPARTURE: 'Departure',
	ARRIVAL: 'Arrival',
	CLIENTNUMBER: 'Client number',
	PACKAGENUMBER: 'Package number',
	GROWTHWEIGHT: 'Growth weight',
	AVERAGEWEIGHT: 'Average weight',
	AVERAGEDISCOUNT: 'Average discount',
	TODAYSHUTTLE: 'Shuttle of the day',
	TOMORROWSHUTTLE: 'Shuttle of tomorrow',
	AFTERTOMORROWSHUTTLE: 'Shuttle of after tomorrow',
	TIMELEFTFORSHUTTLE: 'Time left to book this shuttle',
	BOOKEDPACKAGE: 'packages booked in this shuttle',
	NOBOOKEDPACKAGE: 'No packages booked for this shuttle'
};

export default locale;
