import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import * as Actions from '../store/actions';

const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120
	},
	selectEmpty: {
		marginTop: theme.spacing(2)
	},
	BoxSelMembre: {
		borderRadius: '8px',
		minWidth: '100px',

		'& .BoxSelMembres fieldset': {
			border: 'none'
		}
	},
	BoxSelMembreInput: {
		borderRadius: '8px'
	},
	'@global': {
		'.MuiSelect-outlined.MuiSelect-outlined': {
			borderRadius: '8px'
		},
		'.MuiList-root.MuiMenu-list.MuiList-padding': {
			border: '1px solid',
			borderColor:
				theme.palette.type === 'light'
					? `${theme.palette.stracker.variantOutlined} !important`
					: `${theme.palette.stracker.contrastTextRegular} !important`,
			// #A8C1F7;
			boxShadow: '0px 4px 4px ' + theme.palette.secondary.colorShadow,
			// rgb(7 78 232 / 15%);
			borderRadius: '8px'
			// width: '140px',
		},
		'.MuiOutlinedInput-root': {
			borderRadius: '8px'
		},
		'.MuiSelect-selectMenu': {
			fontWeight: 600,
			color: theme.palette.stracker.contrastTextDark,
			border: '1px solid',
			borderColor:
				theme.palette.type === 'light'
					? `${theme.palette.stracker.variantOutlined} !important`
					: `${theme.palette.stracker.contrastTextRegular} !important`
		},
		'.MuiMenu-list.MuiList-padding .MuiListItem-root.Mui-selected': {
			backgroundColor: theme.palette.secondary.contrastBgFocus + ' !important'
		}
	}
}));

function RoleSelect(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	// const { t } = useTranslation('membres');

	const user = useSelector(({ auth }) => auth.user);
	const idConnectedUser = user.data.idUser;

	const [isDisabled, setIsDisabled] = React.useState(false);

	// eslint-disable-next-line
	const [role, setRole] = React.useState(true);

	const handleRoleChange = event => {
		let model = {};
		setRole(event.target.value);

		model.idUser = props.userId;
		model.idRole = event.target.value;
		model.idCustomer = user.data.idCustomer;
		model.idConnectedUser = idConnectedUser;

		dispatch(Actions.updateRoleMember(model));
	};

	useEffect(() => {
		if (user.role === 'userAdmin') {
			if (user.id === props.userId) setIsDisabled(true);
			else setIsDisabled(false);
		}
		if (user.role === 'superAdmin') {
			if (user.id === props.userId) setIsDisabled(true);
			else setIsDisabled(false);
		}
		if (user.role === 'user') {
			setIsDisabled(true);
		}
		// eslint-disable-next-line
	}, [user.role]);

	return (
		<div>
			<FormControl
				variant="outlined"
				className={(classes.formControl, classes.BoxSelMembre)}
				disabled={isDisabled}
			>
				{/* <InputLabel id="role-label">Role</InputLabel> */}
				<Select
					labelId="role-label"
					id="role-id"
					value={props.roleId}
					onChange={handleRoleChange}
					/* label="Role" */
					className={(classes.selectEmpty, classes.BoxSelMembreInput, 'BoxSelMembres')}
				>
					<MenuItem value="" className="hidden">
						<em>None</em>
					</MenuItem>
					<MenuItem value={2}>Admin</MenuItem>
					<MenuItem value={3}>User</MenuItem>
				</Select>
			</FormControl>
		</div>
	);
}

export default RoleSelect;
