import { Icon } from '@material-ui/core';
import clsx from 'clsx';
import HistoryIcon from '@stracker/customicons/HistoryIcon';
import ClientIcon from '@stracker/customicons/ClientIcon';
import MasterIcon from '@stracker/customicons/MasterIcon';
import AssetIcon from '@stracker/customicons/AssetIcon';
import TransportIcon from '@stracker/customicons/TransportIcon';
import ContentIcon from '@stracker/customicons/ContentIcon';
import DeviceIcon from '@stracker/customicons/DeviceIcon';
import CotationIcon from '@stracker/customicons/CotationIcon';
import AnalyticsIcon from '@stracker/customicons/AnalyticsIcon';
import GlobeIcon from '@stracker/customicons/GlobeIcon';
import React from 'react';
import DashboardIcon from '@stracker/customicons/DashboardIcon';

function StatusIcon(props) {
	switch (props.status) {
		case 'content':
			return <ContentIcon className={clsx(props.className, 'list-item-icon text-16 flex-shrink-0')} />;
		case 'asset':
			return <AssetIcon className={clsx(props.className, 'list-item-icon text-16 flex-shrink-0')} />;
		case 'master':
			return <MasterIcon className={clsx(props.className, 'list-item-icon text-16 flex-shrink-0')} />;
		case 'transport':
			return <TransportIcon className={clsx(props.className, 'list-item-icon text-16 flex-shrink-0')} />;
		case 'fashionhub':
			return <GlobeIcon className={clsx(props.className, 'list-item-icon text-16 flex-shrink-0')} />;
		case 'client':
			return <ClientIcon className={clsx(props.className, 'list-item-icon text-16 flex-shrink-0')} />;
		case 'device':
			return <DeviceIcon className={clsx(props.className, 'list-item-icon text-16 flex-shrink-0')} />;
		case 'history':
			return <HistoryIcon className={clsx(props.className, 'list-item-icon text-16 flex-shrink-0')} />;
		case 'home':
			return <DashboardIcon className={clsx(props.className, 'list-item-icon text-16 flex-shrink-0')} />;
		case 'cotation':
			return <CotationIcon className={clsx(props.className, 'list-item-icon text-16 flex-shrink-0')} />;
		case 'analytics':
			return <AnalyticsIcon className={clsx(props.className, 'list-item-icon text-16 flex-shrink-0')} />;
		default:
			return (
				<Icon className={clsx(props.className, 'list-item-icon text-16 flex-shrink-0')} color="action">
					{props.status}
				</Icon>
			);
	}
}

export default StatusIcon;
