import ForgotPasswordConfig from 'app/main/authe/forgot-password/ForgotPasswordConfig';
import LockConfig from 'app/main/authe/lock/LockConfig';
import LoginConfig from 'app/main/authe/login/LoginConfig';
import LogoutConfig from 'app/main/authe/logout/LogoutConfig';
import MailConfirmConfig from 'app/main/authe/mail-confirm/MailConfirmConfig';
import RegisterConfig from 'app/main/authe/register/RegisterConfig';
import ResetPasswordConfig from 'app/main/authe/reset-password/ResetPasswordConfig';
import UserSuccessfulConfig from 'app/main/authe/user-createsuccessful/UsersuccessfulConfig';
import EmailsentConfig from 'app/main/authe/emailsent/EmailsentConfig';
import Passwordmodified from 'app/main/authe/password-modified/PasswordmodifiedConfig';

const authConfigs = [
	ForgotPasswordConfig,
	LockConfig,
	LoginConfig,
	LogoutConfig,
	MailConfirmConfig,
	RegisterConfig,
	ResetPasswordConfig,
	UserSuccessfulConfig,
	EmailsentConfig,
	Passwordmodified
];

export default authConfigs;
