const locale = {
	APPLICATIONS: 'Applications',
	EXAMPLE: 'Example',
	DASHBOARD: 'Dashboard',
	TRANSPORT: 'Shipments',
	ASSETS: 'Assets',
	TRACKING: 'Tracking package indoor',
	DEVICES: 'Devices',
	CLIENT: 'Customer management',
	SEARCH: 'Search',
	CONTENT: 'Contents',
	HISTORY: 'History',
	MASTER_ASSET: 'Containers',
	COTATIONS: 'Quotes',
	ANALYTICS: 'Analytics',
	TASKS: 'Tasks',
	FASHIONHUB: 'Fashion Hub'
};

export default locale;
