import API from '@pi/api';

export const GET_NOTIFICATIONS = '[SUPPLY CHAIN NOTIFICATIONS DATA] GET NOTIFICATIONS DATA';
export const GET_NOTIFICATIONS_TRANSPORT_COTATION =
	'[NOTIFICATIONS TRANSPORT COTATIONS] GET NOTIFICATIONS TRANSPORT COTATIONS';
export const UPDATE_NOTIFICATION_TO_READ = '[SUPPLY CHAIN UPDATE_NOTIFICATIONS_TO_READ] UPDATE NOTIFICATION TO READ';
export const UPDATE_ALL_NOTIFICATIONS_TO_READ =
	'[SUPPLY CHAIN UPDATE_ALL_NOTIFICATIONS_TO_READ] UPDATE ALL NOTIFICATIONS TO READ';
export const STATUS_NOTIFICATIONS_DIALOG = '[SUPPLY CHAIN NOTIFICATIONS DATA] STATUS_NOTIFICATIONS_DIALOG';

export function getNotifications(idConnectedUser, idCustomer, full, idRole) {
	const request = API.post('/notif', {
		action: 'list',
		idConnectedUser,
		idCustomer,
		full
	});

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_NOTIFICATIONS,
				payload: response.data
			})
		);
}

export function getNotificationsTransportCotation(idConnectedUser, idCustomer, idRole) {
	const request = API.post('/notif', {
		action: 'unreadntfontr',
		idConnectedUser,
		idCustomer,
		idRole
	});

	return dispatch =>
		request.then(response =>
			dispatch({
				type: GET_NOTIFICATIONS_TRANSPORT_COTATION,
				payload: response.data
			})
		);
}

export const updateNotificationRead = (idConnectedUser, idCustomer, idNotification) => {
	const request = API.post('/notif', {
		action: 'updatestatus',
		idConnectedUser,
		idCustomer,
		status: 1,
		idNotification
	});

	return dispatch =>
		request.then(res => {
			dispatch({ type: UPDATE_NOTIFICATION_TO_READ, payload: res.data, idNotification });
		});
};

export const updateAllNotificationsRead = (idConnectedUser, idCustomer) => {
	/* const request = axios.put('/api/notifications'); */
	const request = API.post('/notif', {
		action: 'updatestatus',
		idConnectedUser,
		idCustomer,
		status: 1
	});

	return dispatch =>
		request.then(res => {
			dispatch({ type: UPDATE_ALL_NOTIFICATIONS_TO_READ, payload: res.data.status });
		});
};

export const updateStatusDialog = statusDialog => {
	return dispatch => dispatch({ type: STATUS_NOTIFICATIONS_DIALOG, payload: statusDialog });
};
