const locale = {
	TITLE: 'Example Page',
	NAME: 'Nom',
	FIRSTNAME: 'Prénom',
	CONNEXION: 'Connexion',
	REMEMBER: 'Se souvenir',
	FORGOT: 'Mot de passe oublié',
	PHONE: 'Téléphone',
	EMAIL: 'Adresse email',
	PASSWORD: 'Mot de passe',
	PWDCONFIRM: 'Mot de passe (Confirmation)',
	CREATE: 'Créer un compte',
	TERMSCOND: "J'ai et j'accepte les conditions.",
	NEWSLETTER:
		"J'accepte que stracker360.fr m'envoie des informations sur des promotions ou des services fournis par Legalstart.fr",
	NBCARACTERES: 'Votre mot de passe doit contenir au moins 9 caractères',
	RESETPASS: 'Modifiez votre mot de passe',
	VALIDER: 'Valider',
	CONFIRMEMAIL: 'Confirm your email address!',
	CONFIRMEMAILTXT: 'A confirmation e-mail has been sent to <b>example@mymail.com</b>.',
	CHECKEMAIL: 'Check your inbox and click on the "Confirm my email" link to confirm your email address.',
	BACKTOLOGIN: 'Page de connexion',
	RECOVERTITLE: 'Mot de passe oublié',
	RECOVER: 'Recevoir le lien de réinitialisation',
	MSGPWDFAIBLE: 'Le nombre de caractères est insuffisant',
	MSGPWDMOYENNE: 'La sécurité de votre mot de passe est moyenne',
	MSGPWDFORTE: 'La sécurité de votre mot de passe est forte',
	PWDCREATED: 'Mot de passe créé',
	COMPTECREATED: 'Votre compte est complet et créé. Vous allez être redirigé vers la page de connexion.',
	TXTFORGOTEMAIL:
		'Saisissez l’adresse e-mail associé à votre compte. Nous vous enverrons un lien par e-mail pour réinitialiser votre mot de passe.', // Votre compte est complet et créé. Vous allez être redirigé vers la page de connexion.',
	EMAILSENT: 'Email envoyé',
	EMAILLINK: 'Un lien pour réinitialiser votre mot de passe vous a été envoyé.',
	NOCOMPTE: "Cet email n'est associé à aucun compte",
	PWDMODIFIED: 'Mot de passe modifié',
	TXTMODIFIED: 'Votre mot de passe a bien été modifié. Vous allez être redirigé vers la page de connexion.',
	EMAILNOVALID: "L'adresse email n'est pas valide",
	IDENTIFIANTSNOVALID: 'Votre email ou votre mot de passe ne correspond pas. Réessayez.',
	EMAILNOEXIST: "Cette adresse email n'existe pas",
	REQCLPWD: 'Le mot de passe doit contenir au moins une lettre majuscule',
	NOFORGOTPWD: 'Mot de passe non déclaré oublié',
	NOVALIDACTION: 'Action non valide',
	UNDIFACTION: 'Action non spécifiée',
	PWDNOMATCH: 'Les mots de passe ne concorde pas',
	ISREGISTERED: 'Ce compte a déjà été validé',
	NOAUTORIZED: "Vous n'êtes autorisé à vous connecter",
	GETQUOTE: 'Obtenir une cotation',
	LANGUAGE: 'Langage',
	US: 'Anglais',
	FR: 'Français'
};

export default locale;
