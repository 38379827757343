import styled from 'styled-components';
import { TextFieldFormsy } from '../../../@fuse/core/formsy';

// eslint-disable-next-line import/prefer-default-export
export const TextFieldFormsyStyled = styled(TextFieldFormsy)`
	margin-bottom: 5px;
	margin-right: ${props => (props.noMr ? '0' : '10')}px;
	width: 100%;
	input {
		background-color: ${props => props.theme.palette.stracker.input};
	    color: ${props => props.theme.palette.stracker.contrastTextDark};
		padding: 8px;
		font-weight: 600;
		border-radius: 8px;
	}
	fieldset {
		border: none;
	}
	[class*='MuiOutlinedInput-root'] {
		border-radius: 8px;
	}
	.Mui-disabled {
		opacity: 0.85;
	}
`;
