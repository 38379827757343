import i18next from 'i18next';
import en from './navigation-i18n/en';
import fr from './navigation-i18n/fr';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('fr', 'navigation', fr);

const navigationConfigSupplyChain = [
	{
		id: 'dashboard',
		title: 'Dashboard',
		translate: 'DASHBOARD',
		type: 'item',
		icon: 'home',
		url: '/dashboards'
	},
	{
		id: 'asset',
		title: 'Assets',
		translate: 'ASSETS',
		type: 'item',
		icon: 'asset',
		url: '/assets'
	},
	{
		id: 'masters',
		title: 'Contenants Master',
		translate: 'MASTER_ASSET',
		type: 'item',
		icon: 'master',
		url: '/masters'
	},
	{
		id: 'content',
		title: 'Content',
		translate: 'CONTENT',
		type: 'item',
		icon: 'content',
		url: '/contenus'
	},
	{
		id: 'device',
		title: 'Devices',
		translate: 'DEVICES',
		type: 'item',
		icon: 'device',
		url: '/devices'
	},
	{
		id: 'history',
		title: 'History',
		translate: 'HISTORY',
		type: 'item',
		icon: 'history',
		url: '/history'
	}
];

export default navigationConfigSupplyChain;
