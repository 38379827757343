const locale = {
	TRANSPORT: 'Transporto',
	TO_COMPLETE: 'Completare',
	READY_TO_GO: 'Pronto ad andare',
	TRANSIT: 'In transito',
	DESTINATION_END: 'Arrivo',
	ADD_TRANSPORT: 'Aggiungi un trasporto',
	TRACKER_LIST: 'Elenco dei tracker',
	BOUTON_CANCEL: 'per cancellare',
	VALIDATE: 'convalidare',
	BOUTON_FINISHED: 'Finito',
	TRANSPORT_TYPE: 'Tipo di trasporto',
	DEPARTURE: 'Partenza',
	ETAPES: 'Passi',
	ETAPE: 'Passo',
	ARRIVAL: 'Arrivo',
	AIRCRAFT: 'Aereo',
	BATEAU: 'Barca',
	TRUCK: 'Camion',
	MINI_CARACTERE: 'Minimo 3 caratteri',
	ADD_A_STEP: 'Aggiungi un passaggio',
	ADD: 'Inserisci',
	DELET_A_STEP: 'Elimina questo passaggio',
	ADDRESS: 'Indirizzo',
	ERROR_SELECT_TRACKER: 'Seleziona almeno un tracker',
	ERROR_ADD_TRANSPORT: 'Immettere un nome di trasporto',
	SEARCH_FOR_TRACKER: 'Trovare un tracker',
	SEARCH_FOR_TRANSPORT: 'Cerca un mezzo di trasporto',
	SELECT_AN_ADDRESS: 'Seleziona un indirizzo',
	ERROR_TRANSPORT_NAME: 'Questo nome è già utilizzato per un altro trasporto',
	ADD_ASSET_REFERENCE: 'Aggiungi un riferimento alla risorsa',
	ERROR_MESSAGE_DIALOG3: 'è già utilizzato come nome della risorsa',
	CREATED: 'Creato',
	NO_TRACKER_AVAILABLE: 'Nessun tracker disponibile',
	INCORRECT_ADDRESS: 'Indirizzo errato',
	CLOSE_TRANSPORT: 'Chiudere il trasporto',
	DELETE_ASSET_TRANSPORT: 'Vuoi eliminare questa risorsa',
	UPDATE_ON: 'Aggiornamento su ',
	CURRENT_ROUTE: 'Percorso attuale'
};

export default locale;
