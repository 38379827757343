import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import _ from '@lodash';
import { Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText } from '@material-ui/core';
import DeleteIcon from '../../../../custom-icons/DeleteIcon2';
import * as Actions from '../store/actions';
import AddIconBlue from '../../../../custom-icons/AddIconBlue';
import { TextFieldFormsyStyled } from '../../../../../../@stracker/components/StyledComponents/TextFieldFormsyStyled';
import { ButtonStyled } from '../../../../../../@stracker/components/StyledComponents/ButtonStyled';

function AddEmail(props) {
	const dispatch = useDispatch();
	const theme = useTheme();
	const { t } = useTranslation('notifications');

	const user = useSelector(({ auth }) => auth.user);
	const { idCustomer } = user.data;
	const idConnectedUser = user.data.idUser;

	const notificationsEmail = useSelector(({ notifications }) => notifications.notifications.notificationsEmail);

	const [formList, setFormList] = useState('');
	const [idNotif, setIdNotif] = useState(null);

	const [open, setOpen] = useState(false);

	const handleInputChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...formList];
		list[index][name] = value;
		setFormList(list);
		props.hangleNotification(list);
	};

	const handleAddClick = () => {
		setFormList([{ contactId: '', contactType: 'email' }, ...formList]);
	};

	const handleClickOpenDialog = () => {
		setOpen(true);
	};

	const handleCloseDialog = () => {
		setIdNotif(null);
		setOpen(false);
	};

	React.useEffect(() => {
		dispatch(Actions.getNotificationsEmail(idCustomer));
		// eslint-disable-next-line
	}, [dispatch]);

	React.useEffect(() => {
		if (notificationsEmail) {
			setFormList(notificationsEmail);
		}
	}, [notificationsEmail]);

	function printDialog(id, index) {
		if (id) {
			setIdNotif(id);
			handleClickOpenDialog();
		} else {
			const listUpdate = [...formList];
			listUpdate.splice(index, 1);
			setFormList(listUpdate);
		}
	}

	function deleteNotif() {
		dispatch(Actions.deleteNotification(idNotif, idCustomer, idConnectedUser));
		setOpen(false);
		props.hangleNotification(formList);
	}

	return (
		<div className="mb-20">
			<Dialog
				open={open}
				onClose={handleCloseDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle style={{ color: theme.palette.stracker.contrastTextDark }} id="alert-dialog-title">
					Supprimer la notification par mail
				</DialogTitle>
				<DialogContent>
					<DialogContentText
						style={{ color: theme.palette.stracker.contrastTextDark }}
						id="alert-dialog-description"
						className="font-semibold mb-0"
					>
						{t('CONFIRM_DELETE_NOTIF')}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<ButtonStyled type="button" className="ml-5 py-7 px-10" onClick={handleCloseDialog} secondary>
						{t('BOUTON_CANCEL')}
					</ButtonStyled>
					<ButtonStyled type="button" className="ml-5 py-7 px-10" onClick={() => deleteNotif()}>
						{t('BOUTON_VALIDER')}
					</ButtonStyled>
				</DialogActions>
			</Dialog>

			<div className="flex items-center mb-5">
				<IconButton className="p-5 mr-5" aria-label="add" onClick={handleAddClick}>
					<Icon className="" color="action">
						<AddIconBlue
							bgColorM={theme.palette.stracker.contrastText}
							boderColorM={'transparence'}
							ColorInM={theme.palette.stracker.body}
						/>
					</Icon>
				</IconButton>

				<p>{t('ADDEMAILS')}</p>
			</div>
			<div className="">
				{formList &&
					_.toArray(formList).map((x, i) => {
						return (
							<ListItem className="p-0 items-center" key={i}>
								{user.role === 'user' ? (
									''
								) : (
									<IconButton
										className="p-5 mr-5 text-24"
										aria-label="delete"
										onClick={() => printDialog(x.idNotificationContact, i)}
									>
										<DeleteIcon
											boderColorM={theme.palette.stracker.contrastOtherButton}
											ColorInM={theme.palette.stracker.contrastTextDark}
										/>
									</IconButton>
								)}
								<TextFieldFormsyStyled
									id={`email${i}`}
									name="contactId"
									type="text"
									placeholder={t('EMAIL')}
									value={x.contactId}
									onChange={e => handleInputChange(e, i)}
									validations={{
										isEmail: true
									}}
									validationErrors={{
										isEmail: t('NOEMAIL')
									}}
									variant="outlined"
									disabled={user.role === 'user' ? true : false}
								/>
							</ListItem>
						);
					})}
			</div>
		</div>
	);
}

export default AddEmail;
