const locale = {
	FASHIONHUB: "Fashion HUB",
	BOOKING: "Réservation",
	CANCEL: 'Annuler',
	ADD: 'Ajouter',
	VALIDATE: 'Valider',
	MODIFY: 'Modifier',
	DEPARTURE: 'Départ',
	ARRIVAL: 'Arrivée',
	CLIENTNUMBER: 'Nombre de clients',
	PACKAGENUMBER: 'Nombre de colis',
	GROWTHWEIGHT: 'Poids total',
	AVERAGEWEIGHT: 'Poids moyen par colis',
	AVERAGEDISCOUNT: 'Remise moyenne',
	TODAYSHUTTLE: 'Navette du jour',
	TOMORROWSHUTTLE: 'Navette de demain',
	AFTERTOMORROWSHUTTLE: "Navette d'après demain",
	TIMELEFTFORSHUTTLE: 'Temps restant pour réserver la navette',
	BOOKEDPACKAGE: 'colis réservé(s) dans la navette du jour',
	NOBOOKEDPACKAGE: 'Aucun colis réservé dans la navette du jour'
};

export default locale;
