import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './i18n/en';
import fr from './i18n/fr';

i18next.addResourceBundle('en', 'contenus', en);
i18next.addResourceBundle('fr', 'contenus', fr);

const ContenusConfig = {
	settings: {
		layout: {
			config: {
				// 	mode: 'fullwidth',
				// 	scroll: 'content',
				// 	navbar: { display: true },
				// 	toolbar: { display: false },
				// footer: { display: true },
				// 	leftSidePanel: { display: true },
				// 	rightSidePanel: { display: false }
			}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/contenus/list',
			component: React.lazy(() => import('./Contenus'))
		},
		{
			path: '/contenus',
			component: () => <Redirect to="/contenus/list" />
		}
	]
};

export default ContenusConfig;

/**
 * Lazy load Example
 */
/*
import React from 'react';

const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
};

export default ExampleConfig;

*/
