import mock from '../mock';
// import _ from '@lodash';

const mapindoorDB = {
	indoorMarkets: [
		{
			id: 1012,
			name: '3d_rotation',
			etages: 1,
			src: '/assets/imgs/roommap/firstFloor1.png',
			markets: [
				{
					id: 1011,
					roomname: 'ac_unit',
					quantity: 1,
					status: 'offline',
					Y: 2,
					X: 2
				},
				{
					id: 1010,
					name: 'access_alarm',
					quantity: 2,
					status: 'offline',
					Y: 10,
					X: 25
				},
				{
					id: 1009,
					roomname: 'ac_unit',
					quantity: 4,
					status: 'online',
					Y: 20,
					X: 30
				},
				{
					id: 1008,
					name: 'access_alarm',
					quantity: 1,
					status: 'online',
					Y: 30,
					X: 40
				},
				{
					id: 1248,
					name: 'both_alarm',
					quantity: 12,
					status: 'both',
					Y: 50,
					X: 60
				}
			]
		},
		{
			id: 1003,
			name: 'accessible_forward',
			etages: 2,
			src: '/assets/imgs/roommap/floorplans_bulding2.png',
			markets: [
				{
					id: 1004,
					name: 'accessibility',
					quantity: 2,
					status: 'offline',
					Y: 10,
					X: 15
				},
				{
					id: 1005,
					name: 'accessibility_new',
					quantity: 4,
					status: 'online',
					Y: 10,
					X: 15
				},
				{
					id: 1006,
					name: 'access_time',
					quantity: 1,
					status: 'offline',
					Y: 10,
					X: 15
				},
				{
					id: 1007,
					name: 'access_alarms',
					quantity: 1,
					status: 'online',
					Y: 10,
					X: 15
				}
			]
		},
		{
			id: 1021,
			name: '3d_rotation',
			etages: 3,
			src: '/assets/imgs/roommap/floorplans_bulding1.png',
			markets: [
				{
					id: 1743,
					roomname: 'account_balance_wallet',
					quantity: 4,
					status: 'online',
					Y: 10,
					X: 15
				},
				{
					id: 1000,
					name: 'account_balance',
					quantity: 1,
					status: 'online',
					Y: 10,
					X: 15
				},
				{
					id: 1001,
					name: 'account_balance_wallet',
					quantity: 6,
					status: 'offline',
					Y: 10,
					X: 15
				},
				{
					id: 1002,
					name: 'accessible',
					quantity: 1,
					status: 'offline',
					Y: 10,
					X: 15
				}
			]
		},
		{
			id: 1018,
			name: 'add_circle_outline',
			etages: 4,
			src: '/assets/imgs/roommap/firstFloor1.png',
			markets: [
				{
					id: 994,
					name: 'add_alert',
					quantity: 1,
					status: 'online',
					Y: 10,
					X: 15
				},
				{
					id: 995,
					name: 'add_alarm',
					quantity: 1,
					status: 'offline',
					Y: 10,
					X: 15
				},
				{
					id: 998,
					name: 'account_circle',
					quantity: 4,
					status: 'online',
					Y: 10,
					X: 15
				},
				{
					id: 999,
					name: 'account_box',
					quantity: 6,
					status: 'offline',
					Y: 10,
					X: 15
				}
			]
		}
	],
	indoorSites: {
		idCustomer: 8,
		customerName: 'Edf',
		nbEtage: 4
	}
};

mock.onGet('/api/scmapindoor-db/indoorMarkets').reply(config => {
	return [200, mapindoorDB.indoorMarkets];
});

mock.onGet('/api/scmapindoor-db/indoorSites').reply(config => {
	return [200, mapindoorDB.indoorSites];
});
