import mock from '../mock';

const notificationsDb = {
	status: 'ok',
	totalNotification: 20,
	totalUnread: 15,
	data: [
		{
			idNotification: 1,
			isRead: true,
			firstName: 'sarah',
			lastName: 'connor',
			createdAt: '2021-03-20T13:40:40+00:00',
			content: 'Michel Connor à modifié le nombre de trackers disponibles',
			title: 'modification',
			avatarUrl: null
		},
		{
			idNotification: 2,
			isRead: false,
			firstName: 'sarah',
			lastName: 'connor',
			createdAt: '2021-03-22T19:40:40+00:00',
			content: 'Sarah Connor à modifié le nombre de trackers disponibles',
			title: 'modification',
			avatarUrl: 'https://picsum.photos/200/500'
		},
		{
			idNotification: 3,
			isRead: false,
			firstName: 'sarah',
			lastName: 'connor',
			createdAt: '2021-03-21T11:40:40+00:00',
			content: 'Sarah Connor à modifié le nombre de trackers disponibles',
			title: 'modification',
			avatarUrl: 'https://source.unsplash.com/random'
		},
		{
			idNotification: 4,
			isRead: false,
			firstName: 'sarah',
			lastName: 'connor',
			createdAt: '2021-03-18T12:40:40+00:00',
			content: 'mike Connor à modifié le nombre de trackers disponibles',
			title: 'modification',
			avatarUrl: null
		},
		{
			idNotification: 5,
			isRead: false,
			firstName: 'sarah',
			lastName: 'connor',
			createdAt: '2021-03-16T11:40:40+00:00',
			content: 'Sarah Connor à modifié le nombre de trackers disponibles',
			title: 'modification',
			avatarUrl: 'https://source.unsplash.com/random'
		},
		{
			idNotification: 6,
			isRead: false,
			firstName: 'sarah',
			lastName: 'connor',
			createdAt: '2021-03-17T13:40:40+00:00',
			content: 'Sarah Connor à modifié le nombre de trackers disponibles',
			title: 'modification',
			avatarUrl: 'https://picsum.photos/200/500'
		},
		{
			idNotification: 7,
			isRead: false,
			firstName: 'sarah',
			lastName: 'connor',
			createdAt: '2021-03-19T15:40:40+00:00',
			content: 'Sarah Connor à modifié le nombre de trackers disponibles',
			title: 'modification',
			avatarUrl: 'https://picsum.photos/200/500'
		},
		{
			idNotification: 8,
			isRead: false,
			firstName: 'sarah',
			lastName: 'connor',
			createdAt: '2021-03-19T17:40:40+00:00',
			content: 'Sarah Connor à modifié le nombre de trackers disponibles',
			title: 'modification',
			avatarUrl: 'https://source.unsplash.com/random'
		},
		{
			idNotification: 9,
			isRead: false,
			firstName: 'sarah',
			lastName: 'connor',
			createdAt: '2021-03-20T10:30:40+00:00',
			content: 'Sarah Connor à modifié le nombre de trackers disponibles',
			title: 'modification',
			avatarUrl: 'https://picsum.photos/200/500'
		},
		{
			idNotification: 10,
			isRead: true,
			firstName: 'sarah',
			lastName: 'connor',
			createdAt: '2021-03-20T13:10:40+00:00',
			content: 'Sarah Connor à modifié le nombre de trackers disponibles',
			title: 'modification',
			avatarUrl: 'https://picsum.photos/200/500'
		},
		{
			idNotification: 11,
			isRead: false,
			firstName: 'sarah',
			lastName: 'connor',
			createdAt: '2021-03-19T10:40:40+00:00',
			content: 'Sarah Connor à modifié le nombre de trackers disponibles',
			title: 'modification',
			avatarUrl: null
		},
		{
			idNotification: 12,
			isRead: true,
			firstName: 'sarah',
			lastName: 'connor',
			createdAt: '2021-03-20T13:40:40+00:00',
			content: 'Sarah Connor à modifié le nombre de trackers disponibles',
			title: 'modification',
			avatarUrl: null
		},
		{
			idNotification: 13,
			isRead: false,
			firstName: 'sarah',
			lastName: 'connor',
			createdAt: '2021-03-20T13:40:40+00:00',
			content: 'Sarah Connor à modifié le nombre de trackers disponibles',
			title: 'modification',
			avatarUrl: null
		},
		{
			idNotification: 14,
			isRead: true,
			firstName: 'sarah',
			lastName: 'connor',
			createdAt: '2021-03-22T11:40:40+00:00',
			content: 'Sarah Connor à modifié le nombre de trackers disponibles',
			title: 'modification',
			avatarUrl: null
		},
		{
			idNotification: 15,
			isRead: false,
			firstName: 'sarah',
			lastName: 'connor',
			createdAt: '2021-03-22T17:30:40+00:00',
			content: 'Sarah Connor à modifié le nombre de trackers disponibles',
			title: 'modification',
			avatarUrl: null
		},
		{
			idNotification: 16,
			isRead: false,
			firstName: 'sarah',
			lastName: 'connor',
			createdAt: '2021-03-22T17:30:40+00:00',
			content: 'Sarah Connor à modifié le nombre de trackers disponibles',
			title: 'modification',
			avatarUrl: null
		},
		{
			idNotification: 17,
			isRead: false,
			firstName: 'sarah',
			lastName: 'connor',
			createdAt: '2021-03-22T17:30:40+00:00',
			content: 'Sarah Connor à modifié le nombre de trackers disponibles',
			title: 'modification',
			avatarUrl: null
		},
		{
			idNotification: 18,
			isRead: false,
			firstName: 'sarah',
			lastName: 'connor',
			createdAt: '2021-03-22T17:30:40+00:00',
			content: 'Sarah Connor à modifié le nombre de trackers disponibles',
			title: 'modification',
			avatarUrl: null
		},
		{
			idNotification: 19,
			isRead: false,
			firstName: 'sarah',
			lastName: 'connor',
			createdAt: '2021-03-22T17:30:40+00:00',
			content: 'Sarah Connor à modifié le nombre de trackers disponibles',
			title: 'modification',
			avatarUrl: null
		},
		{
			idNotification: 20,
			isRead: false,
			firstName: 'sarah',
			lastName: 'connor',
			createdAt: '2021-03-22T17:30:40+00:00',
			content: 'Sarah Connor à modifié le nombre de trackers disponibles',
			title: 'modification',
			avatarUrl: null
		}
	]
};

mock.onGet('/api/notifications').reply(config => {
	return [200, notificationsDb];
});

mock.onPut('/api/notification', { id: 5 }).reply(request => {
	const data = JSON.parse(request.data);

	notificationsDb.data = notificationsDb.data.map(notif => {
		if (notif.idNotification === data.id) {
			return {
				...notif,
				isRead: true
			};
		}
		return notif;
	});

	notificationsDb.totalUnread -= 1;

	return [200, notificationsDb];
});

mock.onPut('/api/notifications').reply(request => {
	notificationsDb.data = notificationsDb.data.map(notif => {
		if (notif.isRead === false) {
			return {
				...notif,
				isRead: true
			};
		}
		return notif;
	});

	notificationsDb.totalUnread = 0;

	return [200, notificationsDb];
});
