import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import en from './i18n/en';
import fr from './i18n/fr';

i18next.addResourceBundle('fr', 'searchP', fr);
i18next.addResourceBundle('en', 'searchP', en);

const SearchConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	// auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/parametres/search',
			component: React.lazy(() => import('./Search'))
		},
		{
			path: '/searchP',
			component: () => <Redirect to="/parametres/search" />
		}
	]
};

export default SearchConfig;
