const locale = {
	ASSET: 'Colis',
	CLOSING: 'Clôturée',
	CONNEXION: 'Connectivité',
	CONTENU: 'Contenu(s)',
	CREATEAT: 'Créé',
	ERROR_MIN_CHAR: 'Veuillez rentrer au moins 3 caractères',
	FENCING: 'Cloturé',
	HIDE_FILTERS: 'Masquer filtres',
	HUMIDITY: 'Humidité',
	NO_RESULT: 'pas de résultats',
	PLACEHOLDER_SEARCH: "Entrez la référence d’un transport ou d'un tag",
	POSITION: 'Localisation',
	RANGE_BY: 'Ranger par',
	RANGE_BY_ASC: 'Du plus récent au moins récent',
	RANGE_BY_DSC: 'Du moins récent au plus récent',
	RESULT: 'Résultat',
	RESULT_TEXT: 'Résultat de la recherche',
	SEARCH: 'Rechercher',
	SHOW_FILTERS: 'Afficher les filtres',
	TEMPERATURE: 'Température',
	TITLE: 'Recherche',
	TITLE_INFO: 'Informations libres'
};

export default locale;
