import React from 'react';
import QuickPanel from './quickPanel/QuickPanel';

function RightSideLayout4(props) {
	return (
		<>
			<QuickPanel />
		</>
	);
}

export default React.memo(RightSideLayout4);
