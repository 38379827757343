import FuseScrollbars from '@fuse/core/FuseScrollbars';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Icon from '@material-ui/core/Icon';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Logo from 'app/fuse-layouts/layout4/components/Logo';
import NavbarFoldedToggleButton from 'app/fuse-layouts/layout4/components/NavbarFoldedToggleButton';
import NavbarMobileToggleButton from 'app/fuse-layouts/layout4/components/NavbarMobileToggleButton';
import Navigation from 'app/fuse-layouts/layout4/components/Navigation';
import React from 'react';
import clsx from 'clsx';
import UserNavigation from './userNav/UserNavigation';
import SettingNav from './settings/SettingNav';

const useStyles = makeStyles(theme => ({
	root: {
		'& ::-webkit-scrollbar-thumb': {
			boxShadow: `inset 0 0 0 20px ${
				theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.24)' : 'rgba(255, 255, 255, 0.24)'
			}`
		},
		'& ::-webkit-scrollbar-thumb:active': {
			boxShadow: `inset 0 0 0 20px ${
				theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.37)' : 'rgba(255, 255, 255, 0.37)'
			}`
		},
		color: theme.palette.stracker.contrastTextDark,
		background: 'red' //`${theme.palette.stracker.variantFocus}!important`, // '#D4E5FF',
	},
	headerNav: {
		backgroundColor: `${theme.palette.stracker.variantFocus}!important`
	},
	userNavigation: {
		position: 'relative',
		'&.list-item': {
			padding: '14px 6px'
		}
	},
	content: {
		overflowX: 'hidden',
		height: '100%',
		overflowY: 'auto',
		background: `${theme.palette.stracker.variantFocus}!important`, // '#D4E5FF',
		backgroundRepeat: 'no-repeat',
		backgroundSize: '100% 40px, 100% 10px',
		backgroundAttachment: 'local, scroll',
		'-webkit-overflow-scrolling': 'touch'
		// 'linear-gradient(rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 30%), linear-gradient(rgba(0, 0, 0, 0.25) 0, rgba(0, 0, 0, 0) 40%)',
	},
	listItem: {
		color: 'inherit!important',
		textDecoration: 'none!important',
		height: 40,
		width: 'calc(100% - 16px)',
		borderRadius: '5px',
		paddingLeft: 24,
		paddingRight: 12,
		paddingTop: 12,
		paddingBottom: 12,
		'&.active': {
			fontSize: ' 1.6rem',
			borderRadius: '8px',
			boxShadow: '0px 4px 4px rgb(7 78 232 / 15%)',
			backgroundColor: theme.palette.background.paper,
			color: `${theme.palette.primary.contrastText}`,
			pointerEvents: 'none',
			'& .list-item-icon': {
				color: 'inherit'
			},
			'&.active:after': {
				content: '',
				border: '1px solid #074EE8',
				height: '20px'
			}
		},
		'& .list-item-icon': {
			marginRight: 16
		}
	}
}));

function NavbarLayout4(props) {
	const classes = useStyles();
	const theme = useTheme();

	return (
		<div className={clsx('flex flex-col overflow-hidden h-full', props.className)}>
			<AppBar
				position="static"
				className={clsx(
					classes.headerNav,
					'flex flex-row items-center flex-shrink h-48 md:h-64 min-h-48 md:min-h-64 px-12 shadow-0'
				)}
			>
				<div className="flex flex-1 mx-8">
					<Logo />
				</div>

				<Hidden mdDown>
					<NavbarFoldedToggleButton className="w-40 h-40 p-0 hidden" />
				</Hidden>

				<Hidden lgUp>
					<NavbarMobileToggleButton className="w-40 h-40 p-0">
						<Icon>{theme.direction === 'ltr' ? 'arrow_back' : 'arrow_forward'}"</Icon>
					</NavbarMobileToggleButton>
				</Hidden>
			</AppBar>

			<FuseScrollbars
				className={clsx(classes.content, 'flex flex-col justify-between')}
				option={{ suppressScrollX: true }}
			>
				<div className={clsx(classes.userNavigation, 'flex items-start p-0')}>
					<UserNavigation />
				</div>

				<Navigation className="flex-1 p-0" layout="vertical" />

				<div className={clsx(classes.settingNav, 'flex items-center p-0 w-full relative')}>
					<SettingNav />
				</div>
			</FuseScrollbars>
		</div>
	);
}

export default React.memo(NavbarLayout4);
