const locale = {
	ACQUISITION: 'Acquisition',
	ADD: 'Add',
	ADD_DEVICE: 'Add a device',
	ADD_NEW_TRACKER: 'Add a new device',
	ADD_SUPPLY: 'Create an asset',
	ADD_TRANSPORT: 'Create a transport',
	ADVANCED_SETTINGS: 'Advanced settings',
	ASSET_INFORMATION: 'Asset information',
	ASSOCIER: 'Associate',
	ASSSOCIER_TRACKER: 'Link a tracker to customer',
	BOUTON_ASSOCIER: 'Link',
	BOUTON_CANCEL: 'Cancel',
	BOUTON_VALIDER: 'Confirm',
	CHOOSE_TYPE_TRACKER: 'Tracker type',
	CONFIRMER_RECYCLAGE: 'Confirm recycling',
	CONNEXION: 'Connectivity',
	CONTENT: 'Content(s)',
	CREATE_A_TRACKER: 'Create a tracker',
	CREATE_TRACKER: 'Create tracker',
	CUSTOMER: 'Customer',
	DELETE_TRACKER: 'Delete tracker',
	DIALOG_ALERT_GENERAL: 'Alerts - custom',
	DIALOG_ALERT_PRESET: 'Alerts - favorite',
	DISSOCIER: 'Dissociate',
	DISSOCIER_TRACKER_TEXT: 'Lorem Ipsum',
	DISSOCIER_TRACKER_part1: 'Do you want to unlink the tracker ',
	DISSOCIER_TRACKER_part2: ' from customer ',
	EMISSION: 'Transmission',
	GEOLOCALISATION: 'Geolocation',
	HUMIDITY: 'Humidity',
	HUMIDITY_HISTORY: 'Humidity history',
	LATER: 'Later',
	MESSAGE_RECYCLE: 'Do you want to recycle the tracker ',
	MODES: 'Modes',
	MODIFIER_TRACKER: 'Modify this tracker',
	NEXT: 'Next',
	PARAMETRER_TRACKER: 'Configure this tracker',
	POSITION: 'Position',
	RANGE_BY: 'Ranger by',
	RANGE_BY_ASC: 'From latest to oldest',
	RANGE_BY_DSC: 'From oldest to latest',
	RECYCLAGE_CLIENT_TEXT: 'A recycling sheet will be sent to you by email for printing',
	SEARCH: 'Search',
	SEND: 'Send',
	SETTINGS: 'Settings',
	SET_UP_TRACKER: 'Set up tracker',
	SIMILAR_POSITION: '# similar positions',
	TEMPERATURE: 'Temperature',
	TEMPERATURE_HISTORY: 'Temperature history',
	TEMP_HUMIDITY: 'Temperature and Humidity',
	TITLE: 'Devices',
	TRACKER: 'Tracker',
	TRACKERS: 'Devices',
	UPDATE_ON: 'Update on',
	YES: 'Yes',
	EVENT: 'Events',
	SENSOR: 'Sensor',
	MAP: 'Map',
	DATE_PICKER_BEGIN: 'Begin',
	DATE_PICKER_END: 'End',
	HISTORY: 'History',
	HISTORY_OF: 'History of',
	HISTORY_MOVE: 'Passed through the',
	HISTORY_NONE: 'No History...',
	ENTER: 'In',
	LEAVE: 'Out',
	GEOFENCING: 'Geofencing',
	ETA: 'Estimated Time',
	CUSTOM_EVENT: 'Informations',
	ALERT: 'Alert',
	MODIFICATION: 'Modification',
	IN_CHARGE: 'In charge',
	TYPE_EVENT: 'Category of event'
};

export default locale;
