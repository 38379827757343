const locale = {
	TITLE: 'Example Page',
	NAME: 'Name',
	FIRSTNAME: 'Firstname',
	CONNEXION: 'Login',
	REMEMBER: 'Remember Me',
	FORGOT: 'Forgot Password',
	PHONE: 'Phone',
	EMAIL: 'Email',
	PASSWORD: 'Password',
	PWDCONFIRM: 'Password (Confirm)',
	CREATE: 'Create an account',
	TERMSCOND: 'I read and accept terms and conditions.',
	NEWSLETTER:
		'I agree that stracker360.fr sends me information about promotions or services provided by Legalstart.fr',
	NBCARACTERES: 'Your password must contain at least 9 characters',
	RESETPASS: 'Reset your password',
	VALIDER: 'Reset password',
	CONFIRMEMAIL: 'Confirm your email address!',
	CONFIRMEMAILTXT: 'A confirmation e-mail has been sent to example@mymail.com.',
	CHECKEMAIL: 'Check your inbox and click on the "Confirm my email" link to confirm your email address.',
	BACKTOLOGIN: 'Go back to login',
	RECOVERTITLE: 'Forgot your password',
	RECOVER: 'Send reset link',
	MSGPWDFAIBLE: 'The number of characters is insufficient',
	MSGPWDMOYENNE: 'The security of your password is medium',
	MSGPWDFORTE: 'The security of your password is strong',
	PWDCREATED: 'Password created',
	COMPTECREATED: 'Your account is complete and created. You will be redirected to the login page.',
	TXTFORGOTEMAIL:
		'Enter the email address associated with your account. We will email you a link to reset your password.Your account is complete and created.',/*You will be redirected to the login page.*/
	EMAILSENT: 'Email sent',
	EMAILLINK: 'A link to reset your password has been sent to you.',
	NOCOMPTE: 'This email is not associated with any account',
	PWDMODIFIED: 'Password changed',
	TXTMODIFIED: 'Your password has been changed successfully. You will be redirected to the login page.',
	EMAILNOVALID: 'This not a valid email address',
	IDENTIFIANTSNOVALID: 'Your email or password does not match. Try again.',
	EMAILNOEXIST: 'This email address does not exist',
	REQCLPWD: 'The Password must contains at least 1 Capital letter',
	NOFORGOTPWD: 'Password not declared forgot',
	NOVALIDACTION: 'Action unknown',
	UNDIFACTION: 'Action undefined',
	PWDNOMATCH: 'Password does not match',
	ISREGISTERED: 'This user is already registered',
	NOAUTORIZED: 'You are not authorized to connect',
	GETQUOTE: 'Get a quote',
	LANGUAGE: 'Language',
	US: 'English',
	FR: 'French'
};

export default locale;
