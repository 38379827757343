import * as React from 'react';

function UpdateIconProfile(props) {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
				fill="none"
				stroke={props.boderColorM}//"#A8C1F7"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.3333 7.375L16.625 9.66667L10.6667 15.625H8.375V13.3333L14.3333 7.375Z"
				stroke={props.ColorInM}//"#023E65"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default UpdateIconProfile;
