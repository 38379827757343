export const OPEN_SC_DIALOG = '[SUPPLY CHAIN] OPEN SUPPLY CHAIN  DIALOG';
export const CLOSE_SC_DIALOG = '[SUPPLY CHAIN] CLOSE SUPPLY CHAIN  DIALOG';
export const OPEN_PROFILE_SC_DIALOG = '[SUPPLY CHAIN  PROFILE] OPEN PROFILE SUPPLY CHAIN  DIALOG';
export const CLOSE_PROFILE_SC_DIALOG = '[SUPPLY CHAIN  PROFILE] CLOSE PROFILE SUPPLY CHAIN  DIALOG';
export const OPEN_MEMBERS_SC_DIALOG = '[SUPPLY CHAIN  MEMBERS] OPEN MEMBERS SUPPLY CHAIN  DIALOG';
export const CLOSE_MEMBERS_SC_DIALOG = '[SUPPLY CHAIN  MEMBERS] CLOSE MEMBERS SUPPLY CHAIN  DIALOG';
export const OPEN_ALERTS_SC_DIALOG = '[SUPPLY CHAIN ALERTS] OPEN ALERTS SUPPLY CHAIN  DIALOG';
export const CLOSE_ALERTS_SC_DIALOG = '[SUPPLY CHAIN ALERTS] CLOSE ALERTS SUPPLY CHAIN  DIALOG';
export const OPEN_LANGUES_SC_DIALOG = '[SUPPLY CHAIN  LANGUES] OPEN LANGUES SUPPLY CHAIN  DIALOG';
export const CLOSE_LANGUES_SC_DIALOG = '[SUPPLY CHAIN  LANGUES] CLOSE LANGUES SUPPLY CHAIN  DIALOG';
export const OPEN_SEARCH_SC_DIALOG = '[SUPPLY CHAIN  SEARCH] OPEN SEARCH SUPPLY CHAIN  DIALOG';
export const CLOSE_SEARCH_SC_DIALOG = '[SUPPLY CHAIN  SEARCH] CLOSE SEARCH SUPPLY CHAIN  DIALOG';
export const OPEN_NOTIFICATIONS_SC_DIALOG = '[SUPPLY CHAIN  NOTIFICATIONS] OPEN NOTIFICATIONS SUPPLY CHAIN  DIALOG';
export const CLOSE_NOTIFICATIONS_SC_DIALOG = '[SUPPLY CHAIN  NOTIFICATIONS] CLOSE NOTIFICATIONS SUPPLY CHAIN  DIALOG';

export function openScDialog(data) {
	return {
		type: OPEN_SC_DIALOG,
		options: data
	};
}

export function closeScDialog() {
	return {
		type: CLOSE_SC_DIALOG
	};
}

export function openProfileScDialog(data) {
	return {
		type: OPEN_PROFILE_SC_DIALOG,
		options: data
	};
}

export function closeProfileScDialog() {
	return {
		type: CLOSE_PROFILE_SC_DIALOG
	};
}

export function openAlertScDialog(data) {
	return {
		type: OPEN_ALERTS_SC_DIALOG,
		options: data
	};
}

export function closeAlertScDialog() {
	return {
		type: CLOSE_ALERTS_SC_DIALOG
	};
}

export function openMemberScDialog(data) {
	return {
		type: OPEN_MEMBERS_SC_DIALOG,
		options: data
	};
}

export function closeMemberScDialog() {
	return {
		type: CLOSE_MEMBERS_SC_DIALOG
	};
}

export function openLangueScDialog(data) {
	return {
		type: OPEN_LANGUES_SC_DIALOG,
		options: data
	};
}

export function closeLangueScDialog() {
	return {
		type: CLOSE_LANGUES_SC_DIALOG
	};
}

export function openSearchScDialog(data) {
	return {
		type: OPEN_SEARCH_SC_DIALOG,
		options: data
	};
}

export function closeSearchScDialog() {
	return {
		type: CLOSE_SEARCH_SC_DIALOG
	};
}

export function openNotificationsScDialog(data) {
	return {
		type: OPEN_NOTIFICATIONS_SC_DIALOG,
		options: data
	};
}

export function closeNotificationsScDialog() {
	return {
		type: CLOSE_NOTIFICATIONS_SC_DIALOG
	};
}
