const locale = {
	DATE_PICKER_BEGIN: 'Début',
	DATE_PICKER_END: 'Fin',
	HUMIDITY_HISTORY: "Historique d'humidité",
	TEMPERATURE_HISTORY: 'Historique des températures',
	TITLE: 'Share',
	UP_TO_DATE: 'Informations à jour',
	LOGIN: 'Se connecter',
	REFRESH: 'Rafraichir',
	DOWNLOAD_CSV: 'Download to CSV',
	CONTENU: 'Contenu',
	POSITION: 'Position',
	CAPTEUR: 'Capteurs',
	ASSOCIATED_TRANSPORT: 'Transport Associé',
	DESTINATION: 'Destination',
	ROUTE: 'Parcours',
	TEXT_UPLOAD_FILE: 'Ce fichier a été envoyé :',
	ETA_DATE_PICKER_BEGIN: 'Départ',
	ETA_DATE_PICKER_END: 'Arrivée',
	EVENT: 'Evènements',
	INFORMATION: 'Informations libres',
	ETA: 'Dates estimées',
	CUSTOM_EVENT: 'Informations',
	ALERT: 'Alerte',
	MODIFICATION: 'Modification',
	IN_CHARGE: 'Prise en charge',
	HISTORY_OF: 'Historique de',
	HISTORY_NONE: 'Aucun historique',
	RANGE_BY: 'Ranger par',
	RANGE_BY_ASC: 'Du plus récent au moins récent',
	RANGE_BY_DSC: 'Du moins récent au plus récent',
	CONTAINER_INFO: 'Informations sur le colis',
	SEARCH: 'Rechercher',
	COTATION: 'Cotation',
	BUDGET: 'Budget',
	SHIPMENT_DETAILS: 'Détails du transports',
	CARBON_FOOTPRINT: 'Empreinte carbone',
	FLIGHT: 'Vol',
	AWB: 'LTA',
	UNITS: 'Unités',
	UNIT: 'Unité',
	DRIVER: 'Chauffeur',
	PHONE_ABV: 'Tel.',
	PLATE: 'Plaque'
};

export default locale;
