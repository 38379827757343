import TrackSplashScreen from '@stracker/core/TrackSplashScreen';
import auth0Service from 'app/services/auth0Service';
import firebaseService from 'app/services/firebaseService';
import apiJwtService from 'app/services/apiJwtService';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from '@reduxjs/toolkit';
import { hideMessage, showMessage } from 'app/store/fuse/messageSlice';
import { setUserDataFirebase, setUserDataAuth0, setUserData, logoutUser } from './store/userSlice';
// import _ from '@lodash';
// import history from '@history';
class Auth extends Component {
	state = {
		waitAuthCheck: true
	};

	componentDidMount() {
		return Promise.all([
			// Comment the lines which you do not use
			// this.firebaseCheck(),
			// this.auth0Check(),
			this.apiJwtCheck()
		]).then(() => {
			this.setState({ waitAuthCheck: false });
		});
	}

	apiJwtCheck = () =>
		new Promise(resolve => {
			apiJwtService.on('onAutoLogin', () => {
				// this.props.showMessage({ message: 'onAutoLogin Logging in with JWT' });

				/** Sign in and retrieve user data from Api * */
				apiJwtService
					.signInWithToken()
					.then(user => {
						this.props.setUserData(user);
						// this.props.showMessage({ message: 'signInWithToken Logged in with JWT' });
						resolve();
					})
					.catch(error => {
						error = error.code === 401 ? error.code : 401;
						// error = error === 401 ? error : 401;
						if (error === 401) {
							this.props.showMessage({
								message: 'access token expired',
								autoHideDuration: 2000,
								variant: 'error',
								anchorOrigin: {
									vertical: 'top',
									horizontal: 'right'
								}
							});
							// this.props.showMessage({ message: error.message });
						}
						//! info: warning Login error null
						this.props.logout(); 
						resolve();
					});
			});

			apiJwtService.on('onAutoLogout', message => {
				// this.props.showMessage({ message: 'onAutoLogin Logging in with JWT' });

				if (message) {
					this.props.showMessage({ message });
				}

				this.props.logout();

				resolve();
			});

			apiJwtService.on('onNoAccessToken', () => {
				resolve();
			});

			apiJwtService.init();

			return Promise.resolve();
		});

	auth0Check = () =>
		new Promise(resolve => {
			auth0Service.init(success => {
				if (!success) {
					resolve();
				}
			});

			if (auth0Service.isAuthenticated()) {
				this.props.showMessage({ message: 'Logging in with Auth0' });

				/* * Retrieve user data from Auth0 * */
				auth0Service.getUserData().then(tokenData => {
					this.props.setUserDataAuth0(tokenData);

					resolve();
					this.props.showMessage({ message: 'Logged in with Auth0' });
				});
			} else {
				resolve();
			}

			return Promise.resolve();
		});

	firebaseCheck = () =>
		new Promise(resolve => {
			firebaseService.init(success => {
				if (!success) {
					resolve();
				}
			});

			firebaseService.onAuthStateChanged(authUser => {
				if (authUser) {
					this.props.showMessage({ message: 'Logging in with Firebase' });

					/* * Retrieve user data from Firebase * */
					firebaseService.getUserData(authUser.uid).then(
						user => {
							this.props.setUserDataFirebase(user, authUser);

							resolve();
							this.props.showMessage({ message: 'Logged in with Firebase' });
						},
						error => {
							resolve();
						}
					);
				} else {
					resolve();
				}
			});

			return Promise.resolve();
		});

	render() {
		// history.push('/usersuccessful');
		return this.state.waitAuthCheck ? <TrackSplashScreen /> : <>{this.props.children}</>;
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators(
		{
			logout: logoutUser,
			setUserData,
			setUserDataAuth0,
			setUserDataFirebase,
			showMessage,
			hideMessage
		},
		dispatch
	);
}

export default connect(null, mapDispatchToProps)(Auth);
