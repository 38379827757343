const settingsConfig = {
	layout: {
		style: 'layout4', // layout-1 layout-2 layout-3
		config: {} // checkout default layout configs at app/fuse-layouts for example  app/fuse-layouts/layout1/Layout1Config.js
	},
	customScrollbars: false,
	animations: true,
	direction: 'ltr', // rtl, ltr
	theme: {
		main: 'charteGraph',
		navbar: 'charteGraph', 
		sidepanel: 'default',
		toolbar: 'mainThemeLight',
		footer: 'mainThemeDark'
	}
};

export default settingsConfig;
