const locale = {
	TITLE: 'Search',
	PLACEHOLDER_SEARCH: 'Type the reference of a transport or a tag',
	SEARCH: 'Search',
	CLOSING: 'Close',
	RANGE_BY: 'Ranger by',
	RANGE_BY_ASC: 'From latest to oldest',
	RANGE_BY_DSC: 'From oldest to latest',
	SHOW_FILTERS: 'Show filters',
	CONTENU: 'Content(s)',
	CREATEAT: 'Created',
	TITLE_INFO: 'Notes',
	CONNEXION: 'Connectivity',
	HUMIDITY: 'Humidity',
	POSITION: 'Position',
	TEMPERATURE: 'Temperature',
	FENCING: 'Close',
	HIDE_FILTERS: 'Hide Filters',
	RESULT: 'Result',
	RESULT_TEXT: 'Result of the research'
};

export default locale;
