import React from 'react';
import { useTheme } from '@material-ui/core/styles';

function CircleAddIcon(props) {
	const theme = useTheme();
	return (
		<svg
			width={props.width || '18'}
			height={props.height || '18'}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				cursor: props.cursor,
				marginLeft: props.marginLeft,
				marginRight: props.marginRight,
				display: props.display
			}}
			onClick={props.onClick}
		>
			<path
				d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
				fill={props.noFill ? 'transparent' : 'transparent'}
				stroke={props.colorPrimary || '#A8C1F7'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M16.0002 12L8.00025 12"
				stroke={props.colorPrimary || theme.palette.stracker.contrastTextDark}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.0002 8L12.0002 16"
				stroke={props.colorPrimary || theme.palette.stracker.contrastTextDark}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default CircleAddIcon;
