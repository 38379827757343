import * as Actions from '../actions';

const initialState = {
	status: 'INIT',
	data: [],
	totalUnread: 0,
	totalNotification: 0,
	dialogIsOpen: false
};

const notificationsReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_NOTIFICATIONS:
			return {
				...state,
				...action.payload,
				data: [...(action.payload.data.length > 0 ? action.payload.data : state.data)],
				totalUnread: action.payload.totalUnread,
				totalNotification: action.payload.totalNotification,
				status: action.payload.status
			};
		case Actions.GET_NOTIFICATIONS_TRANSPORT_COTATION:
			return {
				...state,
				...action.payload,
				counterCotation: action.payload.counterCotation,
				counterTransport: action.payload.counterTransport
			};
		case Actions.UPDATE_NOTIFICATION_TO_READ: {
			let updateNotification = state.data;
			updateNotification = updateNotification.map(notification => {
				if (notification.idNotification === action.idNotification) {
					return {
						...notification,
						isRead: 1
					};
				}
				return notification;
			});
			return { ...state, data: updateNotification, totalUnread: state.totalUnread - 1 };
		}
		case Actions.UPDATE_ALL_NOTIFICATIONS_TO_READ: {
			let updateNotifications = state.data;
			updateNotifications = updateNotifications.map(notification => {
				if (notification.isRead === 0) {
					return {
						...notification,
						isRead: 1
					};
				}
				return notification;
			});
			return { ...state, data: updateNotifications, totalUnread: 0 };
		}
		case Actions.STATUS_NOTIFICATIONS_DIALOG: {
			return { ...state, dialogIsOpen: action.payload };
		}

		default:
			return state;
	}
};
export default notificationsReducer;
