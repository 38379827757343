const locale = {
	TITLE: 'Titulo',
	TRANSPORT: 'Transporte',
	CREATE_TRANSPORT: 'Creación de nuevos transportes',
	CREATE_TRANSPORT_SUBTITLE: 'Asocie su transporte con uno o más rastreadores',
	CREATE_TRANSPORT_SUBTITLE1: 'Referencia de transporte',
	CREATE_TRANSPORT_SUBTITLE2: 'Asocie su transporte con uno o más rastreadores',
	CREATE_TRANSPORT_SUBTITLE3: 'Rastreador(s) asociado(s) y activo(s)',
	CREATE_TRANSPORT_SUBTITLE4: 'Agregar una ruta',
	CREATE_TRANSPORT_SUBTITLE5: 'Agrega los datos que quieres ver',
	DIALOG_NEXT: 'Siguiente',
	TO_COMPLETE: 'Para completar',
	READY_TO_GO: 'Listo para salir',
	TRANSIT: 'En tránsito',
	DESTINATION_END: 'Llegada',
	ADD_TRANSPORT: 'Agregar un transporte',
	ADD: 'Agregar',
	TRACKER_LIST: 'Lista de rastreadores',
	BOUTON_CANCEL: 'anular',
	VALIDATE: 'validar',
	BOUTON_FINISHED: 'Terminado',
	TRANSPORT_TYPE: 'Tipo de transporte',
	DEPARTURE: 'salida',
	ETAPES: 'Pasos',
	ETAPE: 'Paso',
	ARRIVAL: 'Llegada',
	AIRCRAFT: 'Plano',
	BATEAU: 'Bote',
	TRUCK: 'Camión',
	MINI_CARACTERE: 'Mínimo 3 caracteres',
	ADD_A_STEP: 'Agregar un paso',
	DELETE_A_STEP: 'Eliminar este paso',
	ADDRESS: 'Dirección',
	ERROR_SELECT_TRACKER: 'Seleccione al menos un rastreador',
	ERROR_ADD_TRANSPORT: 'Ingrese un nombre de transporte',
	SEARCH_FOR_TRACKER: 'Encontrar un rastreador',
	SEARCH_FOR_TRANSPORT: 'Buscar un transporte',
	SELECT_AN_ADDRESS: 'Seleccione una direccion',
	ERROR_TRANSPORT_NAME: 'Este nombre ya se usa para otro transporte',
	ADD_ASSET_REFERENCE: 'Agregar una referencia de activo',
	ERROR_MESSAGE_DIALOG3: 'ya se utiliza como nombre de activo',
	CREATED: 'Creado',
	NO_TRACKER_AVAILABLE: 'No hay rastreador disponible',
	INCORRECT_ADDRESS: 'Direccion incorrecta',
	CLOSE_TRANSPORT: 'Transporte cercano',
	DELETE_ASSET_TRANSPORT: '¿Quieres eliminar este activo?',
	UPDATE_ON: 'Actualización sobre ',
	CURRENT_ROUTE: 'Ruta actual',
	LOADING_AVAILABLE_TRACKERS: 'Cargando rastreadores disponibles'
};

export default locale;
