import React from 'react';

function AssetIcon(props) {
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_14444_9190)">
				<path
					d="M9.66754 1.08834L15.6675 4.08834C15.9174 4.21253 16.1278 4.40396 16.2748 4.64113C16.4219 4.87829 16.4999 5.15178 16.5 5.43084V12.5783C16.4999 12.8574 16.4219 13.1309 16.2748 13.3681C16.1278 13.6052 15.9174 13.7967 15.6675 13.9208L9.66754 16.9208C9.45914 17.0251 9.22932 17.0794 8.99629 17.0794C8.76326 17.0794 8.53343 17.0251 8.32504 16.9208L2.32504 13.9208C2.07538 13.7951 1.86585 13.602 1.72008 13.3635C1.5743 13.1249 1.49809 12.8504 1.50004 12.5708V5.43084C1.50018 5.15178 1.57818 4.87829 1.72525 4.64113C1.87231 4.40396 2.08263 4.21253 2.33254 4.08834L8.33254 1.08834C8.53995 0.985276 8.76842 0.931641 9.00004 0.931641C9.23165 0.931641 9.46012 0.985276 9.66754 1.08834V1.08834Z"
					stroke="#023E65"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M1.73828 4.61914L8.99828 8.24914L16.2583 4.61914"
					stroke="#023E65"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path d="M9 17.07V8.25" stroke="#023E65" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
				<path
					d="M5.25 2.625L12.75 6.375"
					stroke="#023E65"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
			<defs>
				<clipPath id="clip0_14444_9190">
					<rect width="18" height="18" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default AssetIcon;
