import axios from 'axios';
// let server_hostname = window.location.hostname;
const API_END_POINT = `${process.env.REACT_APP_API_BASE_URL}/index.php`;

export default axios.create({
	baseURL: API_END_POINT,
	headers: {
		Accept: 'application/json, text/plain, */*',
		'content-type': 'application/json'
	},
	responseType: 'json'
	// timeout: 25000
});
