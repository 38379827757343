import mock from '../mock';

const container = [
	{
		idContainer: 330,
		containerReference: 'RGS76FEJIK',
		transport: {
			idTransport: 159,
			transportName: 'Zhang Gatta Pte Limited',
			destination: 'Rue du Port, Montréal, QC H2Y, Canada',
			status: 'readytoship'
		},
		trip: {
			addressStart: {
				latitude: 49.41775,
				longitude: 2.82587,
				fullAddressStart: "Place de l'Hôtel de Ville, 60200 Compiègne, France"
			},
			addressDestination: {
				latitude: 45.50179,
				longitude: -73.55445,
				fullAddressEnd: 'Rue du Port, Montréal, QC H2Y, Canada',
				address: {
					street: null,
					postCode: null,
					city: null,
					country: null
				}
			},
			transit: [
				{
					latitude: 48.42343,
					longitude: 7.66294,
					tripNumber: 2,
					address: {
						street: '',
						postCode: '77500',
						city: '',
						country: ''
					}
				}
			]
		},
		comment: null,
		alerts: [
			{
				id: 213,
				ruleName: 'Temperature Willem',
				description: null,
				ruleKey: 'Temperature',
				ruleValue: '',
				deleted: 'N',
				creationBy: 71,
				modificationDate: null,
				modificationBy: null,
				idCustomer: 40,
				ruleValueMin: 5,
				ruleValueMax: 30,
				direction: '',
				address: '',
				gpsLocation: '',
				creationDate: '2021-03-22T21:28:16+00:00',
				alertStatus: 'R',
				idDevice: null
			},
			{
				id: 661,
				ruleName: '_temperature_',
				description: null,
				ruleKey: '_temperature_',
				ruleValue: '_temperature_',
				deleted: 'N',
				creationBy: 71,
				modificationDate: null,
				modificationBy: null,
				idCustomer: null,
				ruleValueMin: 0,
				ruleValueMax: 20,
				direction: '',
				address: '',
				gpsLocation: '',
				creationDate: '2021-05-05T13:10:51+00:00',
				alertStatus: 'R',
				idDevice: 512
			},
			{
				id: 662,
				ruleName: '_humidity_',
				description: null,
				ruleKey: '_humidity_',
				ruleValue: '_humidity_',
				deleted: 'N',
				creationBy: 71,
				modificationDate: null,
				modificationBy: null,
				idCustomer: null,
				ruleValueMin: 0,
				ruleValueMax: 20,
				direction: '',
				address: '',
				gpsLocation: '',
				creationDate: '2021-05-05T13:10:51+00:00',
				alertStatus: 'R',
				idDevice: 512
			},
			{
				id: 663,
				ruleName: '_geofencing_',
				description: null,
				ruleKey: '_geofencing_',
				ruleValue: '49.41775 2.82587',
				deleted: 'N',
				creationBy: 71,
				modificationDate: null,
				modificationBy: null,
				idCustomer: null,
				ruleValueMin: 10,
				ruleValueMax: 0,
				direction: 'OUT',
				address: "Place de l'Hôtel de Ville, 60200 Compiègne, France",
				gpsLocation: '49.41775 2.82587',
				creationDate: '2021-05-05T13:10:51+00:00',
				alertStatus: 'R',
				idDevice: 512
			},
			{
				id: 664,
				ruleName: '_geofencing_',
				description: null,
				ruleKey: '_geofencing_',
				ruleValue: '48.42343 7.66294',
				deleted: 'N',
				creationBy: 71,
				modificationDate: null,
				modificationBy: null,
				idCustomer: null,
				ruleValueMin: 10,
				ruleValueMax: 0,
				direction: 'INOUT',
				address: "Place de l'Hôtel de Ville, 67150 Erstein, France",
				gpsLocation: '48.42343 7.66294',
				creationDate: '2021-05-05T13:10:51+00:00',
				alertStatus: 'R',
				idDevice: 512
			},
			{
				id: 665,
				ruleName: '_geofencing_',
				description: null,
				ruleKey: '_geofencing_',
				ruleValue: '45.50179 -73.55445',
				deleted: 'N',
				creationBy: 71,
				modificationDate: null,
				modificationBy: null,
				idCustomer: null,
				ruleValueMin: 10,
				ruleValueMax: 0,
				direction: 'IN',
				address: 'Rue du Port, Montréal, QC H2Y, Canada',
				gpsLocation: '45.50179 -73.55445',
				creationDate: '2021-05-05T13:10:52+00:00',
				alertStatus: 'R',
				idDevice: 512
			}
		],
		temperature: {
			temperatureMin: [],
			temperatureMax: [],
			historyDeviceDateTemperature: []
		},
		humidity: {
			humidityMin: [],
			humidityMax: [],
			historyDeviceDateHumidity: []
		},
		historyPosition: [],
		device: {
			battery: null,
			model: 'HC01',
			status: 'S',
			imei: '77773233344778',
			idDevice: 495,
			temperature: null,
			humidity: null,
			address: null,
			position: {
				latitude: null,
				longitude: null
			},
			lastUpdate: '2021-05-12T18:24:55+00:00'
		}
	},
	{
		idContainer: 383,
		containerReference: 'test3',
		transport: {
			idTransport: 159,
			transportName: 'Zhang Gatta Pte Limited',
			destination: 'Rue du Port, Montréal, QC H2Y, Canada',
			status: 'readytoship'
		},
		trip: {
			addressStart: {
				latitude: 49.41775,
				longitude: 2.82587,
				fullAddressStart: "Place de l'Hôtel de Ville, 60200 Compiègne, France"
			},
			addressDestination: {
				latitude: 45.50179,
				longitude: -73.55445,
				fullAddressEnd: 'Rue du Port, Montréal, QC H2Y, Canada',
				address: {
					street: null,
					postCode: null,
					city: null,
					country: null
				}
			},
			transit: [
				{
					latitude: 48.42343,
					longitude: 7.66294,
					tripNumber: 2,
					address: {
						street: '',
						postCode: '77500',
						city: '',
						country: ''
					}
				}
			]
		},
		comment: null,
		alerts: [],
		temperature: {
			temperatureMin: [],
			temperatureMax: [],
			historyDeviceDateTemperature: []
		},
		humidity: {
			humidityMin: [],
			humidityMax: [],
			historyDeviceDateHumidity: []
		},
		historyPosition: [
			{
				latitude: 49.41775,
				longitude: 2.82587,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 49.133333,
				longitude: 6.1666673,
				deviceDate: '2021-05-14T21:30:00+00:00'
			},
			{
				latitude: 48.584614,
				longitude: 7.7507127,
				deviceDate: '2021-05-14T23:57:00+00:00'
			}
		],
		device: {
			battery: null,
			model: 'HC01',
			status: 'S',
			imei: '77773233344778',
			idDevice: 495,
			temperature: null,
			humidity: null,
			address: null,
			position: {
				latitude: null,
				longitude: null
			},
			lastUpdate: '2021-05-12T18:24:55+00:00'
		}
	},
	{
		idContainer: 393,
		containerReference: 'Tracker 1',
		transport: {
			idTransport: 170,
			transportName: 'Transport Guillaume',
			destination: null,
			status: 'readytoship'
		},
		trip: {
			addressStart: {
				latitude: null,
				longitude: null,
				fullAddressStart: null
			},
			addressDestination: {
				latitude: null,
				longitude: null,
				fullAddressEnd: null,
				address: {
					street: null,
					postCode: null,
					city: null,
					country: null
				}
			},
			transit: []
		},
		comment: null,
		alerts: [
			{
				id: 940,
				ruleName: '_temperature_',
				description: null,
				ruleKey: '_temperature_',
				ruleValue: '_temperature_',
				deleted: 'N',
				creationBy: 71,
				modificationDate: null,
				modificationBy: null,
				idCustomer: null,
				ruleValueMin: 5,
				ruleValueMax: 25,
				direction: '',
				address: '',
				gpsLocation: '',
				creationDate: '2021-05-12T22:30:15+00:00',
				alertStatus: 'R',
				idDevice: 546
			},
			{
				id: 941,
				ruleName: '_humidity_',
				description: null,
				ruleKey: '_humidity_',
				ruleValue: '_humidity_',
				deleted: 'N',
				creationBy: 71,
				modificationDate: null,
				modificationBy: null,
				idCustomer: null,
				ruleValueMin: 5,
				ruleValueMax: 25,
				direction: '',
				address: '',
				gpsLocation: '',
				creationDate: '2021-05-12T22:30:15+00:00',
				alertStatus: 'R',
				idDevice: 546
			}
		],
		temperature: {
			temperatureMin: [],
			temperatureMax: [],
			historyDeviceDateTemperature: []
		},
		humidity: {
			humidityMin: [],
			humidityMax: [],
			historyDeviceDateHumidity: []
		},
		historyPosition: [],
		device: {
			battery: 100,
			model: 'PT35',
			status: 'A',
			imei: '860689030765555',
			idDevice: 546,
			temperature: 0,
			humidity: 0,
			address: '2 Rue des Voyelles, 93290 Tremblay-en-France, France',
			position: {
				latitude: 48.996945,
				longitude: 2.53066
			},
			lastUpdate: '2021-05-12T22:30:14+00:00'
		}
	},
	{
		idContainer: 395,
		containerReference: 'Tracker 3',
		transport: {
			idTransport: 170,
			transportName: 'Transport Guillaume',
			destination: null,
			status: 'readytoship'
		},
		trip: {
			addressStart: {
				latitude: null,
				longitude: null,
				fullAddressStart: null
			},
			addressDestination: {
				latitude: null,
				longitude: null,
				fullAddressEnd: null,
				address: {
					street: null,
					postCode: null,
					city: null,
					country: null
				}
			},
			transit: []
		},
		comment: 'Ceci est un mock (395)',
		alerts: [
			{
				id: 944,
				ruleName: '_temperature_',
				description: null,
				ruleKey: '_temperature_',
				ruleValue: '_temperature_',
				deleted: 'N',
				creationBy: 71,
				modificationDate: null,
				modificationBy: null,
				idCustomer: null,
				ruleValueMin: 5,
				ruleValueMax: 25,
				direction: '',
				address: '',
				gpsLocation: '',
				creationDate: '2021-05-12T22:30:15+00:00',
				alertStatus: 'U',
				idDevice: 548
			},
			{
				id: 945,
				ruleName: '_humidity_',
				description: null,
				ruleKey: '_humidity_',
				ruleValue: '_humidity_',
				deleted: 'N',
				creationBy: 71,
				modificationDate: null,
				modificationBy: null,
				idCustomer: null,
				ruleValueMin: 5,
				ruleValueMax: 25,
				direction: '',
				address: '',
				gpsLocation: '',
				creationDate: '2021-05-12T22:30:15+00:00',
				alertStatus: 'U',
				idDevice: 548
			}
		],
		temperature: {
			temperatureMin: [],
			temperatureMax: [],
			historyDeviceDateTemperature: []
		},
		humidity: {
			humidityMin: [],
			humidityMax: [],
			historyDeviceDateHumidity: []
		},
		historyPosition: [],
		device: {
			battery: 100,
			model: 'PT35',
			status: 'A',
			imei: '860689030765555',
			idDevice: 546,
			temperature: 0,
			humidity: 0,
			address: '2 Rue des Voyelles, 93290 Tremblay-en-France, France',
			position: {
				latitude: 48.996945,
				longitude: 2.53066
			},
			lastUpdate: '2021-05-12T22:30:14+00:00'
		}
	},
	{
		idContainer: 396,
		containerReference: 'Tracker 4',
		transport: {
			idTransport: 170,
			transportName: 'Transport Guillaume',
			destination: null,
			status: 'readytoship'
		},
		trip: {
			addressStart: {
				latitude: null,
				longitude: null,
				fullAddressStart: null
			},
			addressDestination: {
				latitude: null,
				longitude: null,
				fullAddressEnd: null,
				address: {
					street: null,
					postCode: null,
					city: null,
					country: null
				}
			},
			transit: []
		},
		comment: 'Ceci est un mock',
		alerts: [
			{
				id: 946,
				ruleName: '_temperature_',
				description: null,
				ruleKey: '_temperature_',
				ruleValue: '_temperature_',
				deleted: 'N',
				creationBy: 71,
				modificationDate: null,
				modificationBy: null,
				idCustomer: null,
				ruleValueMin: 5,
				ruleValueMax: 25,
				direction: '',
				address: '',
				gpsLocation: '',
				creationDate: '2021-05-12T22:30:15+00:00',
				alertStatus: 'U',
				idDevice: 558
			},
			{
				id: 947,
				ruleName: '_humidity_',
				description: null,
				ruleKey: '_humidity_',
				ruleValue: '_humidity_',
				deleted: 'N',
				creationBy: 71,
				modificationDate: null,
				modificationBy: null,
				idCustomer: null,
				ruleValueMin: 5,
				ruleValueMax: 25,
				direction: '',
				address: '',
				gpsLocation: '',
				creationDate: '2021-05-12T22:30:15+00:00',
				alertStatus: 'U',
				idDevice: 558
			}
		],
		temperature: {
			temperatureMin: [],
			temperatureMax: [],
			historyDeviceDateTemperature: []
		},
		humidity: {
			humidityMin: [],
			humidityMax: [],
			historyDeviceDateHumidity: []
		},
		historyPosition: [],
		device: {
			battery: 100,
			model: 'PT35',
			status: 'A',
			imei: '860689030765555',
			idDevice: 546,
			temperature: 0,
			humidity: 0,
			address: '2 Rue des Voyelles, 93290 Tremblay-en-France, France',
			position: {
				latitude: 48.996945,
				longitude: 2.53066
			},
			lastUpdate: '2021-05-12T22:30:14+00:00'
		}
	},
	{
		idContainer: 398,
		containerReference: '4Gflight',
		transport: {
			idTransport: 171,
			transportName: 'Test Mode Avion',
			destination: 'Rugby Ct, Ridgewood, NJ 07450, EE. UU.',
			status: 'readytoship'
		},
		trip: {
			addressStart: {
				latitude: 48.8675885,
				longitude: 2.2251694,
				fullAddressStart: '4 Rue Pierre Dupont, 92150 Suresnes, Francia'
			},
			addressDestination: {
				latitude: 40.992919,
				longitude: -74.1024039,
				fullAddressEnd: 'Rugby Ct, Ridgewood, NJ 07450, EE. UU.',
				address: {
					street: null,
					postCode: null,
					city: null,
					country: null
				}
			},
			transit: []
		},
		comment: null,
		alerts: [
			{
				id: 956,
				ruleName: '_geofencing_',
				description: null,
				ruleKey: '_geofencing_',
				ruleValue: '48.8675885 2.2251694',
				deleted: 'N',
				creationBy: 700,
				modificationDate: null,
				modificationBy: null,
				idCustomer: null,
				ruleValueMin: 5,
				ruleValueMax: 0,
				direction: 'OUT',
				address: null,
				gpsLocation: '48.8675885 2.2251694',
				creationDate: '2021-05-14T07:51:08+00:00',
				alertStatus: 'R',
				idDevice: 560
			},
			{
				id: 957,
				ruleName: '_geofencing_',
				description: null,
				ruleKey: '_geofencing_',
				ruleValue: '49.0096739 2.547966',
				deleted: 'N',
				creationBy: 700,
				modificationDate: null,
				modificationBy: null,
				idCustomer: null,
				ruleValueMin: 5,
				ruleValueMax: 0,
				direction: 'INOUT',
				address: 'Aeropuerto de París-Charles de Gaulle (CDG), 95700 Roissy-en-France, Francia',
				gpsLocation: '49.0096739 2.547966',
				creationDate: '2021-05-14T07:51:08+00:00',
				alertStatus: 'R',
				idDevice: 560
			},
			{
				id: 958,
				ruleName: '_geofencing_',
				description: null,
				ruleKey: '_geofencing_',
				ruleValue: '40.992919 -74.1024039',
				deleted: 'N',
				creationBy: 700,
				modificationDate: null,
				modificationBy: null,
				idCustomer: null,
				ruleValueMin: 5,
				ruleValueMax: 0,
				direction: 'IN',
				address: null,
				gpsLocation: '40.992919 -74.1024039',
				creationDate: '2021-05-14T07:51:08+00:00',
				alertStatus: 'R',
				idDevice: 560
			}
		],
		temperature: {
			temperatureMin: [],
			temperatureMax: [],
			historyDeviceDateTemperature: [
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00'
			]
		},
		humidity: {
			humidityMin: [],
			humidityMax: [],
			historyDeviceDateHumidity: [
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00'
			]
		},
		historyPosition: [
			{
				latitude: 48.8706587,
				idPosition: 10922,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10923,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10924,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10925,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10949,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10950,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10951,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10953,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			}
		],
		device: {
			battery: 100,
			model: 'PT35',
			status: 'A',
			imei: '860689030764574',
			idDevice: 547,
			temperature: 0,
			humidity: 0,
			address: 'Mairie de Suresnes, 92150 Suresnes, France',
			position: {
				latitude: 48.8706587,
				longitude: 2.2242373
			},
			lastUpdate: '2021-05-14T08:01:31+00:00'
		}
	},
	{
		idContainer: 399,
		containerReference: '2G',
		transport: {
			idTransport: 171,
			transportName: 'Test Mode Avion',
			destination: 'Rugby Ct, Ridgewood, NJ 07450, EE. UU.',
			status: 'readytoship'
		},
		trip: {
			addressStart: {
				latitude: 48.8675885,
				longitude: 2.2251694,
				fullAddressStart: '4 Rue Pierre Dupont, 92150 Suresnes, Francia'
			},
			addressDestination: {
				latitude: 40.992919,
				longitude: -74.1024039,
				fullAddressEnd: 'Rugby Ct, Ridgewood, NJ 07450, EE. UU.',
				address: {
					street: null,
					postCode: null,
					city: null,
					country: null
				}
			},
			transit: []
		},
		comment: null,
		alerts: [],
		temperature: {
			temperatureMin: [],
			temperatureMax: [],
			historyDeviceDateTemperature: [
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00'
			]
		},
		humidity: {
			humidityMin: [],
			humidityMax: [],
			historyDeviceDateHumidity: [
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00'
			]
		},
		historyPosition: [
			{
				latitude: 48.8706587,
				idPosition: 10922,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10923,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10924,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10925,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10949,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10950,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10951,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10953,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			}
		],
		device: {
			battery: 100,
			model: 'PT35',
			status: 'A',
			imei: '860689030764574',
			idDevice: 547,
			temperature: 0,
			humidity: 0,
			address: 'Mairie de Suresnes, 92150 Suresnes, France',
			position: {
				latitude: 48.8706587,
				longitude: 2.2242373
			},
			lastUpdate: '2021-05-14T08:01:31+00:00'
		}
	},
	{
		idContainer: 426,
		containerReference: 'Numero 1',
		transport: {
			idTransport: 173,
			transportName: 'Test for asset',
			destination: 'Str. des 17. Juni, Berlin, Allemagne',
			status: 'readytoship'
		},
		trip: {
			addressStart: {
				latitude: 49.0096739,
				longitude: 2.547966,
				fullAddressStart: 'Aéroport de Paris-Charles de Gaulle (CDG), 95700 Roissy-en-France, France'
			},
			addressDestination: {
				latitude: 52.5141475,
				longitude: 13.3492861,
				fullAddressEnd: 'Str. des 17. Juni, Berlin, Allemagne'
			},
			transit: [
				{
					latitude: 49.4049534,
					longitude: 2.8235242,
					tripNumber: 1,
					deviceDate: '100 Avenue de la Libération, 60200 Compiègne, France'
				},
				{
					latitude: 49.3757428,
					longitude: 3.3322023,
					tripNumber: 2,
					deviceDate: '10 Avenue de Reims, 02200 Soissons, France'
				}
			]
		},
		comment: "Test d'affichage 'MOCK'",
		alerts: [],
		temperature: {
			temperatureMin: [],
			temperatureMax: [],
			historyDeviceDateTemperature: [
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00'
			]
		},
		humidity: {
			humidityMin: [],
			humidityMax: [],
			historyDeviceDateHumidity: [
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00'
			]
		},
		historyPosition: [
			{
				latitude: 49.352924,
				idPosition: 10922,
				longitude: 2.78398,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 49.40614,
				idPosition: 10923,
				longitude: 2.823127,
				deviceDate: '2021-05-14T20:32:00+00:00'
			},
			{
				latitude: 49.396828,
				idPosition: 10923,
				longitude: 3.134523,
				deviceDate: '2021-05-14T22:11:00+00:00'
			}
		],
		device: {
			battery: 100,
			model: 'PT35',
			status: 'A',
			imei: '860689030764574',
			idDevice: 547,
			temperature: 20,
			humidity: 12,
			address: 'N31, Ressons le long',
			position: {
				latitude: 49.396828,
				longitude: 3.134523
			},
			lastUpdate: '2021-05-14T22:59:31+00:00'
		}
	},
	{
		idContainer: 400,
		containerReference: '4Ginit',
		transport: {
			idTransport: 171,
			transportName: 'Test Mode Avion',
			destination: 'Rugby Ct, Ridgewood, NJ 07450, EE. UU.',
			status: 'readytoship'
		},
		trip: {
			addressStart: {
				latitude: 48.8675885,
				longitude: 2.2251694,
				fullAddressStart: '4 Rue Pierre Dupont, 92150 Suresnes, Francia'
			},
			addressDestination: {
				latitude: 40.992919,
				longitude: -74.1024039,
				fullAddressEnd: 'Rugby Ct, Ridgewood, NJ 07450, EE. UU.',
				address: {
					street: null,
					postCode: null,
					city: null,
					country: null
				}
			},
			transit: []
		},
		comment: null,
		alerts: [],
		temperature: {
			temperatureMin: [],
			temperatureMax: [],
			historyDeviceDateTemperature: [
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00'
			]
		},
		humidity: {
			humidityMin: [],
			humidityMax: [],
			historyDeviceDateHumidity: [
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00',
				'2021-05-14 20:21:00'
			]
		},
		historyPosition: [
			{
				latitude: 48.8706587,
				idPosition: 10922,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10923,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10924,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10925,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10949,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10950,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10951,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			},
			{
				latitude: 48.8706587,
				idPosition: 10953,
				longitude: 2.2242373,
				deviceDate: '2021-05-14T20:21:00+00:00'
			}
		],
		device: {
			battery: 100,
			model: 'PT35',
			status: 'A',
			imei: '860689030764574',
			idDevice: 547,
			temperature: 0,
			humidity: 0,
			address: 'Mairie de Suresnes, 92150 Suresnes, France',
			position: {
				latitude: 48.8706587,
				longitude: 2.2242373
			},
			lastUpdate: '2021-05-14T08:01:31+00:00'
		}
	},
	{
		idContainer: 1000,
		containerReference: 'Perfect',
		transport: {
			idTransport: 159,
			transportName: 'Transport sur mesure',
			destination: '457 Chingford Rd, London E4 8SP, Royaume-Uni',
			status: 'readytoship'
		},
		trip: {
			addressStart: {
				latitude: 48.351956,
				longitude: -0.0780073,
				fullAddressStart: '1 Rue de Narbonne, 72130 Saint-L\u00e9onard-des-Bois, France'
			},
			addressDestination: {
				latitude: 51.6025348,
				longitude: -0.0171938,
				fullAddressEnd: '457 Chingford Rd, London E4 8SP, Royaume-Uni'
			},
			transit: [
				{
					latitude: 48.859877,
					longitude: 2.3254911,
					fullAddress: '12 rue du park, la villette, FR'
				},
				{
					latitude: 50.694147,
					longitude: 3.162917,
					fullAddress: 'JFK airport, NYC, USA'
				}
			]
		},
		comment: "Test d'un itinéraire",
		alerts: [
			{
				id: 213,
				ruleName: 'Temperature Willem',
				description: null,
				ruleKey: 'Temperature',
				ruleValue: '',
				deleted: 'N',
				creationBy: 71,
				modificationDate: null,
				modificationBy: null,
				idCustomer: 40,
				ruleValueMin: 5,
				ruleValueMax: 30,
				direction: '',
				address: '',
				gpsLocation: '',
				creationDate: '2021-03-22T21:28:16+00:00',
				alertStatus: 'R',
				idDevice: null
			},
			{
				id: 661,
				ruleName: '_temperature_',
				description: null,
				ruleKey: '_temperature_',
				ruleValue: '_temperature_',
				deleted: 'N',
				creationBy: 71,
				modificationDate: null,
				modificationBy: null,
				idCustomer: null,
				ruleValueMin: 0,
				ruleValueMax: 20,
				direction: '',
				address: '',
				gpsLocation: '',
				creationDate: '2021-05-05T13:10:51+00:00',
				alertStatus: 'R',
				idDevice: 512
			}
		],
		temperature: {
			temperatureMin: [5, 5, 5, 5, 5, 5, 5, 5, 5, 5],
			temperatureMax: [25, 25, 25, 25, 25, 25, 25, 25, 25, 25],
			historyDeviceDateTemperature: [19, 20, 21, 20, 21, 20, 18, 20, 20, 22]
		},
		humidity: {
			humidityMin: [],
			humidityMax: [],
			historyDeviceDateHumidity: []
		},
		historyPosition: [
			{
				latitude: 48.346625,
				longitude: -0.077022,
				deviceDate: '2021-05-14T08:03:31+00:00'
			},
			{
				latitude: 48.337529,
				longitude: -0.06389,
				deviceDate: '2021-05-14T08:04:34+00:00'
			},
			{
				latitude: 48.328574,
				longitude: -0.048349,
				deviceDate: '2021-05-14T08:05:35+00:00'
			},
			{
				latitude: 48.310713,
				longitude: -0.004361,
				deviceDate: '2021-05-14T08:06:36+00:00'
			},
			{
				latitude: 48.300709,
				longitude: 0.012483,
				deviceDate: '2021-05-14T08:07:36+00:00'
			},
			{
				latitude: 48.29346,
				longitude: 0.016547,
				info: '2021-05-14T08:10:39+00:00'
			},
			{
				latitude: 48.293219,
				longitude: 0.017783,
				deviceDate: '2021-05-14T08:11:40+00:00'
			},
			{
				latitude: 48.292697,
				longitude: 0.022636,
				deviceDate: '2021-05-14T08:14:43+00:00'
			},
			{
				latitude: 48.290813,
				longitude: 0.031219,
				deviceDate: '2021-05-14T08:15:45+00:00'
			},
			{
				latitude: 48.285867,
				longitude: 0.039751,
				deviceDate: '2021-05-14T08:016:50+00:00'
			},
			{
				latitude: 48.286974,
				longitude: 0.045598,
				deviceDate: '2021-05-14T08:20:50+00:00'
			},
			{
				latitude: 48.300149,
				longitude: 0.093824,
				deviceDate: '2021-05-14T08:22:50+00:00'
			},
			{
				latitude: 48.309445,
				longitude: 0.131664,
				deviceDate: '2021-05-14T08:24:50+00:00'
			},
			{
				latitude: 48.303123,
				longitude: 0.148806,
				deviceDate: '2021-05-14T08:26:50+00:00'
			},
			{
				latitude: 48.217655,
				longitude: 0.177988,
				deviceDate: '2021-05-14T08:29:50+00:00'
			},
			{
				latitude: 48.173263,
				longitude: 0.155889,
				deviceDate: '2021-05-14T08:31:36+00:00'
			},
			{
				latitude: 48.059935,
				longitude: 0.175802,
				deviceDate: '2021-05-14T08:35:50+00:00'
			},
			{
				latitude: 48.059021,
				longitude: 0.213868,
				deviceDate: '2021-05-14T08:36:50+00:00'
			},
			{
				latitude: 48.05465,
				longitude: 0.36184,
				deviceDate: '2021-05-14T08:40:36+00:00'
			},
			{
				latitude: 48.164746,
				longitude: 0.743615,
				deviceDate: '2021-05-14T08:41:50+00:00'
			},
			{
				latitude: 48.327331,
				longitude: 1.375702,
				deviceDate: '2021-05-14T08:43:50+00:00'
			},
			{
				latitude: 48.39908,
				longitude: 1.497539,
				deviceDate: '2021-05-14T08:47:50+00:00'
			},
			{
				latitude: 48.471075,
				longitude: 1.58955,
				deviceDate: '2021-05-14T08:48:50+00:00'
			},
			{
				latitude: 48.539281,
				longitude: 1.887902,
				deviceDate: '2021-05-14T08:51:50+00:00'
			},
			{
				latitude: 48.58916,
				longitude: 2.03244,
				deviceDate: '2021-05-14T08:52:50+00:00'
			},
			{
				latitude: 48.626734,
				longitude: 2.138527,
				deviceDate: '2021-05-14T08:53:50+00:00'
			},
			{
				latitude: 48.691392,
				longitude: 2.228246,
				deviceDate: '2021-05-14T08:54:02+00:00'
			},
			{
				latitude: 48.717682,
				longitude: 2.257163,
				deviceDate: '2021-05-14T08:55:50+00:00'
			},
			{
				latitude: 48.711408,
				longitude: 2.288259,
				deviceDate: '2021-05-14T08:58:50+00:00'
			},
			{
				latitude: 48.767621,
				longitude: 2.345681,
				deviceDate: '2021-05-14T08:59:50+00:00'
			},
			{
				latitude: 48.806757,
				longitude: 2.345338,
				deviceDate: '2021-05-14T09:06:36+00:00'
			},
			{
				latitude: 48.826203,
				longitude: 2.390353,
				deviceDate: '2021-05-14T09:08:36+00:00'
			},
			{
				latitude: 48.84598,
				longitude: 2.367822,
				deviceDate: '2021-05-14T09:12:36+00:00'
			},
			{
				latitude: 48.85129,
				longitude: 2.362415,
				deviceDate: '2021-05-14T09:15:36+00:00'
			},
			{
				latitude: 48.855442,
				longitude: 2.352802,
				deviceDate: '2021-05-14T09:17:36+00:00'
			},
			{
				latitude: 48.859013,
				longitude: 2.355698,
				deviceDate: '2021-05-14T09:20:36+00:00'
			},
			{
				latitude: 48.859563,
				longitude: 2.354068,
				deviceDate: '2021-05-14T09:22:42+00:00'
			},
			{
				latitude: 48.859788,
				longitude: 2.3544,
				deviceDate: '2021-05-14T09:23:36+00:00'
			},
			{
				latitude: 48.860346,
				longitude: 2.352962,
				deviceDate: '2021-05-14T09:24:36+00:00'
			},
			{
				latitude: 48.858409,
				longitude: 2.351938,
				deviceDate: '2021-05-14T09:25:36+00:00'
			},
			{
				latitude: 48.857971,
				longitude: 2.353022,
				deviceDate: '2021-05-14T09:26:36+00:00'
			},
			{
				latitude: 48.857311,
				longitude: 2.352469,
				deviceDate: '2021-05-14T09:27:36+00:00'
			}
		],
		device: {
			battery: null,
			model: 'HC01',
			status: 'S',
			imei: '77773233344778',
			idDevice: 495,
			temperature: null,
			humidity: null,
			address: null,
			position: {
				latitude: null,
				longitude: null
			},
			lastUpdate: '2021-05-18T22:24:55+00:00'
		}
	}
];

// Formulaire pour ajouter un device ******************************************************
mock.onPost('api/transport/container').reply(config => {
	const data = JSON.parse(config.data);
	const response = container.filter(req => Number(data.idContainer) === req.idContainer);
	return [200, response[0]];
});
