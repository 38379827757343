import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const TitleTabSettings = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	padding-bottom: 10px;
	margin-bottom: 10px;
	border-bottom: 1px solid
		${props => (props.disabledBorder ? 'transparent' : props.theme.palette.stracker.colorOutlined)};
	h4 {
		color: ${props => props.theme.palette.stracker.contrastText};
		font-size: 24px;
		margin-left: 10px;
	}
`;
