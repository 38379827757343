import moment from 'moment';
import 'moment/locale/fr';

const translationDate = (currentLang, dateBeforeTranslated, min, remove) => {
	if (currentLang === 'fr') {
		if (min) {
			if (remove) {
				return moment(dateBeforeTranslated).subtract(2, 'hours').calendar({
					sameDay: "[Aujourd'hui] [à] HH:mm",
					nextDay: '[Demain]',
					nextWeek: 'dddd',
					lastDay: '[Hier] [à] HH:mm',
					lastWeek: 'dddd [dernier] [à] HH:mm',
					sameElse: 'DD/MM/YYYY [à] HH:mm'
				});
			}

			return moment(dateBeforeTranslated).calendar({
				sameDay: "[Aujourd'hui] [à] HH:mm",
				nextDay: '[Demain]',
				nextWeek: 'dddd',
				lastDay: '[Hier] [à] HH:mm',
				lastWeek: 'dddd [dernier] [à] HH:mm',
				sameElse: 'DD/MM/YYYY [à] HH:mm'
			});
		}
		return moment(dateBeforeTranslated).calendar({
			sameDay: "[Aujourd'hui] [à] HH:mm",
			nextDay: '[Demain]',
			nextWeek: 'dddd',
			lastDay: '[Hier] [à] HH:mm',
			lastWeek: 'dddd [dernier] [à] HH:mm',
			sameElse: 'DD/MM/YYYY [à] HH:mm'
		});
	}
	if (min) {
		if (remove) {
			return moment(dateBeforeTranslated).subtract(2, 'hours').calendar({
				sameDay: '[Today] [at] h:mm a',
				nextDay: '[Tomorrow]',
				nextWeek: 'dddd',
				lastDay: '[Yesterday] [at] h:mm a',
				lastWeek: '[Last] dddd [at] h:mm a',
				sameElse: 'DD/MM/YYYY [at] h:mm a'
			});
		} else {
			return moment(dateBeforeTranslated).calendar({
				sameDay: '[Today] [at] h:mm a',
				nextDay: '[Tomorrow]',
				nextWeek: 'dddd',
				lastDay: '[Yesterday] [at] h:mm a',
				lastWeek: '[Last] dddd [at] h:mm a',
				sameElse: 'DD/MM/YYYY [at] h:mm a'
			});
		}
		
	}
	return moment(dateBeforeTranslated).calendar({
		sameDay: '[Today] [at] h:mm a',
		nextDay: '[Tomorrow]',
		nextWeek: 'dddd',
		lastDay: '[Yesterday] [at] h:mm a',
		lastWeek: '[Last] dddd [at] h:mm a',
		sameElse: 'DD/MM/YYYY [at] h:mm a'
	});
};
export default translationDate;
