const locale = {
	CONNEXION: 'Connectivity',
	CONTENU: 'Content(s)',
	CREATEAT: 'Created',
	ERROR_MIN_CHAR: 'Please enter at least 3 characters',
	FENCING: 'Close',
	HIDE_FILTERS: 'Hide Filters',
	HUMIDITY: 'Humidity',
	NO_RESULT: 'no results',
	PLACEHOLDER_SEARCH: 'Type the reference of a transport or a tag',
	POSITION: 'Position',
	RANGE_BY: 'Ranger by',
	RANGE_BY_ASC: 'From latest to oldest',
	RANGE_BY_DSC: 'From oldest to latest',
	RESULT: 'Result',
	RESULT_TEXT: 'Result of the research',
	SEARCH: 'Search',
	SHOW_FILTERS: 'Show filters',
	TEMPERATURE: 'Temperature',
	TITLE: 'History',
	TITLE_INFO: 'Notes',
	CLOSING: 'Close',
	CREATED: 'Create',
	ASSET: 'Asset',
	TRANSPORT: 'Transport',
	DESTINATION: 'Destination',
	ARRIVED_AT: 'Arrived the ',
	START_COUNTRY: 'Departure',
	END_COUNTRY: 'Arrival',
	CAR: 'Car',
	PLANE: 'Plane',
	BOAT: 'Boat',
	TRANSPORT_TYPE: 'Means',
	DATE: 'Dates',
	FROM: 'From',
	TO: 'To',
	FROM_FILTER: 'From ',
	TO_FILTER: 'To ',
	EXPORT_DATA: 'Export Excel',
	QUOTE: 'Quote',
	CLIENT: 'Client',
	ORDER_BY: 'Order by',
	FILTER_BY: 'Filter by'
};

export default locale;
