const locale = {
	ADDCONTENU: 'Add Content',
	ASSET: 'Asset',
	CANCEL: 'Cancel',
	CHANGEAT: 'Edited on',
	COMMENT_BACK: 'Close',
	COMMENT_BUTTON: 'Add a comment',
	COMMENT_CONFIRM_DELETE: 'Comment will be deleted. Are you sure ?',
	COMMENT_TITLE: 'Comments',
	CONNEXION: 'Connectivity',
	CONTENT: 'Containers',
	CONTENU: 'Content',
	CONTENU_ADD_TITLE: 'Add content',
	CONTENU_BACK: 'Close',
	CONTENU_CONFIRM_DELETE: 'Content will be deleted. Are you sure ?',
	CREATEAT: 'Created',
	DANGER: 'Warning !',
	DELETECONTENU: 'Delete this content',
	DELETECONTENUDIALOG: 'Are you sure you want to delete this content ?',
	DELETECONTENUTITLE: 'Content deleted',
	ENDSCROLL: 'Last reached',
	LOADING: 'Loading',
	POSITION: 'Position',
	RANGE_BY: 'Sort by',
	RANGE_BY_ASC: 'From latest to oldest',
	RANGE_BY_DSC: 'From oldest to latest',
	SAVE: 'Save',
	SEARCHHEADER: 'Search',
	SEEMORE: 'See more',
	SHOW_FILTERS: 'Display filters',
	TITLE: 'Content(s)',
	TRACKERS: 'Trackers',
};

export default locale;
