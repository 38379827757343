const locale = {
	TITLE: 'Dashboard',
	OFFLINE: 'Devices Offline',
	SEELIST: 'See more list',
	STOCKS: 'Devices Stocks',
	ONLINE: 'Devices Online',
	SEARCHHEADER: 'Search for...',
	INTERNALMAP: 'Internal map',
	EXTERNALMAP: 'External map',
	CONTAINER_LIST: 'Containers list',
	UPDATED_AT: 'Updated',
	DASHBOARD_DEVICES: 'devices',
	ERROR_REQUEST: 'No information available',
	TRANSPORT: 'Transports',
	NO_MASTER: 'No content',
	TO_COMPLETE: 'In process',
	READY_TO_GO: 'Ready to go',
	TRANSIT: 'In transit',
	DESTINATION_END: 'Arrived',
	TO_COMPLETE_RES: 'In process',
	READY_TO_GO_RES: 'Ready to go',
	OFFER_TO_ACCEPT: 'offers to validate',
	ARRIVAL: 'Arrival'
};

export default locale;
