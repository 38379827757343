import React from 'react';

const TruckIcon = props => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={props.className}
		>
			<rect width="20" height="20" rx="10" fill="#A8C1F7" />
			<path
				d="M12.333 4.75H3.58301V12.3333H12.333V4.75Z"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.333 7.66675H14.6663L16.4163 9.41675V12.3334H12.333V7.66675Z"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.20833 15.2499C7.01375 15.2499 7.66667 14.597 7.66667 13.7916C7.66667 12.9862 7.01375 12.3333 6.20833 12.3333C5.40292 12.3333 4.75 12.9862 4.75 13.7916C4.75 14.597 5.40292 15.2499 6.20833 15.2499Z"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.7913 15.2499C14.5968 15.2499 15.2497 14.597 15.2497 13.7916C15.2497 12.9862 14.5968 12.3333 13.7913 12.3333C12.9859 12.3333 12.333 12.9862 12.333 13.7916C12.333 14.597 12.9859 15.2499 13.7913 15.2499Z"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default TruckIcon;
