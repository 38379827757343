import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { OutlinedInput, MenuItem, Select, TextField } from '@material-ui/core';
import styled from 'styled-components';
import _ from '@lodash';
import UserIcon from '@stracker/customicons/UserIcon';
import { Avatar } from '@material-ui/core';
import CircleEmpty from '@stracker/customicons/CircleEmpty';
import CircleFilled from '@stracker/customicons/CircleFilled';
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
	font1: {
		color: theme.palette.stracker.contrastTextDark,
		fontWeight: 600,
		fontSize: '12px'
	},
	select: {
		borderRadius: '15px !important',
		marginLeft: '10px',
		backgroundColor: 'none',
		'&:after': {
			borderBottomColor: theme.palette.stracker.contrastTextDark
		},
		'& .MuiSvgIcon-root': {
			color: theme.palette.stracker.contrastTextDark
		}
	},
	avatar: {
		width: '24px',
		height: '24px',
		borderColor: theme.palette.stracker.contrastTextDark,
		border: '2px solid',
		boxShadow: '0px 4px 4px rgba(7, 78, 232, 0.15)',
		marginRight: '8px',
		backgroundColor: '#FAE9CE',
		color: '#EDA124',
		fontSize: '12px'
	}
}));

const MenuProps = {
	style: {
		width: '2000px'
	}
};

const compareByCustomerName = (a, b) => {
	return a.customerName.toUpperCase() > b.customerName.toUpperCase() ? 1 : -1;
};

function ClientFilter({ filtersList, setFiltersList, customerFilterList }) {
	const theme = useTheme();
	const { t } = useTranslation('history');
	const classes = useStyles();
	const matchesSm = useMediaQuery('(min-width:600px)');
	customerFilterList.sort(compareByCustomerName);

	const [searchCustomer, setSearchCustomer] = useState('');

	const handleClick = data => {
		let filtersListCpy = filtersList;
		let ret = true;

		// eslint-disable-next-line
		customerFilterList.map(customerData => {
			if (customerData === data) customerData.display = !customerData.display;
		});

		// eslint-disable-next-line
		filtersList.map(filter => {
			if (filter.type === 'customer' && data.idCustomer === filter.idCustomer) {
				ret = false;
			}
		});

		if (ret === true)
			filtersListCpy.push({ type: 'customer', value: data.customerName, idCustomer: data.idCustomer });
		else {
			filtersListCpy.forEach((filter, index) => {
				if (filter.idCustomer === data.idCustomer) {
					filtersListCpy.splice(index, 1);
				}
			});
		}

		setSearchCustomer('');
		setFiltersList([...filtersListCpy]);
	};

	const handleInputChange = e => {
		setSearchCustomer(e.target.value);
	};

	return (
		<Select
			className={classes.select}
			multiple
			displayEmpty
			value={[]}
			input={<OutlinedInputStyled />}
			renderValue={selected => {
				return (
					<div style={{ display: 'flex' }}>
						<UserIcon color={theme.palette.stracker.contrastTextDark} />
						{matchesSm && (
							<p className={classes.font1} style={{ marginLeft: '5px' }}>
								{t('CLIENT')}
							</p>
						)}
					</div>
				);
			}}
			MenuProps={MenuProps}
			inputProps={{ 'aria-label': 'Without label' }}
			components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
		>
			<TextField onChange={e => handleInputChange(e)} onKeyDown={e => e.stopPropagation()} />

			{customerFilterList.map((data, index) =>
				data.customerName.toLowerCase().includes(searchCustomer.toLowerCase()) ? (
					<MenuItem key={index} value={data} onClick={e => handleClick(data)}>
						{data.display === true ? <CircleFilled /> : <CircleEmpty />}

						<Avatar className={classes.avatar} style={{ marginLeft: '20px' }}>
							{data.customerName ? _.toUpper(data.customerName.slice(0, 1)) : null}
						</Avatar>
						<p
							style={{
								fontWeight: 'bold',
								color: theme.palette.stracker.contrastTextDark,
								marginLeft: '10px',
								marginRight: '20px'
							}}
						>
							{data.customerName}
						</p>
					</MenuItem>
				) : null
			)}
		</Select>
	);
}

export default ClientFilter;

const OutlinedInputStyled = styled(OutlinedInput)`
	.MuiOutlinedInput-input {
		padding-top: 4px;
		padding-bottom: 4px;
		height: 18px;
	}
	.MuiMenu-paper {
		top: 10px !important;
	}
`;
