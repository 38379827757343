import React, { useState } from 'react';
import withReducer from 'app/store/withReducer';
import { useTheme } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import CancelIcon from '@material-ui/icons/Cancel';
import reducer from './store/reducers';
import NotificationsContent from './NotificationsContent';
import { TitleTabSettings } from '../../../../../@stracker/components/title/TitleTabSettings';
import BellIcon from '../../../custom-icons/BellIcon';

function Notifications({ handleCloseDialogSettings, setBurgerIsOpen, burgerIsOpen, displayMenuBurger }) {
	const theme = useTheme();
	const [pageActuelle, setPageActuelle] = useState('');

	function changePage(comp) {
		setPageActuelle(comp);
	}

	return (
		<>
			<TitleTabSettings>
				{displayMenuBurger && (
					<MenuIcon className="cursor-pointer mr-10" onClick={() => setBurgerIsOpen(!burgerIsOpen)} />
				)}
				<BellIcon color={theme.palette.stracker.contrastText} />
				<h4>Notifications</h4>
				{displayMenuBurger && (
					<CancelIcon className=" cursor-pointer ml-auto" onClick={handleCloseDialogSettings} />
				)}
			</TitleTabSettings>
			<NotificationsContent
				handleCloseDialogSettings={handleCloseDialogSettings}
				pageActu={pageActuelle}
				switchPage={changePage}
			/>
		</>
	);
}

export default withReducer('notifications', reducer)(Notifications);
