import { combineReducers } from '@reduxjs/toolkit';
import auth from 'app/auth/store';
import fuse from './fuse';
import i18n from './i18nSlice';
import stracker from './stracker/reducers';

const createReducer = asyncReducers => (state, action) => {
	const combinedReducer = combineReducers({
		auth,
		fuse,
		i18n,
		stracker,
		...asyncReducers
	});
 
	/* Reset the redux store when user logged out : action.type === 'auth/user/userLoggedOut' */
	if (action.type === 'settings/setInitialSettings' || action.type === 'auth/user/userLoggedOut') {
		window.localStorage.clear();
		state = undefined;
	}

	return combinedReducer(state, action);
};

export default createReducer;

/*
const createReducer = asyncReducers =>
	combineReducers({
		auth,
		fuse,
		i18n,
		stracker,
		...asyncReducers
	});

export default createReducer; */
