import mock from '../mock';

const devicesDb = [
	{
		uniqueIdentifiant: 347788,
		asset: 'Camion n°123',
		content: 5, // content.lenght
		data: [{ temperature: '23°', battery: 80, humidity: '44%', type: 'supply' }],
		status: 'A',
		idCustomer: '1',
		customerName: 'EDF',
		adress: '151 rue de temple, 75003 Paris FRANCE'
	},
	{
		uniqueIdentifiant: 407911,
		asset: 'Entrepot 3',
		data: [{ temperature: 20, battery: 85, humidity: 49, type: 'GPS' }],
		content: 3, // content.lenght
		status: 'H',
		idCustomer: false,
		customerName: false,
		adress: '23 rue de CDG 95180 Roissy FRANCE'
	},
	{
		uniqueIdentifiant: 13000,
		asset: 'Vieux port',
		content: 4, // content.lenght
		data: [{ temperature: 15, battery: 67, humidity: 29, type: 'GPS' }],
		status: 'A',
		idCustomer: '3',
		customerName: 'ABWare',
		adress: '1 place du vieux port 13000 Marseille FRANCE'
	},
	{
		uniqueIdentifiant: 888001,
		asset: 'Boeing 777',
		content: 3, // content.lenght
		data: [{ temperature: 26, battery: 95, humidity: 11, type: 'GPS' }],
		status: 'A',
		idCustomer: '2',
		customerName: 'Air France',
		transportAssocie: 'Transport A',
		type: 'transport',
		adress: 'Entrepot 13 Marseille FRANCE',
		destination: 'Place Vendôme, Paris, FRANCE'
	},
	{
		uniqueIdentifiant: 407911,
		asset: 'Entrepot IV',
		battery: 100,
		data: [{ temperature: 15, battery: 100, humidity: 39, type: 'LBS' }],
		content: 6, // content.lenght
		status: 'A',
		idCustomer: '4',
		customerName: 'Fuse'
	},
	{
		uniqueIdentifiant: 888001,
		asset: 'Boeing 747 Cargo',
		battery: 95,
		content: 4, // content.lenght
		data: [{ temperature: 26, battery: 95, humidity: 11, type: 'GPS' }],
		status: 'H',
		idCustomer: '2',
		customerName: 'Air France',
		type: 'transport',
		transportAssocie: 'Transport AF742',
		destination: 'Temple Street, NYC, USA'
	}
];

mock.onPost('api/devices/list').reply(config => {
	return [200, devicesDb];
});
mock.onPost('api/device/customerList').reply(config => { 
	const device = devicesDb.filter(
		theDevice => theDevice.idCustomer === JSON.parse(config.data).idCustomer.toString()
	); 
  
	return [200, device];
});
// Formulaire pour ajouter un device ******************************************************
mock.onPost('api/devices/add').reply(config => {
	const data = JSON.parse(config.data);
	const devicesId = devicesDb;
	devicesId.push(data); 
	return [200, devicesId];
});
