import React from 'react';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import en from './i18n/en';
import fr from './i18n/fr';
// import AssetsApp from './Assets'

i18next.addResourceBundle('en', 'assets', en);
i18next.addResourceBundle('fr', 'assets', fr);

const AssetsConfig = {
	settings: {
		layout: {
			config: {
				// 	mode: 'fullwidth',
				// 	scroll: 'content',
				// 	navbar: { display: true },
				// 	toolbar: { display: false },
				// footer: { display: true },
				// 	leftSidePanel: { display: true },
				// 	rightSidePanel: { display: false }
			}
		}
	},
	routes: [
		{
			path: '/assets/list',
			component: React.lazy(() => import('./Assets'))
			// component: AssetsApp
		},
		{
			path: '/assets/details/:uniqueIdentifiant',
			component: React.lazy(() => import('./deviceDetails/Details'))
		},
		{
			path: '/assets',
			component: () => <Redirect to="/assets/list" />
		}
	]
};

export default AssetsConfig;

/**
 * Lazy load Example
 */
/*
import React from 'react';

const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
};

export default ExampleConfig;

*/
