import React from 'react';

function GlobeIcon(props) {
	return (
		<svg
			width={props.size || '18'}
			height={props.size || '18'}
			viewBox="0 0 26 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={props.className}
			color={props.color || 'currentColor'}
			style={{ minWidth: props.size || '18', minHeight: props.size || '18' }}
		>
			<path
				d="M13.0013 23.8332C18.9844 23.8332 23.8346 18.9829 23.8346 12.9998C23.8346 7.01675 18.9844 2.1665 13.0013 2.1665C7.01822 2.1665 2.16797 7.01675 2.16797 12.9998C2.16797 18.9829 7.01822 23.8332 13.0013 23.8332Z"
				stroke={props.color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.16797 13H23.8346"
				stroke={props.color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13.0013 2.1665C15.711 5.13305 17.251 8.98287 17.3346 12.9998C17.251 17.0168 15.711 20.8666 13.0013 23.8332C10.2916 20.8666 8.75165 17.0168 8.66797 12.9998C8.75165 8.98287 10.2916 5.13305 13.0013 2.1665V2.1665Z"
				stroke={props.color || 'currentColor'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default GlobeIcon;
