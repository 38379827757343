import React from 'react';
import { authRoles } from 'app/auth';
// import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import en from './i18n/en'; 
import fr from './i18n/fr';

i18next.addResourceBundle('fr', 'notifications', fr);
i18next.addResourceBundle('en', 'notifications', en); 

const NotificationsConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.user,
	routes: [
		{
			path: '/parametres/notifications',
			component: React.lazy(() => import('./Notifications'))
		} /* ,
		{
			path: '/notifications',
			component: () => <Redirect to="/parametres/notifications" />
		} */
	]
};

export default NotificationsConfig;
