import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText } from '@material-ui/core';
import * as Actions from '../store/actions';
import * as NotifActions from '../../notifications/store/actions';
import clsx from 'clsx';
import API from '@pi/api';

const useStyles = makeStyles(theme => ({
	paddSelect: {
		paddingRight: '35px'
	},
	'@global': {
		'.MpersoDialod .confirmValidate': {
			color: '#fff',
			backgroundColor: '#074EE8'
		},
		'.MpersoDialod .confirmCancel': {
			color: '#023E65',
			border: '1px solid #A8C1F7',
			backgroundColor: '#fff',
			position: 'absolute',
			left: '8px'
		},
		'.MuiList-root.MuiMenu-list.MuiList-padding': {
			/*border: '1px solid',
			borderColor:
				theme.palette.type === 'light'
					? `${theme.palette.stracker.variantOutlined} !important`
					: `${theme.palette.stracker.contrastTextRegular} !important`,
			// #A8C1F7;
			boxShadow: '0px 4px 4px ' + `${theme.palette.secondary.colorShadow}`,
			// rgb(7 78 232 / 15%);
			borderRadius: '8px',
			width: '140px'*/
		},
		'.MuiList-root.MuiMenu-list.MuiList-padding li:hover': {
			backgroundColor:
				theme.palette.type === 'light'
					? `${theme.palette.stracker.input} !important`
					: `${theme.palette.stracker.body} !important`
		}
	}
}));

const ITEM_HEIGHT = 48;

function MoreBtn(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const { t } = useTranslation('membres');

	const user = useSelector(({ auth }) => auth.user);
	const members = useSelector(({ members }) => members.member.state);

	const [anchorEl, setAnchorEl] = useState(null);
	// eslint-disable-next-line
	const open = Boolean(anchorEl);

	const [isHidden, setIsHidden] = useState('');
	const [isClssHidden, setIsClssHidden] = useState('');
	const [closeMenu, setCloseMenu] = useState(false);

	/* *** Ouverture Dialog *** */
	const [openDialog, setOpenDialog] = useState(false);

	const handleClickOpenDialog = () => {
		setOpenDialog(true);
	};
	const handleCloseDialog = value => {
		// setPreset(null);
		props.eraseM();
		setOpenDialog(false);
	};
	/* ****** ***** */

	const handleClick = event => {
		setCloseMenu(!closeMenu);

		setAnchorEl(event.currentTarget);
	};

	const deleteAssociatedMail = async () => {
		let mail = null;
		let idNotificationContact = null;
		Object.values(members).forEach(member => {
			if (member.idUser === props.userId) {
				mail = member.email;
				return;
			}
		});
		const request = API.post('/notification', {
			action: 'list',
			contactType: 'email',
			type: 'customer',
			idCustomer: user.data.idCustomer
		});
		await request.then(response => {
			response.data.forEach(user => {
				if (user.contactId === mail) idNotificationContact = user.idNotificationContact;
			});
		});
		if (idNotificationContact)
			await dispatch(NotifActions.deleteNotification(idNotificationContact, user.data.idCustomer, props.userId));
	};

	const handleDelete = () => {
		setAnchorEl(null);
		props.eraseM();
		const memberInfo = {
			idCustomer: user.data.idCustomer,
			idUser: props.userId
		};
		dispatch(Actions.deleteUser(memberInfo));
		deleteAssociatedMail();
		setOpenDialog(false);
	};

	const updateMember = () => {
		props.switchPage(props.userId, 'EditMembers');
		setCloseMenu(false);
	};

	//user.role !== 'userAdmin' ? 'hidden' : 'role-user'
	useEffect(() => {
		if (user.role === 'userAdmin') {
			if (user.id === props.userId) {
				setIsHidden('hidden');
				setIsClssHidden('');
			} else {
				setIsHidden('role-user');
				setIsClssHidden('hidden');
			}
		}
		if (user.role === 'superAdmin') {
			if (user.id === props.userId) {
				setIsHidden('hidden');
				setIsClssHidden('');
			} else {
				setIsHidden('role-user');
				setIsClssHidden('');
			}
		}
		if (user.role === 'user') {
			setIsHidden('hidden');
			setIsClssHidden('hidden');
		}
		// eslint-disable-next-line
	}, [user.role]);

	function printDialog(idMyUser) {
		// setPreset(idMyUser);
		props.eraseM();
		handleClickOpenDialog();
	}

	return (
		<div>
			<div>
				<Dialog
					open={openDialog}
					onClose={handleCloseDialog}
					className="MpersoDialod"
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title" />
					<DialogContent>
						<DialogContentText id="alert-dialog-description">{t('CONFIRM_DELETE_USER')}</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							className="confirmCancel rounded-8 normal-case"
							onClick={handleCloseDialog}
							color="primary"
						>
							{t('BOUTON_CANCEL')}
						</Button>
						<Button
							className="confirmValidate rounded-8 normal-case"
							onClick={handleDelete}
							color="primary"
						>
							{t('BOUTON_VALIDER')}
						</Button>
					</DialogActions>
				</Dialog>
			</div>

			<IconButton
				edge="end"
				aria-label="more"
				aria-controls="long-menu"
				aria-haspopup="true"
				onClick={handleClick}
				className={isHidden}
			>
				<MoreVertIcon />
			</IconButton>
			<div className={clsx(isClssHidden, classes.paddSelect)}></div>
			<Menu
				id="long-menu"
				anchorEl={anchorEl}
				keepMounted
				open={closeMenu}
				onBackdropClick={e => {
					e.stopPropagation();
					setCloseMenu(false);
				}}
				PaperProps={{
					style: {
						maxHeight: ITEM_HEIGHT * 4.5,
						width: '20ch'
					}
				}}
			>
				<MenuItem onClick={updateMember} value={10}>
					{t('MEMBMODIF')}
				</MenuItem>
				<MenuItem onClick={() => printDialog(props.userId)} value={10}>
					{t('MEMBSUPPR')}
				</MenuItem>
			</Menu>
		</div>
	);
}

export default MoreBtn;
