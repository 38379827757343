import styled, { keyframes } from 'styled-components';
import ListItem from '@material-ui/core/ListItem';

const appearListAnimation = keyframes`
  from {
    opacity: 0;
    max-height: 0
    
  }
  to {
    opacity:1;
    max-height: 50vh
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const ListItemStyledWithSelect = styled(ListItem)`
	.react-tel-input .selected-flag[title=''] .flag {
		background-image: url('https://upload.wikimedia.org/wikipedia/commons/2/2f/Missing_flag.png') !important;
		background-size: cover;
		background-position: center;
	}
	.containerPhoneInput {
		/*background-color: ${props => props.theme.palette.stracker.input};
		border-radius: 8px;*/
	}
	.PhoneInput {
		background-color: ${props => props.theme.palette.stracker.input};
		font-weight: 600;
		color: ${props => props.theme.palette.stracker.contrastTextDark};
		border: none;
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		width: 100%;
	}
	.buttonPhoneInput {
		background-color: ${props => props.theme.palette.stracker.input};
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		border: none;
		border-right: 1px solid ${props => props.theme.palette.stracker.contrastTextLight};
	}
	.react-tel-input .selected-flag:hover,
	.react-tel-input .selected-flag:focus {
		border-top-left-radius: 8px;
		border-bottom-left-radius: 8px;
		border-left: 1px solid ${props => props.theme.palette.stracker.contrastTextLight};
		border-top: 1px solid ${props => props.theme.palette.stracker.contrastTextLight};
		border-bottom: 1px solid ${props => props.theme.palette.stracker.contrastTextLight};
	}
	.dropdownPhoneInput {
		/* background-color: ${props => props.theme.palette.stracker.input}; */
	}
	.react-tel-input .country-list .country:hover {
		background-color: ${props => props.theme.palette.stracker.input};
		color: ${props => props.theme.palette.stracker.contrastTextLight};
	}
	.react-tel-input .country-list {
		/*top: -10px;
		left: 50px;*/
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		border: 3px solid ${props => props.theme.palette.stracker.input};
		box-shadow: 0 2px 0 50000px rgb(0 0 0 / 40%);
		/*max-height: 40vh;*/
		padding: 0 4px 2px;
		border-radius: 8px;
		animation: 0.2s linear forwards ${appearListAnimation};
		background-color: ${props => props.theme.palette.stracker.input};
	}
	.react-tel-input .country-list .country {
		font-size: 14px;
	}
	.react-tel-input .flag-dropdown.open .selected-flag {
		border-radius: 8px 0 0 8px;
		border: 1px solid ${props => props.theme.palette.stracker.contrastTextDark};
		border-right: none;
	}
	.react-tel-input .form-control.open {
		border: 1px solid ${props => props.theme.palette.stracker.contrastTextDark};
	}
	.searchPhoneInput {
		background-color: red;
	}
`;
