import './db/auth-db';
import './db/icons-db';
import './db/notifications-db';
import './db/clients-db';
import './db/devices-db';
import './db/transport-db';
import './db/mapindoor-db';
import mock from './mock';

mock.onAny().passThrough();
