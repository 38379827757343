import _ from '@lodash';
import TextField from '@material-ui/core/TextField';
import { withFormsy, addValidationRule } from 'formsy-react';
import React from 'react';

function TextFieldFormsy(props) {
	const importedProps = _.pick(props, [
		'autoComplete',
		'autoFocus',
		'children',
		'className',
		'defaultValue',
		'disabled',
		'FormHelperTextProps',
		'fullWidth',
		'id',
		'InputLabelProps',
		'inputProps',
		'InputProps',
		'inputRef',
		'label',
		'multiline',
		'name',
		'onBlur',
		'onChange',
		'onFocus',
		'placeholder',
		'required',
		'rows',
		'rowsMax',
		'select',
		'SelectProps',
		'type',
		'variant',
		'color'
	]);

	const { errorMessage } = props;
	const value = props.number ? props.value || 0 : props.value ||  '';

	function changeValue(event) {
		props.setValue(event.currentTarget.value);
		if (props.onChange) {
			props.onChange(event);
		}
	}

	/**
	 * //*\_ fonction importée de formsy pour personnaliser les erreurs de formulaire
	 */
	addValidationRule('isCoherentTemp', (values, value) => {
		return values.temperature_min <= values.temperature_max;
	});

	addValidationRule('isCoherentHum', (values, value) => {
		return values.humidity_min <= values.humidity_max;
	});

	return (
		<TextField
			{...importedProps}
			onChange={changeValue}
			value={value}
			error={Boolean((!props.isPristine && props.showRequired) || errorMessage)}
			helperText={errorMessage}
			autoComplete="nope"
		/>
	);
}

export default React.memo(withFormsy(TextFieldFormsy));
