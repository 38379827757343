import React from 'react';
import i18next from 'i18next';
import en from './i18n/en';
import fr from './i18n/fr';
// import { authRoles } from 'app/auth';

i18next.addResourceBundle('fr', 'dashboard', fr);
i18next.addResourceBundle('en', 'dashboard', en);

const DashboardConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	// auth: authRoles.userAdmin,
	routes: [
		{
			path: '/dashboards',
			component: React.lazy(() => import('./Dashboard'))
		}
	]
};

export default DashboardConfig;
