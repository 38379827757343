import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ContainerScrollDivPage = styled.section`
	height: 100%;
	overflow: ${props => (props.alwaysScrollable ? 'scroll' : 'auto')};
	padding-right: ${props => (props.noPr ? '0' : '7px')};
	font-weight: 600;
	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-thumb {
		border: 2px solid ${props => props.theme.palette.stracker.colorOutlined};
		box-shadow: none;
	}
	.infinite-scroll-component {
		overflow: ${props => (props.alwaysScrollable ? 'scroll !important' : 'auto')};
	}

	@media (min-width: 600px) {
      padding-right: ${props => (props.noPr ? '0' : '14px')};
	}
`;
