const locale = {
	ACQUISITION: 'Acquisition',
	ADD: 'Ajouter',
	ADD_DEVICE: 'Ajouter un tracker',
	ADD_NEW_TRACKER: 'Ajouter un nouveau tracker',
	ADD_SUPPLY: 'Créer un asset',
	ADD_TRANSPORT: 'Créer un transport',
	ADVANCED_SETTINGS: 'Paramètres avancés',
	ASSET_INFORMATION: "Informations de l'asset",
	ASSOCIER: 'Associer',
	ASSSOCIER_TRACKER: 'Associer un tracker à un client',
	BOUTON_ASSOCIER: 'Associer',
	BOUTON_CANCEL: 'Annuler',
	BOUTON_VALIDER: 'Valider',
	CHOOSE_TYPE_TRACKER: 'Modèle de tracker',
	CONFIRMER_RECYCLAGE: 'Confirmer le recyclage',
	CONNEXION: 'Connectivité',
	CONTENT: 'Contenu(s)',
	CREATE_A_TRACKER: "Enregistrement d'un tracker",
	CREATE_TRACKER: 'Créer tracker',
	CUSTOMER: 'Client',
	DELETE_TRACKER: 'Supprimer le tracker',
	DIALOG_ALERT_GENERAL: 'Alertes - personnalisées',
	DIALOG_ALERT_PRESET: 'Alertes - favoris',
	DISSOCIER: 'Dissocier',
	DISSOCIER_TRACKER_TEXT: 'Cette action entraînera la fin de la capture de données pour cet actif',
	DISSOCIER_TRACKER_part1: 'Voulez-vous dissocier le tracker ',
	DISSOCIER_TRACKER_part2: ' du client ',
	EMISSION: 'Emission',
	GEOLOCALISATION: 'Géolocalisation',
	HUMIDITY: 'Humidité',
	HUMIDITY_HISTORY: "Historique d'humidité",
	LATER: 'Plus tard',
	MESSAGE_RECYCLE: 'Voulez-vous recycler le tracker ',
	MODES: 'Modes',
	MODIFIER_TRACKER: 'Modifier le tracker',
	NEXT: 'Suivant',
	PARAMETRER_TRACKER: 'Paramétrer le tracker',
	POSITION: 'Localisation',
	RANGE_BY: 'Ranger par',
	RANGE_BY_ASC: 'Du plus récent au moins récent',
	RANGE_BY_DSC: 'Du moins récent au plus récent',
	RECYCLAGE_CLIENT_TEXT: 'Une fiche de recyclage va vous être envoyé par email pour impression',
	SEARCH: 'Rechercher',
	SEND: 'Valider',
	SETTINGS: 'Paramètres',
	SET_UP_TRACKER: "Paramétrage d'un tracker",
	SIMILAR_POSITION: '# positions similaires',
	TEMPERATURE: 'Température',
	TEMPERATURE_HISTORY: 'Historique des températures',
	TEMP_HUMIDITY: 'Température et Humidité',
	TITLE: 'Les trackers',
	TRACKER: 'tracker',
	TRACKERS: 'Trackers',
	UPDATE_ON: 'Mise à jour le',
	YES: 'Oui',
	EVENT: 'Evénements',
	SENSOR: 'Capteurs',
	MAP: 'Carte',
	DATE_PICKER_BEGIN: 'Début',
	DATE_PICKER_END: 'Fin',
	HISTORY: 'Historique',
	HISTORY_OF: 'Historique de',
	HISTORY_MOVE: 'Passé par la',
	HISTORY_NONE: 'Aucun Historique...',
	ALERT: 'Alerte',
	ENTER: 'Entrée',
	LEAVE: 'Sortie',
	GEOFENCING: 'Géofencing',
	CUSTOM_EVENT: 'Information',
	ETA: 'Dates estimées',
	MODIFICATION: 'Modification',
	IN_CHARGE: 'Prise en charge',
	TYPE_EVENT: "Type d'évènement"
};

export default locale;
