import mock from '../mock';

const clientsDB = {
	customers: [
		{
			idCustomer: 1,
			customerName: 'Edf1',
			users: [
				{
					idUser: 2,
					firstname: 'Jtyt',
					lastname: 'Dtrydt',
					superAdminId: true
				},
				{
					idUser: 1,
					firstname: 'Tdtryt',
					lastname: 'Mtry',
					superAdminId: false
				},
				{
					idUser: 3,
					firstname: 'P',
					lastname: 'V',
					superAdminId: false
				},
				{
					idUser: 4,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 5,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 6,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 7,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 8,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 9,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				}
			],
			totalUsers: 100,
			totalTrackers: 378899,
			onStock: 2500,
			incomplet: 320500,
			desabledTrackers: 4232,
			enableTrackers: 5167
		},
		{
			idCustomer: 2,
			customerName: 'Edf2',
			users: [
				{
					idUser: 2,
					firstname: 'Jtyt',
					lastname: 'Dtrydt',
					superAdminId: true
				},
				{
					idUser: 1,
					firstname: 'Tdtryt',
					lastname: 'Mtry',
					superAdminId: false
				},
				{
					idUser: 3,
					firstname: 'P',
					lastname: 'V',
					superAdminId: false
				},
				{
					idUser: 4,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 5,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 6,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 7,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 8,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 9,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				}
			],
			totalUsers: 100,
			totalTrackers: 378899,
			onStock: 2500,
			incomplet: 320500,
			desabledTrackers: 4232,
			enableTrackers: 5167
		},
		{
			idCustomer: 3,
			customerName: 'Edf3',
			users: [
				{
					idUser: 2,
					firstname: 'Jtyt',
					lastname: 'Dtrydt',
					superAdminId: true
				},
				{
					idUser: 1,
					firstname: 'Tdtryt',
					lastname: 'Mtry',
					superAdminId: false
				},
				{
					idUser: 3,
					firstname: 'P',
					lastname: 'V',
					superAdminId: false
				},
				{
					idUser: 4,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 5,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 6,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 7,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 8,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 9,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				}
			],
			totalUsers: 100,
			totalTrackers: 378899,
			onStock: 2500,
			incomplet: 320500,
			desabledTrackers: 4232,
			enableTrackers: 5167
		},
		{
			idCustomer: 16,
			customerName: 'Zdf4',
			users: [
				{
					idUser: 2,
					firstname: 'Jtyt',
					lastname: 'Dtrydt',
					superAdminId: true
				},
				{
					idUser: 1,
					firstname: 'Tdtryt',
					lastname: 'Mtry',
					superAdminId: false
				},
				{
					idUser: 3,
					firstname: 'P',
					lastname: 'V',
					superAdminId: false
				},
				{
					idUser: 4,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 5,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 6,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 7,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 8,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 9,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				}
			],
			totalUsers: 100,
			totalTrackers: 378899,
			onStock: 2500,
			incomplet: 320500,
			desabledTrackers: 4232,
			enableTrackers: 5167
		},
		{
			idCustomer: 5,
			customerName: 'Edf5',
			users: [
				{
					idUser: 2,
					firstname: 'Jtyt',
					lastname: 'Dtrydt',
					superAdminId: true
				},
				{
					idUser: 1,
					firstname: 'Tdtryt',
					lastname: 'Mtry',
					superAdminId: false
				},
				{
					idUser: 3,
					firstname: 'P',
					lastname: 'V',
					superAdminId: false
				},
				{
					idUser: 4,
					firstname: 'R',
					lastname: 'N',
					superAdminId: false
				},
				{
					idUser: 5,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 6,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 7,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 8,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 9,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 10,
					firstname: 'P',
					lastname: 'D',
					superAdminId: false
				}
			],
			totalUsers: 100,
			totalTrackers: 378899,
			onStock: 2500,
			incomplet: 320500,
			desabledTrackers: 4232,
			enableTrackers: 5167
		},
		{
			idCustomer: 6,
			customerName: 'Edf6',
			users: [
				{
					idUser: 2,
					firstname: 'Jtyt',
					lastname: 'Dtrydt',
					superAdminId: true
				},
				{
					idUser: 1,
					firstname: 'Tdtryt',
					lastname: 'Mtry',
					superAdminId: false
				},
				{
					idUser: 3,
					firstname: 'P',
					lastname: 'V',
					superAdminId: false
				},
				{
					idUser: 4,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 5,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 6,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 7,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 8,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 9,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				}
			],
			totalUsers: 100,
			totalTrackers: 378899,
			onStock: 2500,
			incomplet: 320500,
			desabledTrackers: 4232,
			enableTrackers: 5167
		},
		{
			idCustomer: 7,
			customerName: 'Edf7',
			users: [
				{
					idUser: 2,
					firstname: 'Jtyt',
					lastname: 'Dtrydt',
					superAdminId: true
				},
				{
					idUser: 1,
					firstname: 'Tdtryt',
					lastname: 'Mtry',
					superAdminId: false
				},
				{
					idUser: 3,
					firstname: 'P',
					lastname: 'V',
					superAdminId: false
				},
				{
					idUser: 4,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 5,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 6,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 7,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 8,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 9,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				}
			],
			totalUsers: 100,
			totalTrackers: 378899,
			onStock: 2500,
			incomplet: 320500,
			desabledTrackers: 4232,
			enableTrackers: 5167
		},
		{
			idCustomer: 8,
			customerName: 'Edf8',
			users: [
				{
					idUser: 2,
					firstname: 'Jtyt',
					lastname: 'Dtrydt',
					superAdminId: true
				},
				{
					idUser: 1,
					firstname: 'Tdtryt',
					lastname: 'Mtry',
					superAdminId: false
				},
				{
					idUser: 3,
					firstname: 'P',
					lastname: 'V',
					superAdminId: false
				},
				{
					idUser: 4,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 5,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 6,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 7,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 8,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 9,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				}
			],
			totalUsers: 100,
			totalTrackers: 378899,
			onStock: 2500,
			incomplet: 320500,
			desabledTrackers: 4232,
			enableTrackers: 5167
		},
		{
			idCustomer: 9,
			customerName: 'Zdf9',
			users: [
				{
					idUser: 2,
					firstname: 'Jtyt',
					lastname: 'Dtrydt',
					superAdminId: true
				},
				{
					idUser: 1,
					firstname: 'Tdtryt',
					lastname: 'Mtry',
					superAdminId: false
				},
				{
					idUser: 3,
					firstname: 'P',
					lastname: 'V',
					superAdminId: false
				},
				{
					idUser: 4,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 5,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 6,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 7,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 8,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 9,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				}
			],
			totalUsers: 100,
			totalTrackers: 378899,
			onStock: 2500,
			incomplet: 320500,
			desabledTrackers: 4232,
			enableTrackers: 5167
		},
		{
			idCustomer: 10,
			customerName: 'Edf10',
			users: [
				{
					idUser: 2,
					firstname: 'Jtyt',
					lastname: 'Dtrydt',
					superAdminId: true
				},
				{
					idUser: 1,
					firstname: 'Tdtryt',
					lastname: 'Mtry',
					superAdminId: false
				},
				{
					idUser: 3,
					firstname: 'P',
					lastname: 'V',
					superAdminId: false
				},
				{
					idUser: 4,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 5,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 6,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 7,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 8,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 9,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				}
			],
			totalUsers: 100,
			totalTrackers: 378899,
			onStock: 2500,
			incomplet: 320500,
			desabledTrackers: 4232,
			enableTrackers: 5167
		},
		{
			idCustomer: 11,
			customerName: 'Edf11',
			users: [
				{
					idUser: 2,
					firstname: 'Jtyt',
					lastname: 'Dtrydt',
					superAdminId: true
				},
				{
					idUser: 1,
					firstname: 'Tdtryt',
					lastname: 'Mtry',
					superAdminId: false
				},
				{
					idUser: 3,
					firstname: 'P',
					lastname: 'V',
					superAdminId: false
				},
				{
					idUser: 4,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 5,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 6,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 7,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 8,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 9,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				}
			],
			totalUsers: 100,
			totalTrackers: 378899,
			onStock: 2500,
			incomplet: 320500,
			desabledTrackers: 4232,
			enableTrackers: 5167
		},
		{
			idCustomer: 12,
			customerName: 'Edf12',
			users: [
				{
					idUser: 2,
					firstname: 'Jtyt',
					lastname: 'Dtrydt',
					superAdminId: true
				},
				{
					idUser: 1,
					firstname: 'Tdtryt',
					lastname: 'Mtry',
					superAdminId: false
				},
				{
					idUser: 3,
					firstname: 'P',
					lastname: 'V',
					superAdminId: false
				},
				{
					idUser: 4,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 5,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 6,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 7,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 8,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 9,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				}
			],
			totalUsers: 100,
			totalTrackers: 378899,
			onStock: 2500,
			incomplet: 320500,
			desabledTrackers: 4232,
			enableTrackers: 5167
		},
		{
			idCustomer: 13,
			customerName: 'Edf13',
			users: [
				{
					idUser: 2,
					firstname: 'Jtyt',
					lastname: 'Dtrydt',
					superAdminId: true
				},
				{
					idUser: 1,
					firstname: 'Tdtryt',
					lastname: 'Mtry',
					superAdminId: false
				},
				{
					idUser: 3,
					firstname: 'P',
					lastname: 'V',
					superAdminId: false
				},
				{
					idUser: 4,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 5,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 6,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 7,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 8,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 9,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				}
			],
			totalUsers: 100,
			totalTrackers: 378899,
			onStock: 2500,
			incomplet: 320500,
			desabledTrackers: 4232,
			enableTrackers: 5167
		},
		{
			idCustomer: 14,
			customerName: 'Edf14',
			users: [
				{
					idUser: 2,
					firstname: 'Jtyt',
					lastname: 'Dtrydt',
					superAdminId: true
				},
				{
					idUser: 1,
					firstname: 'Tdtryt',
					lastname: 'Mtry',
					superAdminId: false
				},
				{
					idUser: 3,
					firstname: 'P',
					lastname: 'V',
					superAdminId: false
				},
				{
					idUser: 4,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 5,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 6,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 7,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 8,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 9,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				}
			],
			totalUsers: 100,
			totalTrackers: 378899,
			onStock: 2500,
			incomplet: 320500,
			desabledTrackers: 4232,
			enableTrackers: 5167
		},
		{
			idCustomer: 15,
			customerName: 'toto fin',
			users: [
				{
					idUser: 2,
					firstname: 'Jtyt',
					lastname: 'Dtrydt',
					superAdminId: true
				},
				{
					idUser: 1,
					firstname: 'Tdtryt',
					lastname: 'Mtry',
					superAdminId: false
				},
				{
					idUser: 3,
					firstname: 'P',
					lastname: 'V',
					superAdminId: false
				},
				{
					idUser: 4,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 5,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 6,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 7,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 8,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				},
				{
					idUser: 9,
					firstname: 'V',
					lastname: 'L',
					superAdminId: false
				}
			],
			totalUsers: 100,
			totalTrackers: 378899,
			onStock: 2500,
			incomplet: 320500,
			desabledTrackers: 4232,
			enableTrackers: 5167
		}
	]
};

mock.onPost('api/customers/list').reply(config => {
	let Ids = [];
	Ids.push(clientsDB.customers.map(client => client.idCustomer));
	return [200, clientsDB];
});

mock.onPost('api/customers/plage').reply(config => {
	return [200, clientsDB];
});

mock.onPost('api/customers').reply(config => {
	let clients = [];
	// eslint-disable-next-line
	clientsDB.customers.map(client => {
		if (client.idCustomer === JSON.parse(config.data).idCustomer) clients.push(client);
	});

	return [200, clients];
});

mock.onPost('/api/customer/add').reply(request => {
	const data = JSON.parse(request.data);
	clientsDB.customers = [
		...clientsDB.customers,
		{
			...data
			// id: FuseUtils.generateGUID()
		}
	];

	return [200, data];
});

mock.onPost('api/customer/pause').reply(request => {
	// clientsDB.customers = clientsDB.customers.filter(client => data.idCustomer !== client.idCustomer);
	return [200, clientsDB.customers];
});

mock.onPost('api/customer/delete').reply(request => {
	// clientsDB.customers = clientsDB.customers.filter(client => data.idCustomer !== client.idCustomer);
	return [200, clientsDB.customers];
});
