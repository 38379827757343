import React from 'react';

function LogoStracker(props) {
	return (
		<div className="float-left mr-10">
			<svg width="100%" height="48" viewBox="0 0 278 48" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M26.2361 42.1118C26.087 42.1118 26.0125 42.1118 25.8634 42.1863C25.9379 42.1118 26.087 42.1118 26.2361 42.1118Z"
					fill="#A9DAEC"
				/>
				<path
					d="M34.3604 39.0558C34.2859 39.1304 34.2114 39.1304 34.1368 39.2049C34.2114 39.1304 34.2859 39.0558 34.3604 39.0558Z"
					fill="#A9DAEC"
				/>
				<path
					d="M27.8758 41.8137C27.8012 41.8137 27.6522 41.8882 27.5776 41.8882C27.7267 41.8882 27.8012 41.8137 27.8758 41.8137Z"
					fill="#A9DAEC"
				/>
				<path
					d="M40.0995 41.8137C40.025 41.8882 39.8759 41.9627 39.8014 42.0373C39.9505 41.9627 40.025 41.8882 40.0995 41.8137Z"
					fill="#A9DAEC"
				/>
				<path
					d="M36.8946 36.8944C36.671 37.118 36.4474 37.3416 36.2238 37.4907C36.5219 37.2671 36.671 37.118 36.8946 36.8944Z"
					fill="#A9DAEC"
				/>
				<path
					d="M40.9192 7.00621C36.2981 2.31056 30.1118 0 24 0C17.8137 0 11.7019 2.38509 7.0062 7.08074C-2.3851 16.472 -2.31057 31.677 7.08073 40.9938L11.1801 36.8944C11.4037 37.118 11.5528 37.2671 11.7764 37.4907C11.5528 37.2671 11.3292 37.118 11.1801 36.8944C7.75154 33.4658 5.81365 28.9193 5.81365 24C5.81365 19.1553 7.67701 14.5342 11.1056 11.1056C14.5341 7.67702 19.1553 5.73913 24 5.73913C28.8447 5.73913 33.3913 7.60248 36.8944 11.0311C40.323 14.4596 42.2609 19.0062 42.2609 23.9255C42.2609 28.7702 40.3975 33.3913 36.9689 36.8199L41.0683 40.9192C50.3851 31.5279 50.3105 16.323 40.9192 7.00621Z"
					fill="#A9DAEC"
				/>
				<path
					d="M32.7206 40.0248C32.7206 40.0248 32.6461 40.0248 32.6461 40.0994C32.6461 40.0994 32.6461 40.0248 32.7206 40.0248Z"
					fill="#A9DAEC"
				/>
				<path
					d="M31.9007 40.472C31.9007 40.472 31.8262 40.472 31.8262 40.5465C31.8262 40.472 31.9007 40.472 31.9007 40.472Z"
					fill="#A9DAEC"
				/>
				<path d="M33.466 39.5777L33.3915 39.6522L33.466 39.5777Z" fill="#A9DAEC" />
				<path
					d="M29.5902 41.3665C29.5156 41.3665 29.4411 41.441 29.3666 41.441C29.4411 41.441 29.5156 41.3665 29.5902 41.3665Z"
					fill="#A9DAEC"
				/>
				<path
					d="M31.1554 40.7703C31.0809 40.7703 31.0809 40.8448 31.0063 40.8448C31.0809 40.8448 31.0809 40.7703 31.1554 40.7703Z"
					fill="#A9DAEC"
				/>
				<path
					d="M28.7704 41.59C28.6958 41.59 28.6213 41.6646 28.5468 41.6646C28.5468 41.6646 28.6213 41.6646 28.7704 41.59Z"
					fill="#A9DAEC"
				/>
				<path
					d="M30.3355 41.1429C30.2609 41.1429 30.2609 41.2174 30.1864 41.2174C30.2609 41.1429 30.2609 41.1429 30.3355 41.1429Z"
					fill="#A9DAEC"
				/>
				<path
					d="M27.056 41.9626C26.907 41.9626 26.8324 42.0372 26.6833 42.0372C26.8324 42.0372 26.9815 41.9626 27.056 41.9626Z"
					fill="#A9DAEC"
				/>
				<path
					d="M11.7766 37.5652C11.9257 37.7143 12.1493 37.8633 12.2983 38.0124C12.1493 37.8633 12.0002 37.7143 11.7766 37.5652Z"
					fill="#A9DAEC"
				/>
				<path
					d="M20.4225 41.8882C20.5716 41.8882 20.7952 41.9628 20.9442 41.9628C20.7952 41.9628 20.6461 41.8882 20.4225 41.8882Z"
					fill="#A9DAEC"
				/>
				<path
					d="M21.3169 42.0372C21.466 42.0372 21.615 42.1118 21.8386 42.1118C21.615 42.1118 21.466 42.0372 21.3169 42.0372Z"
					fill="#A9DAEC"
				/>
				<path
					d="M18.7083 41.441C18.9319 41.5155 19.1555 41.59 19.3045 41.59C19.1555 41.59 18.9319 41.5155 18.7083 41.441Z"
					fill="#A9DAEC"
				/>
				<path
					d="M19.6027 41.6646C19.8263 41.7391 19.9753 41.7391 20.1989 41.8136C19.9753 41.8136 19.7517 41.7391 19.6027 41.6646Z"
					fill="#A9DAEC"
				/>
				<path
					d="M12.5219 38.1616C12.7455 38.3106 12.9691 38.4597 13.1181 38.6833C12.8945 38.4597 12.6709 38.3106 12.5219 38.1616Z"
					fill="#A9DAEC"
				/>
				<path
					d="M35.851 37.8633C35.7765 37.9378 35.6274 38.0123 35.5529 38.0869C35.7019 38.0123 35.7765 37.9378 35.851 37.8633Z"
					fill="#A9DAEC"
				/>
				<path
					d="M35.1058 38.4597C35.0313 38.5342 34.9567 38.6087 34.8822 38.6087C34.9567 38.6087 35.0313 38.5342 35.1058 38.4597Z"
					fill="#A9DAEC"
				/>
				<path
					d="M22.2113 42.1118C22.3604 42.1118 22.5094 42.1118 22.6585 42.1118C22.5094 42.1864 22.3604 42.1864 22.2113 42.1118Z"
					fill="#A9DAEC"
				/>
				<path
					d="M14.6833 39.6522C14.907 39.8013 15.1306 39.9504 15.4287 40.0249C15.1306 39.9504 14.907 39.8013 14.6833 39.6522Z"
					fill="#A9DAEC"
				/>
				<path
					d="M13.938 39.205C14.1616 39.354 14.3852 39.5031 14.6833 39.6522C14.3852 39.5031 14.1616 39.354 13.938 39.205Z"
					fill="#A9DAEC"
				/>
				<path
					d="M17.8882 41.2173C18.1118 41.2919 18.3354 41.3664 18.559 41.4409C18.3354 41.2919 18.1118 41.2919 17.8882 41.2173Z"
					fill="#A9DAEC"
				/>
				<path
					d="M13.1926 38.6832C13.4162 38.8323 13.6398 38.9814 13.8634 39.1304C13.6398 38.9814 13.4162 38.8323 13.1926 38.6832Z"
					fill="#A9DAEC"
				/>
				<path
					d="M17.0684 40.8447C17.292 40.9192 17.5156 40.9937 17.7392 41.1428C17.5156 41.0683 17.292 40.9937 17.0684 40.8447Z"
					fill="#A9DAEC"
				/>
				<path
					d="M16.2487 40.472C16.4723 40.5465 16.6959 40.6956 16.9195 40.7701C16.6959 40.6956 16.4723 40.6211 16.2487 40.472Z"
					fill="#A9DAEC"
				/>
				<path
					d="M15.4286 40.0994C15.6522 40.2484 15.8758 40.323 16.0994 40.472C15.8758 40.323 15.6522 40.2484 15.4286 40.0994Z"
					fill="#A9DAEC"
				/>
				<path
					d="M36.8945 36.8944C33.4659 40.323 28.9193 42.2609 24.0001 42.2609C19.1553 42.2609 14.5342 40.3975 11.1057 36.9689L7.08081 40.9938C16.4721 50.3851 31.6771 50.3106 41.0684 40.9193L36.8945 36.8944Z"
					fill="#004B7C"
				/>
				<path
					d="M24.0002 11.4783C27.3542 11.4783 30.4847 12.7454 32.8697 15.1305C35.2548 17.5155 36.5219 20.646 36.5219 24C36.5219 27.3541 35.2548 30.4845 32.8697 32.8696C30.4847 35.2547 27.3542 36.5218 24.0002 36.5218C20.6461 36.5218 17.5157 35.2547 15.1306 32.8696C12.7455 30.4845 11.4784 27.3541 11.4784 24C11.4784 20.646 12.7455 17.5155 15.1306 15.1305C17.5157 12.8199 20.6461 11.4783 24.0002 11.4783ZM24.0002 5.66461C19.3045 5.66461 14.6089 7.45343 11.0312 11.0311C3.87596 18.2609 3.9505 29.8137 11.1058 36.969C14.6834 40.5466 19.3791 42.3354 24.0002 42.3354C28.6958 42.3354 33.3915 40.5466 36.9691 36.969C44.1244 29.8137 44.0499 18.1864 36.8946 11.1056C33.3915 7.45343 28.6958 5.66461 24.0002 5.66461Z"
					fill="#0095D2"
				/>
				<path
					d="M15.1306 32.8695C12.7455 30.4844 11.4784 27.354 11.4784 24C11.4784 20.6459 12.7455 17.5155 15.1306 15.1304L11.0312 11.031C3.87596 18.2608 3.9505 29.8136 11.1058 36.9689L15.1306 32.8695Z"
					fill="#004B7C"
				/>
				<path
					d="M24.0002 11.4783C20.7952 11.4783 17.5902 12.6708 15.1306 15.1304C10.2859 20.0497 10.2859 27.9503 15.1306 32.795C17.5902 35.2547 20.7952 36.4472 23.9256 36.4472C27.1306 36.4472 30.3356 35.2547 32.7952 32.795C37.6399 27.8758 37.6399 19.9752 32.7952 15.1304C30.4101 12.7454 27.2051 11.4783 24.0002 11.4783Z"
					fill="white"
				/>
				<path
					d="M24.0002 17.2919C25.789 17.2919 27.5033 17.9627 28.7704 19.2298C30.0374 20.4969 30.7828 22.2112 30.7828 24C30.7828 25.7888 30.112 27.5031 28.8449 28.7702C27.5778 30.0373 25.8635 30.7826 24.0747 30.7826C22.2859 30.7826 20.5716 30.1118 19.3045 28.8447C18.0374 27.5777 17.2921 25.8634 17.2921 24.0745C17.2921 22.2857 17.9629 20.5714 19.23 19.3044C20.4971 17.9627 22.2114 17.2919 24.0002 17.2919ZM24.0002 11.4783C20.7952 11.4783 17.5902 12.6708 15.1306 15.1304C10.2859 20.0497 10.2859 27.9503 15.1306 32.795C17.5902 35.2547 20.7952 36.4472 23.9256 36.4472C27.1306 36.4472 30.3356 35.2547 32.7952 32.795C37.6399 27.8758 37.6399 19.9752 32.7952 15.1304C30.4101 12.7454 27.2051 11.4783 24.0002 11.4783Z"
					fill="#A9DAEC"
				/>
				<path
					d="M24.0001 17.59C27.2109 17.59 29.8137 14.9872 29.8137 11.7764C29.8137 8.56557 27.2109 5.96271 24.0001 5.96271C20.7893 5.96271 18.1864 8.56557 18.1864 11.7764C18.1864 14.9872 20.7893 17.59 24.0001 17.59Z"
					fill="white"
				/>
				<path
					d="M29.8882 9.91311C29.292 8.19882 28.0249 6.85721 26.3851 6.1864C24.8945 5.59013 23.1802 5.59013 21.6149 6.1864C19.9752 6.85721 18.7081 8.19882 18.1118 9.91311C17.5156 11.6274 17.7392 13.4162 18.6336 14.9814C21.1677 19.1553 24 24.0001 24 24.0001L25.4162 21.615C25.4162 21.615 27.4286 18.1864 29.3665 14.9069C30.2609 13.4162 30.4845 11.6274 29.8882 9.91311ZM24 14.8324C22.2858 14.8324 20.8696 13.4908 20.8696 11.7765C20.8696 10.0622 22.2112 8.72056 24 8.72056C25.7143 8.72056 27.1305 10.0622 27.1305 11.7765C27.0559 13.4908 25.7143 14.8324 24 14.8324Z"
					fill="#EB5B27"
				/>

				<path
					d="M71.6017 12.5237C73.8607 12.5237 75.8495 12.8788 77.5683 13.5889C79.3116 14.2745 80.8217 15.2907 82.0985 16.6375L79.0415 20.7145C77.9857 19.6126 76.8439 18.7923 75.6163 18.2536C74.4131 17.6904 73.0381 17.4088 71.4912 17.4088C70.1407 17.4088 69.1709 17.6292 68.5816 18.0699C67.9923 18.5107 67.6976 19.0494 67.6976 19.686C67.6976 20.2003 67.9309 20.641 68.3974 21.0083C68.8885 21.3511 69.6742 21.645 70.7546 21.8898L74.8428 22.8081C77.2736 23.3713 79.0538 24.1916 80.1833 25.269C81.3127 26.3464 81.8775 27.7788 81.8775 29.5664C81.8775 31.2804 81.4355 32.7374 80.5516 33.9372C79.6676 35.1126 78.4522 36.0063 76.9053 36.6185C75.3584 37.2062 73.5906 37.5 71.6017 37.5C69.1463 37.5 66.8996 37.1082 64.8617 36.3246C62.8237 35.5166 61.2031 34.4637 60 33.1659L63.057 28.942C63.9654 29.9949 65.1931 30.8764 66.74 31.5865C68.2869 32.2721 69.8829 32.6149 71.528 32.6149C74.0816 32.6149 75.3584 31.8681 75.3584 30.3744C75.3584 29.8602 75.162 29.4317 74.7691 29.0889C74.4008 28.7216 73.7133 28.4155 72.7066 28.1706L68.2133 27.1789C66.8382 26.8851 65.6106 26.4688 64.5302 25.9301C63.4744 25.3914 62.6395 24.6813 62.0257 23.7998C61.4118 22.8938 61.1049 21.7674 61.1049 20.4206C61.1049 18.9025 61.5223 17.5557 62.3572 16.3804C63.192 15.1805 64.3951 14.2378 65.9666 13.5522C67.538 12.8665 69.4164 12.5237 71.6017 12.5237Z"
					fill={props.ColorInM} // "#023E65"
				/>
				<path
					d="M98.5814 18.7678V23.2121H93.0936V30.5948C93.0936 31.4763 93.3023 32.113 93.7197 32.5047C94.1617 32.872 94.7633 33.0557 95.5244 33.0557C96.1137 33.0557 96.6171 32.9945 97.0345 32.872C97.4519 32.7251 97.8448 32.517 98.2131 32.2476L98.876 36.1043C98.2622 36.5205 97.4765 36.8633 96.5189 37.1327C95.5858 37.3776 94.6405 37.5 93.6829 37.5C91.5713 37.5 89.9016 37.0592 88.6739 36.1777C87.4708 35.2717 86.8569 33.7536 86.8324 31.6232V23.2121H83.6649V18.7678H86.8324V14.6541L93.0936 12.9278V18.7678H98.5814Z"
					fill={props.ColorInM}
				/>
				<path
					d="M101.473 37.1327V18.7678H106.998L107.256 22.7346C107.698 21.3879 108.373 20.3349 109.282 19.5759C110.215 18.7923 111.406 18.4005 112.854 18.4005C113.321 18.4005 113.714 18.4495 114.033 18.5474C114.377 18.6209 114.647 18.7066 114.843 18.8045L114.217 23.9467C113.971 23.8732 113.652 23.7998 113.259 23.7263C112.891 23.6529 112.437 23.6161 111.897 23.6161C110.767 23.6161 109.785 23.9712 108.95 24.6813C108.14 25.3914 107.735 26.4566 107.735 27.8768V37.1327H101.473Z"
					fill={props.ColorInM}
				/>
				<path
					d="M127.359 25.4894C127.359 24.6323 127.114 23.9589 126.623 23.4692C126.156 22.955 125.518 22.6979 124.707 22.6979C123.897 22.6979 123.197 22.906 122.608 23.3223C122.043 23.7141 121.65 24.4242 121.429 25.4526L116.126 24.2038C116.47 22.4408 117.378 21.0328 118.851 19.9799C120.325 18.927 122.399 18.4005 125.076 18.4005C127.777 18.4005 129.864 19.0004 131.337 20.2003C132.835 21.3756 133.584 23.1019 133.584 25.3792V32.1007C133.584 33.0557 134.013 33.5332 134.873 33.5332C135.315 33.5332 135.671 33.4842 135.941 33.3863L135.572 36.9491C134.836 37.2919 133.94 37.4633 132.884 37.4633C131.73 37.4633 130.735 37.2429 129.901 36.8021C129.066 36.3369 128.501 35.639 128.206 34.7085C127.691 35.6635 126.868 36.3736 125.739 36.8389C124.634 37.2796 123.455 37.5 122.203 37.5C120.312 37.5 118.802 37.0715 117.673 36.2145C116.568 35.3329 116.015 34.0719 116.015 32.4313C116.015 31.109 116.408 30.0194 117.194 29.1623C118.004 28.2808 119.269 27.6687 120.987 27.3258L127.359 26.0036V25.4894ZM122.203 31.8436C122.203 32.3823 122.399 32.7863 122.792 33.0557C123.185 33.325 123.652 33.4597 124.192 33.4597C125.027 33.4597 125.763 33.2149 126.402 32.7251C127.04 32.2109 127.359 31.4151 127.359 30.3377V29.3092L124.376 29.9337C123.639 30.0806 123.087 30.301 122.719 30.5948C122.375 30.8641 122.203 31.2804 122.203 31.8436Z"
					fill={props.ColorInM}
				/>
				<path
					d="M146.997 18.4005C148.863 18.4005 150.41 18.6698 151.638 19.2086C152.89 19.7473 153.86 20.4451 154.548 21.3022C155.235 22.1592 155.677 23.0897 155.873 24.0936L150.091 26.0036C149.944 24.9506 149.637 24.1671 149.17 23.6529C148.704 23.1142 148.041 22.8448 147.181 22.8448C146.003 22.8448 145.107 23.2611 144.493 24.0936C143.903 24.9262 143.609 26.2362 143.609 28.0237C143.609 29.8357 143.928 31.1335 144.566 31.9171C145.229 32.6762 146.113 33.0557 147.218 33.0557C148.962 33.0557 149.98 32.064 150.275 30.0806L155.91 31.8803C155.64 33.472 154.744 34.8065 153.222 35.8839C151.724 36.9613 149.735 37.5 147.255 37.5C144.235 37.5 141.816 36.6675 139.999 35.0024C138.207 33.3128 137.311 30.9621 137.311 27.9502C137.311 25.9424 137.716 24.2283 138.526 22.8081C139.361 21.3879 140.503 20.2982 141.951 19.5391C143.4 18.78 145.082 18.4005 146.997 18.4005Z"
					fill={props.ColorInM}
				/>
				<path
					d="M165.427 10.9811V26.4811L172.425 18.7678H179.386L172.609 25.9668L179.791 37.1327H172.867L168.41 29.6031L165.427 32.9088V37.1327H159.165V10.9811H165.427Z"
					fill={props.ColorInM}
				/>
				<path
					d="M190.056 37.5C186.987 37.5 184.556 36.6675 182.764 35.0024C180.996 33.3373 180.112 30.9866 180.112 27.9502C180.112 25.9424 180.517 24.2283 181.328 22.8081C182.162 21.3634 183.292 20.2737 184.716 19.5391C186.165 18.78 187.847 18.4005 189.762 18.4005C191.702 18.4005 193.347 18.78 194.697 19.5391C196.072 20.2737 197.116 21.3022 197.828 22.6244C198.54 23.9222 198.896 25.4159 198.896 27.1055C198.896 27.5707 198.871 28.0237 198.822 28.4645C198.798 28.8807 198.773 29.2358 198.748 29.5296H186.189C186.361 30.8029 186.778 31.7212 187.441 32.2844C188.104 32.8231 188.976 33.0924 190.056 33.0924C190.989 33.0924 191.726 32.9088 192.266 32.5415C192.831 32.1742 193.248 31.66 193.518 30.9988L198.527 32.6517C198.184 33.7046 197.582 34.5983 196.723 35.3329C195.888 36.0431 194.881 36.5818 193.703 36.9491C192.549 37.3164 191.333 37.5 190.056 37.5ZM189.762 22.7713C187.773 22.7713 186.594 23.91 186.226 26.1872H192.966C192.696 23.91 191.628 22.7713 189.762 22.7713Z"
					fill={props.ColorInM}
				/>
				<path
					d="M202.398 37.1327V18.7678H207.923L208.181 22.7346C208.623 21.3879 209.298 20.3349 210.207 19.5759C211.14 18.7923 212.331 18.4005 213.779 18.4005C214.246 18.4005 214.639 18.4495 214.958 18.5474C215.302 18.6209 215.572 18.7066 215.768 18.8045L215.142 23.9467C214.896 23.8732 214.577 23.7998 214.184 23.7263C213.816 23.6529 213.362 23.6161 212.822 23.6161C211.692 23.6161 210.71 23.9712 209.875 24.6813C209.065 25.3914 208.66 26.4566 208.66 27.8768V37.1327H202.398Z"
					fill={props.ColorInM}
				/>
				<path
					d="M234.429 16.9385C234.429 18.6004 233.943 19.9873 232.972 21.0993C232.001 22.1993 230.634 22.9406 228.872 23.3232V23.4666C230.982 23.7297 232.565 24.3873 233.62 25.4394C234.675 26.4796 235.202 27.8666 235.202 29.6002C235.202 32.123 234.309 34.0719 232.523 35.4469C230.736 36.8099 228.194 37.4914 224.897 37.4914C221.984 37.4914 219.526 37.0191 217.524 36.0746V32.3263C218.639 32.8763 219.82 33.3007 221.067 33.5996C222.314 33.8985 223.513 34.048 224.664 34.048C226.702 34.048 228.224 33.6713 229.232 32.9181C230.239 32.1649 230.742 30.9991 230.742 29.4209C230.742 28.022 230.185 26.9937 229.07 26.3361C227.955 25.6785 226.204 25.3497 223.818 25.3497H221.535V21.9243H223.854C228.051 21.9243 230.149 20.4775 230.149 17.5841C230.149 16.4602 229.783 15.5934 229.052 14.9836C228.32 14.3738 227.241 14.069 225.815 14.069C224.82 14.069 223.86 14.2124 222.937 14.4994C222.014 14.7744 220.923 15.3184 219.664 16.1314L217.596 13.1902C220.006 11.4206 222.805 10.5359 225.994 10.5359C228.644 10.5359 230.712 11.1038 232.199 12.2396C233.686 13.3755 234.429 14.9418 234.429 16.9385Z"
					fill={props.ColorInM}
				/>
				<path
					d="M238.709 25.9595C238.709 15.701 242.899 10.5717 251.28 10.5717C252.598 10.5717 253.713 10.6734 254.625 10.8766V14.3918C253.713 14.1287 252.658 13.9972 251.46 13.9972C248.642 13.9972 246.526 14.7505 245.111 16.257C243.697 17.7635 242.929 20.1786 242.809 23.5025H243.025C243.589 22.534 244.38 21.7868 245.399 21.2607C246.418 20.7227 247.617 20.4536 248.996 20.4536C251.382 20.4536 253.24 21.183 254.571 22.6416C255.902 24.1003 256.567 26.0791 256.567 28.578C256.567 31.3279 255.794 33.504 254.247 35.1061C252.712 36.6963 250.614 37.4914 247.953 37.4914C246.07 37.4914 244.434 37.043 243.043 36.1463C241.652 35.2376 240.579 33.9224 239.824 32.2007C239.081 30.4671 238.709 28.3867 238.709 25.9595ZM247.881 34.0121C249.331 34.0121 250.446 33.5458 251.226 32.6132C252.017 31.6806 252.413 30.3475 252.413 28.6138C252.413 27.1073 252.041 25.9237 251.298 25.0628C250.566 24.2019 249.463 23.7715 247.989 23.7715C247.077 23.7715 246.238 23.9688 245.471 24.3634C244.704 24.746 244.098 25.278 243.655 25.9595C243.211 26.6291 242.989 27.3166 242.989 28.022C242.989 29.7078 243.445 31.1306 244.356 32.2904C245.279 33.4382 246.454 34.0121 247.881 34.0121Z"
					fill={props.ColorInM}
				/>
				<path
					d="M277.5 24.0226C277.5 28.578 276.763 31.9616 275.288 34.1735C273.825 36.3854 271.583 37.4914 268.562 37.4914C265.637 37.4914 263.419 36.3496 261.908 34.0659C260.397 31.7822 259.642 28.4345 259.642 24.0226C259.642 19.3955 260.373 15.988 261.836 13.7999C263.311 11.6 265.553 10.5 268.562 10.5C271.499 10.5 273.723 11.6478 275.234 13.9434C276.745 16.239 277.5 19.5988 277.5 24.0226ZM263.922 24.0226C263.922 27.5975 264.288 30.1502 265.019 31.6806C265.763 33.211 266.944 33.9762 268.562 33.9762C270.181 33.9762 271.362 33.1991 272.105 31.6448C272.86 30.0904 273.238 27.5497 273.238 24.0226C273.238 20.5074 272.86 17.9667 272.105 16.4004C271.362 14.8222 270.181 14.0331 268.562 14.0331C266.944 14.0331 265.763 14.8043 265.019 16.3466C264.288 17.889 263.922 20.4477 263.922 24.0226Z"
					fill={props.ColorInM}
				/>
			</svg>
		</div>
	);
}

export default LogoStracker;
