import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const ButtonStyled = styled.button`
	background-color: ${props =>
		props.unfunctional
			? 'lightgray'
			: props.fill
				? 'white'
				: props.secondary
					? 'transparent'
					: props.theme.palette.type === 'light'
						? props.theme.palette.stracker.contrastText
						: props.theme.palette.secondary.contrastText};
	color: ${props =>
		props.secondary && props.theme.palette.type === 'light'
			? props.theme.palette.stracker.contrastTextDark // bouton secondaire et light
			: props.secondary
				? props.theme.palette.stracker.colorOutlined // bouton secondaire et dark
				: props.theme.palette.stracker.contrastTextRegular // bouton primaire
	};
	border-radius: 8px;
	border: ${props =>
		props.secondary && props.theme.palette.type === 'light'
			? `1px solid ${props.theme.palette.stracker.variantOutlined}`
			: `1px solid ${props.theme.palette.stracker.variantOutlined}`};
	border-width: ${props => props.noBorder && '0 !important'};
	opacity: ${props => (props.disabled ? '.4' : '.8')};
	cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
	font-weight: 600;
	font-family: Open Sans, sans-serif;
	transition: opacity 0.3s ease-in-out;
	font-size: 14px;
	padding: ${props => (props.padding ? props.padding : '7px 20px')};

	&:hover {
		opacity: ${props => (props.disabled && !props.noHover ? '.4' : '1')}
	}
`;
