import React from 'react';
import { authRoles } from 'app/auth';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import en from './i18n/en'; 
import fr from './i18n/fr';

i18next.addResourceBundle('fr', 'langues', fr);
i18next.addResourceBundle('en', 'langues', en); 

const LanguesConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.superAdmin,
	routes: [
		{
			path: '/parametres/langues',
			component: React.lazy(() => import('./Langues'))
		},
		{
			path: '/langues',
			component: () => <Redirect to="/parametres/langues" />
		}
	]
};

export default LanguesConfig;
