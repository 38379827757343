import React, { useState } from 'react';
import CircleDeleteIcon from '@stracker/customicons/CircleDeleteIcon';
import ChevronLeftIcon from '@stracker/customicons/ChevronIcon';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const OrderBy = ({ icon, title, orderValue, setOrderValue }) => {
	const theme = useTheme();

	const [isActiveTop, setIsActiveTop] = useState(false);
	const [isActiveBottom, setIsActiveBottom] = useState(false);
	const isMobileDevice = useMediaQuery('(max-width:960px)');

	const ascending = () => {
		let orderValueCpy = orderValue;
		let isIn = false;
		if (orderValue.length > 0) {
			orderValue.forEach((val, index) => {
				if (val.orderType === title && val.order !== 'ascending') {
					orderValueCpy[index].order = 'ascending';
					isIn = true;
				}
			});
		}

		if (!isIn) {
			orderValueCpy.push({ orderType: title, order: 'ascending' });
		}

		setOrderValue([...orderValueCpy]);
	};

	const descending = () => {
		let orderValueCpy = orderValue;
		let isIn = false;
		if (orderValue.length > 0) {
			orderValue.forEach((val, index) => {
				if (val.orderType === title && val.order !== 'descending') {
					orderValueCpy[index].order = 'descending';
					isIn = true;
				}
			});
		}

		if (!isIn) {
			orderValueCpy.push({ orderType: title, order: 'descending' });
		}

		setOrderValue([...orderValueCpy]);
	};

	const resetOrder = () => {
		setIsActiveTop(false);
		setIsActiveBottom(false);

		let orderValueCpy = orderValue;
		orderValue.forEach((val, index) => {
			if (val.orderType === title) {
				orderValueCpy.splice(index, 1);
				setOrderValue([...orderValueCpy]);
			}
		});
	};

	return (
		<div
			className="flex items-center"
			style={{
				border: `1px solid ${theme.palette.stracker.variantOutlined}`,
				padding: '0 15px',
				borderRadius: '15px',
				maxHeight: '26px',
				marginLeft: '10px'
			}}
		>
			{(isActiveTop || isActiveBottom) && (
				<div style={{ cursor: 'pointer' }} onClick={resetOrder}>
					<CircleDeleteIcon />
				</div>
			)}
			{icon && <div style={{ marginLeft: isActiveTop || isActiveBottom ? '8px' : '0' }}>{icon}</div>}
			<p style={{ color: theme.palette.stracker.contrastTextDark, marginLeft: '8px', fontSize: '13px' }}>
				{isMobileDevice ? '' : title}
			</p>
			<div
				style={{ marginLeft: '10px', cursor: 'pointer' }}
				onClick={() => {
					if (!isActiveBottom && !isActiveTop) {
						setIsActiveTop(false);
						setIsActiveBottom(true);
						ascending();
					} else {
						setIsActiveTop(!isActiveTop);
						setIsActiveBottom(!isActiveBottom);
						if (isActiveTop) ascending();
						else descending();
					}
				}}
			>
				<div style={{ marginBottom: '-8px' }}>
					<ChevronLeftIcon
						rotate="180"
						color={isActiveTop ? theme.palette.stracker.contrastTextGreyM : null}
					/>
				</div>
				<div>
					<ChevronLeftIcon color={isActiveBottom ? theme.palette.stracker.contrastTextGreyM : null} />
				</div>
			</div>
		</div>
	);
};

export default OrderBy;
