import React from 'react';
import { authRoles } from 'app/auth';
import { Redirect } from 'react-router-dom';
import i18next from 'i18next';
import en from './i18n/en'; 
import fr from './i18n/fr';

i18next.addResourceBundle('fr', 'membres', fr);
i18next.addResourceBundle('en', 'membres', en); 

const MembresConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.superAdmin,
	routes: [
		{
			path: '/parametres/membres',
			component: React.lazy(() => import('./Membres'))
		},
		{
			path: '/membres',
			component: () => <Redirect to="/parametres/membres" />
		}
	]
};

export default MembresConfig;
