import React from 'react';
import { Redirect } from 'react-router-dom';
import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from './i18n/en';
import fr from './i18n/fr';

i18next.addResourceBundle('en', 'clients', en);
i18next.addResourceBundle('fr', 'clients', fr);

const ClientsConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	auth: authRoles.superAdmin,
	routes: [
		{
			// path: ['/clients/seemore/:idCustomer?', '/clients/:labelHandle/:idCustomer?'],
			path: '/clients/seemore/:idCustomer',
			component: React.lazy(() => import('./seemore/ClientSeeMore'))
		},
		{
			path: '/clients/list',
			component: React.lazy(() => import('./Clients'))
		},
		{
			path: '/clients',
			component: () => <Redirect to="/clients/list" />
		}
	]
};

export default ClientsConfig;

/**
 * Lazy load Example
 */
/*
import React from 'react';

const ExampleConfig = {
    settings: {
        layout: {
            config: {}
        }
    },
    routes  : [
        {
            path     : '/example',
            component: React.lazy(() => import('./Example'))
        }
    ]
};

export default ExampleConfig;

*/
