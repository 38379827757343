import * as Actions from '../actions';

const initialState = {
	entities: [],
	profiles: {},
	total: 0,
	success: {},
	successPass: {},
};

const profileReducer = (state = initialState, action) => {
	switch (action.type) {
		case Actions.GET_SC_PROFILE:
			return [...action.payload];
		case Actions.GET_SC_CUSTOMERNAME: {
			return {
				...state,
				profiles: action.payload[0]
			};
		}
		case Actions.UPDATE_PROFILE_SUCCESS: {
			return {
				...state,
				success: action.payload
			};
		}
		case Actions.UPDATE_PROFILE_WITHEMAIL_SUCCESS: {
			return {
				...state,
				successPass: action.payload
			};
		}
		
		default:
			return state;
	}
};

export default profileReducer;
