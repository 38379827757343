import React from 'react';
import { authRoles } from 'app/auth';
import i18next from 'i18next';
import en from '../i18n/en';
import fr from '../i18n/fr';

i18next.addResourceBundle('fr', 'register', fr);
i18next.addResourceBundle('en', 'register', en);

const RegisterConfig = {
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: authRoles.onlyGuest,
	routes: [
		{
			path: '/register/:token?',
			component: React.lazy(() => import('./Register'))
		}
	]
};

export default RegisterConfig;
