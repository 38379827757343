import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { OutlinedInput, MenuItem, Select } from '@material-ui/core';
import styled from 'styled-components';
import CircleEmpty from '@stracker/customicons/CircleEmpty';
import CircleFilled from '@stracker/customicons/CircleFilled';

const useStyles = makeStyles(theme => ({
	font1: {
		color: theme.palette.stracker.contrastTextDark,
		fontWeight: 600,
		fontSize: '12px'
	},
	select: {
		borderRadius: '8px',
		backgroundColor: 'white',
		'&:after': {
			borderBottomColor: theme.palette.stracker.contrastTextDark
		},
		'& .MuiSvgIcon-root': {
			color: theme.palette.stracker.contrastTextDark
		}
	}
}));

const MenuProps = {
	style: {
		width: '2000px'
	}
};

function CotationFilterDropDown({ filterData, setFilterData }) {
	// eslint-disable-next-line
	const theme = useTheme();
	const { t } = useTranslation('cotation');
	const classes = useStyles();
	// eslint-disable-next-line
	const [personName, setPersonName] = useState([]);

	const handleChange = event => {
		let filterDataCpy = filterData;
		// eslint-disable-next-line
		filterDataCpy.map(data => {
			if (data.typeName === event.target.value[event.target.value.length - 1].typeName)
				data.display = !data.display;
		});
		setFilterData([...filterDataCpy]);
	};

	return (
		<div>
			<Select
				className={classes.select}
				multiple
				displayEmpty
				value={personName}
				onChange={handleChange}
				input={<OutlinedInputStyled />}
				renderValue={selected => {
					return <p className={classes.font1}>{t('FILTER')}</p>;
				}}
				MenuProps={MenuProps}
				inputProps={{ 'aria-label': 'Without label' }}
				components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
			>
				{filterData.map(data => (
					<MenuItem key={data} value={data}>
						{data.display === true ? <CircleEmpty /> : <CircleFilled />}
						{data.label}
					</MenuItem>
				))}
			</Select>
		</div>
	);
}

export default CotationFilterDropDown;

const OutlinedInputStyled = styled(OutlinedInput)`
	.MuiOutlinedInput-input {
		padding-top: 2px;
		padding-bottom: 0;
		height: 18px;
	}
	.MuiMenu-paper {
		top: 10px !important;
	}
`;
