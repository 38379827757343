import React from 'react';
import i18next from 'i18next';
import en from './i18n/en';
import fr from './i18n/fr';
// import { authRoles } from 'app/auth';

i18next.addResourceBundle('fr', 'history', fr);
i18next.addResourceBundle('en', 'history', en);

const HistoryConfig = {
	settings: {
		layout: {
			config: {}
		}
	},
	routes: [
		{
			path: '/history',
			exact: true,
			component: React.lazy(() => import('./History'))
		},
		{
			path: '/history/detail/:key',
			component: React.lazy(() => import('./HistoryDetail'))
		},
		{
			path: '/history/:id',
			component: React.lazy(() => import('../share/ShareByTransportAsset.js'))
		}
	]
};

export default HistoryConfig;
