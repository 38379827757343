import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';

// const availableLanguages = ['en', 'fr'];
const locales =
	(navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage || 'en-US';
const lang = locales.split(/[-_]/)[0];
if (process.env.REACT_APP_HOST_ENV === 'development') {
	console.log('i18n file en mode Dev', locales);
}
// the translations
// (tip move them in a JSON file and import them)
const resources = {
	en: {
		translation: {
			'Welcome to Stracker': 'Welcome to Stracker Plateform'
		}
	},
	fr: {
		translation: {
			'Welcome to Stracker': 'Bienvenue sur la plateforme de Stracker'
		}
	}
};
i18n.use(languageDetector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: lang, // 'en',
		fallbackLng: 'fr',
		// whitelist: availableLanguages,
		keySeparator: false, // we do not use keys in form messages.welcome
		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;
